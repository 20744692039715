import { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import CustomCard from '../../cards/CustomCard';
import ReferencesList from './ReferencesList';
import AddReferenceDialog from '../dialogs/AddReferenceDialog';
import {
  articleIdUrlParam,
  EDIT_MODE_KEY,
  VERSION_CONTENT_CONTAINER_ID,
} from '../../../shared/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectActiveVersion,
  selectShowMakeLinkSelectionInfo,
  setShowMakeLinkSelectionInfo,
} from '../../../redux/store/content/slice';
import { RightKey } from '../../../shared/enums';
import useGetCategoryByArticleId from '../../../hooks/useGetCategoryByArticleId';
import { SourceContentArea } from '../../../redux/store/api/api';
import convertFromRange from '../../version/functions/convertFromRange';
import testForIntersection from '../../version/functions/testForIntersection';
import IntersectionDetectedDialog from '../../version/dialogs/version-content/IntersectionDetectedDialog';
import { getRange } from '../../version/functions/validateRange';

function References() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const articleId: string | null = searchParams.get(articleIdUrlParam);
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const [dialogShow, setDialogShow] = useState(false);
  const [
    intersectionDetectedDialogOpened,
    setIntersectionDetectedDialogOpened,
  ] = useState(false);
  const [sourceContentArea, setSourceContentArea] = useState<
    SourceContentArea | undefined
  >(undefined);
  const [sourceContentAreaAncestorIds, setSourceContentAreaAncestorIds] =
    useState<string[]>([]);
  const [selection] = useState(window.getSelection());
  const activeVersion = useAppSelector(selectActiveVersion);
  const showMakeLinkSelectionInfo = useAppSelector(
    selectShowMakeLinkSelectionInfo,
  );

  const category = useGetCategoryByArticleId(activeVersion.articleId);

  // permission
  const userCanEditArticle =
    category?.permittedActions?.includes(
      RightKey.RightArticleManagementEditArticle,
    ) || false;

  const getClosestParentId = (startEl: HTMLElement): string => {
    const parentId = startEl.parentElement?.id;
    if (parentId) {
      return parentId;
    }
    if (parentId === VERSION_CONTENT_CONTAINER_ID || !startEl.parentElement) {
      return '';
    }
    return getClosestParentId(startEl.parentElement);
  };

  const processSelection = () => {
    const range = getRange(selection);

    if (!range) {
      return;
    }

    if (testForIntersection(range, VERSION_CONTENT_CONTAINER_ID, 'a')) {
      setIntersectionDetectedDialogOpened(true);

      return;
    }

    setSourceContentArea(convertFromRange(range, VERSION_CONTENT_CONTAINER_ID));

    const rangeContainer = range.commonAncestorContainer as HTMLElement;
    const rangeContainerChildren = Array.from(rangeContainer.children || []);
    const intersectedContainerIds: string[] = [];

    rangeContainerChildren.forEach((child) => {
      if (range.intersectsNode(child) && child.id) {
        intersectedContainerIds.push(child.id);
      }
    });

    if (intersectedContainerIds.length === 0) {
      intersectedContainerIds.push(getClosestParentId(rangeContainer));
    }

    setSourceContentAreaAncestorIds(intersectedContainerIds);

    setDialogShow(true);
  };

  const handleAddReferenceWithContentLinkClick = () => {
    if (selection && !selection.isCollapsed) {
      processSelection();
    } else {
      dispatch(setShowMakeLinkSelectionInfo(true));
    }
  };

  const getCardActions = () => {
    const cardActions = [];
    if (editModeIsActive && articleId && userCanEditArticle) {
      if (activeVersion.isRecentVersion) {
        cardActions.push({
          name: translation('addReferenceWithContentLink'),
          iconClassName: 'icon-pencil',
          underlineColorClass: 'link-mark-underline',
          onClick: () => {
            handleAddReferenceWithContentLinkClick();
          },
        });
      }
      cardActions.push({
        name: translation('addReference'),
        iconClassName: 'icon-plus',
        onClick: () => {
          setDialogShow(true);
        },
      });
    }
    return cardActions;
  };

  useEffect(() => {
    const onMouseUp = () => {
      if (selection && !selection.isCollapsed) {
        processSelection();
      }
    };

    if (showMakeLinkSelectionInfo) {
      document.addEventListener('mouseup', onMouseUp);
    }

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [showMakeLinkSelectionInfo]);

  return (
    <>
      <CustomCard
        expandable
        title={translation('references').toUpperCase()}
        iconClass='icon-verlinkungen'
        actions={getCardActions()}>
        <ReferencesList userCanEditReference={userCanEditArticle} />
      </CustomCard>
      <AddReferenceDialog
        dialogShow={dialogShow}
        setDialogShow={setDialogShow}
        sourceContentArea={sourceContentArea}
        resetSourceContentArea={() => setSourceContentArea(undefined)}
        sourceContentAreaAncestorIds={sourceContentAreaAncestorIds}
      />
      <IntersectionDetectedDialog
        show={intersectionDetectedDialogOpened}
        setShow={setIntersectionDetectedDialogOpened}
        selection={selection}
        typeOfIntersectedElement='Link'
      />
    </>
  );
}

export default References;
