import { useTranslation } from 'react-i18next';
import CustomCard from '../components/cards/CustomCard';
import LastChanges from '../components/last-changes/LastChanges';
import DashboardText from '../components/infos/DashboardText';

function Home(): JSX.Element {
  const { t: translation } = useTranslation();

  return (
    <>
      <div className='hint-container'>
        <CustomCard title={translation('hint')} iconClass='icon-dashboard'>
          <DashboardText />
        </CustomCard>
      </div>
      <div className='last-changes-container'>
        <CustomCard
          title={translation('lastChanges')}
          iconClass='icon-aenderungen'>
          <LastChanges />
        </CustomCard>
      </div>
    </>
  );
}

export default Home;
