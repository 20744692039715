import { PropsWithChildren } from 'react';
import Main from './Main';
import NavMenu from './NavMenu';
import './Layout.scss';

function Layout({ children }: PropsWithChildren<{}>): JSX.Element {
  return (
    <div>
      <NavMenu />
      <Main>{children}</Main>
    </div>
  );
}

export default Layout;
