import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  CategoryType,
  useGetApiCategoryTypesQuery,
  usePostApiCategoryTypesMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { useAppDispatch } from '../../../redux/hooks';
import CustomDialog from '../../dialogs/CustomDialog';
import { defaultColor, defaultIcon } from '../defaultValues';
import Loader from '../../loader/Loader';

interface IAddCategoryTypeDialogProps {
  show: boolean;
  setActiveCategoryType: (categoryType: CategoryType) => void;
  onClose: () => void;
}

function AddCategoryTypeDialog({
  show,
  setActiveCategoryType,
  onClose,
}: IAddCategoryTypeDialogProps) {
  const { t: translation } = useTranslation();
  const [categoryTypeName, setCategoryTypeName] = useState<string>('');
  const categoryTypeNameInputRef = useRef<HTMLInputElement>(null);
  const [
    addCategoryType,
    {
      isError: postCategoryTypeIsError,
      isLoading,
      error: postCategoryTypeError,
    },
  ] = usePostApiCategoryTypesMutation();
  const {
    refetch,
    isError: getCategoryTypesIsError,
    error: getCategoryTypesError,
  } = useGetApiCategoryTypesQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (show) {
      categoryTypeNameInputRef.current?.focus();
    }
  }, [show]);

  useEffect(() => {
    if (getCategoryTypesIsError) {
      dispatch(
        addMessage({
          id: 'GetCategoryTypesError',
          variant: 'danger',
          messageKeyBody:
            getCategoryTypesError && 'data' in getCategoryTypesError
              ? getCategoryTypesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (postCategoryTypeIsError) {
      dispatch(
        addMessage({
          id: 'AddCategoryTypeError',
          variant: 'danger',
          messageKeyBody:
            postCategoryTypeError && 'data' in postCategoryTypeError
              ? postCategoryTypeError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getCategoryTypesIsError, postCategoryTypeIsError]);

  const handleAddCategory = (): void => {
    addCategoryType({
      categoryType: {
        name: categoryTypeName.trim(),
        colorHexCode: defaultColor,
        iconCssClass: defaultIcon,
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateAttachmentSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        setCategoryTypeName('');
        onClose();
        setActiveCategoryType(result?.resultObject || {});
      });
  };

  return (
    <CustomDialog
      titleId='AddCategoryTypeDialog'
      show={show}
      closeFunction={() => {
        setCategoryTypeName('');
        onClose();
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleAddCategory}
      actionTitle={translation('add')}
      actionButtonDisabled={categoryTypeName.trim() === '' || isLoading}
      dialogTitle={translation('addCategoryType')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Form
            onSubmit={(e) => {
              if (categoryTypeName.trim() === '') {
                e.preventDefault();
                return;
              }
              handleAddCategory();
            }}>
            <Form.Group className='mb-3' controlId='CategoryTypeName'>
              <Form.Label>{translation('categoryName')}*</Form.Label>
              <Form.Control
                ref={categoryTypeNameInputRef}
                required
                onChange={(e) => {
                  setCategoryTypeName(e.target.value);
                }}
                type='text'
                value={categoryTypeName}
              />
            </Form.Group>
          </Form>
          <p>{translation('fieldsAreRequiredLegend')}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default AddCategoryTypeDialog;
