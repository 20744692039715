import { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import CustomListGroup, { ListItem } from '../lists/CustomListGroup';
import {
  useGetApiAttachmentsByVersionIdQuery,
  Attachment,
} from '../../redux/store/api/api';
import { addMessage, removeMessage } from '../../redux/store/layout/slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectActiveVersion,
  setContextAttachment,
  setDeleteAttachmentDialogOpened,
  setRenameAttachmentDialogOpened,
} from '../../redux/store/content/slice';
import { ContextAction } from '../dropdown-menus/types';
import { downloadFile, getFileIcon } from '../../shared/utils';
import { RightKey } from '../../shared/enums';
import { EDIT_MODE_KEY } from '../../shared/constants';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import { useFilesByIdMutation } from '../../redux/store/api/fileApi';
import MoveAttachmentDialog from './dialogs/MoveAttachmentDialog';

function AttachmentsList(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const blackClass = 'text-body';
  const [attachmentToMove, setAttachmentToMove] = useState<Attachment | null>(
    null,
  );
  const [moveAttachmentDialogOpened, setMoveAttachmentDialogOpened] =
    useState<boolean>(false);
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const activeVersion = useAppSelector(selectActiveVersion);
  const {
    data: attachmentData,
    isError: getAttachmentsError,
    isFetching,
    error,
  } = useGetApiAttachmentsByVersionIdQuery(
    activeVersion.id
      ? {
          versionId: activeVersion.id,
        }
      : skipToken,
  );
  const [getFileUrl] = useFilesByIdMutation();

  const category = useGetCategoryByArticleId(activeVersion.articleId);

  // permission
  const userCanEditArticle = category?.permittedActions?.includes(
    RightKey.RightArticleManagementEditArticle,
  );

  useEffect(() => {
    if (getAttachmentsError) {
      dispatch(
        addMessage({
          id: 'GetAttachmentsError',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
          variant: 'danger',
        }),
      );
    }
  }, [getAttachmentsError]);

  const getMovingActions = (attachment: Attachment): ContextAction[] => {
    const actions = [];
    const attachmentOrder: number = attachment.order || 0;
    const targetOrderDown = attachmentOrder + 1;

    if (attachmentToMove?.id !== attachment.id) {
      actions.push({
        iconClass: 'icon-move',
        iconColorClass: blackClass,
        name: translation('moveAttachmentOverThis'),
        onClick: () => {
          setAttachmentToMove({ ...attachmentToMove, order: attachmentOrder });
          setMoveAttachmentDialogOpened(true);
        },
      });

      actions.push({
        iconClass: 'icon-move',
        iconColorClass: blackClass,
        name: translation('moveAttachmentBelowThis'),
        onClick: () => {
          setAttachmentToMove({ ...attachmentToMove, order: targetOrderDown });
          setMoveAttachmentDialogOpened(true);
        },
      });
    }

    actions.push({
      iconClass: 'icon-deaktivieren',
      iconColorClass: blackClass,
      name: translation('stopMove'),
      onClick: () => {
        setAttachmentToMove(null);
      },
    });

    return actions;
  };

  const getContextActions = (attachment: Attachment) => {
    const actions: ContextAction[] = [];

    if (attachmentToMove) {
      return getMovingActions(attachment);
    }

    actions.push({
      name: translation('renameAttachment'),
      onClick: () => {
        dispatch(setContextAttachment(attachment));
        dispatch(setRenameAttachmentDialogOpened(true));
      },
      iconClass: 'icon-umbenennen',
      iconColorClass: blackClass,
    });

    actions.push({
      iconClass: 'icon-move',
      iconColorClass: blackClass,
      name: translation('moveAttachment'),
      onClick: () => {
        setAttachmentToMove(attachment);
      },
    });

    actions.push({
      name: translation('deleteAttachment'),
      onClick: () => {
        dispatch(setContextAttachment(attachment));
        dispatch(setDeleteAttachmentDialogOpened(true));
      },
      iconClass: 'icon-trash',
      iconColorClass: 'text-danger',
    });

    return actions;
  };

  return (
    <>
      {attachmentToMove && (
        <Alert variant='warning'>
          <div className='d-flex flex-column text-center'>
            <p>{translation('moveAttachmentIsStarted')}</p>
            <p className='fw-bold'>{attachmentToMove.name}</p>
            <Button
              className='mt-2'
              onClick={() => {
                setAttachmentToMove(null);
              }}
              variant='outline-dark'>
              {translation('cancelMove')}
            </Button>
          </div>
        </Alert>
      )}
      {isFetching && <p>{translation('loaded')}</p>}
      {!isFetching && (attachmentData?.resultObject?.length || 0) === 0 && (
        <p>{translation('noAttachments')}</p>
      )}
      {!isFetching && (attachmentData?.resultObject?.length || 0) > 0 && (
        <CustomListGroup
          listItems={(attachmentData?.resultObject || []).map(
            (a: Attachment, i: number) =>
              ({
                id: i.toString(),
                content: a.visibleName || a.name,
                iconClass: getFileIcon(a.name),
                contextActions:
                  editModeIsActive && userCanEditArticle
                    ? getContextActions(a)
                    : undefined,
                onClick: () => {
                  dispatch(
                    addMessage({
                      id: 'StartFileDownload',
                      messageKeyHeader: 'attachmentIsDownloading',
                      messageKeyBody: 'pleaseBePatient',
                      variant: 'info',
                      timeInMs: 9000000000,
                    }),
                  );
                  getFileUrl({ id: a.fileId || '' })
                    .unwrap()
                    .then((objectUrl) => {
                      downloadFile(objectUrl, a.visibleName || a.name || '');
                      dispatch(removeMessage('StartFileDownload'));
                      dispatch(
                        addMessage({
                          id: 'DownloadAttachmentSuccess',
                          messageKeyBody: 'downloadAttachmentSuccess',
                          variant: 'success',
                        }),
                      );
                    })
                    .catch(() => {
                      dispatch(removeMessage('StartFileDownload'));
                      dispatch(
                        addMessage({
                          id: 'GetAttachementFileError',
                          messageKeyBody: 'unknownError',
                          variant: 'danger',
                        }),
                      );
                    });
                },
              } as ListItem),
          )}
        />
      )}
      <MoveAttachmentDialog
        show={moveAttachmentDialogOpened}
        setShow={setMoveAttachmentDialogOpened}
        attachment={attachmentToMove || {}}
        setAttachmentToMove={setAttachmentToMove}
      />
    </>
  );
}

export default AttachmentsList;
