import { useTranslation } from 'react-i18next';
import CustomDialog from '../../dialogs/CustomDialog';
import CreateOrUpdateNoteDialog from './CreateOrUpdateNoteDialog';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectActiveNote,
  selectActiveVersion,
  setActiveNote,
} from '../../../redux/store/content/slice';
import useGetCategoryByArticleId from '../../../hooks/useGetCategoryByArticleId';
import { RightKey, NoteVisibilityType } from '../../../shared/enums';

interface IShowNoteDialogProps {
  show: boolean;
  setShow: (value: boolean) => void;
  showUpdateDialog: boolean;
  setShowUpdateDialog: (value: boolean) => void;
}
function ShowNoteDialog({
  show,
  setShow,
  showUpdateDialog,
  setShowUpdateDialog,
}: IShowNoteDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const note = useAppSelector(selectActiveNote);
  const version = useAppSelector(selectActiveVersion);
  const category = useGetCategoryByArticleId(version.articleId);

  // permissions
  const userCanEditUserGroupNotes =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementEditGroupNote,
    ) || false;
  const userCanEditGlobalNotes =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementEditOrganizationNote,
    ) || false;
  const userCanEditNote =
    note.visibility === NoteVisibilityType.User ||
    (note.visibility === NoteVisibilityType.UserGroup &&
      userCanEditUserGroupNotes) ||
    (note.visibility === NoteVisibilityType.General && userCanEditGlobalNotes);

  return (
    <>
      <CustomDialog
        titleId='ShowNoteDialog'
        show={show}
        closeFunction={() => {
          setShow(false);
          dispatch(setActiveNote({}));
        }}
        actionFunction={
          userCanEditNote
            ? () => {
                setShow(false);
                setShowUpdateDialog(true);
              }
            : undefined
        }
        dialogTitle={translation('showNote')}
        closeTitle={translation('close')}
        actionTitle={userCanEditNote ? translation('edit') : undefined}>
        {note.lastModifiedBy && (
          <div className='mb-3 text-muted'>
            <i className='icon-edit fs-5 align-text-top me-2' aria-hidden />
            {`${translation('lastModifiedBy')}: ${note.lastModifiedBy}`}
          </div>
        )}
        <p className='fw-bold border-bottom mb-1 pb-1'>{note.name}</p>
        <p>{note.text}</p>
      </CustomDialog>
      <CreateOrUpdateNoteDialog
        show={showUpdateDialog}
        setShow={setShowUpdateDialog}
        type='update'
        note={note}
      />
    </>
  );
}

export default ShowNoteDialog;
