import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectActiveVersion,
  selectCurrentVersions,
  setActiveVersion,
  setContextVersion,
  setDeleteDraftDialogOpened,
  setRenameDraftDialogOpened,
} from '../../redux/store/content/slice';
import { getEditDraftUrl } from '../../shared/urlBuilder';
import CustomListGroup from '../lists/CustomListGroup';

function DraftList(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeVersion = useAppSelector(selectActiveVersion);
  const versions = useAppSelector(selectCurrentVersions).filter((v) => v.draft);

  return (
    <>
      {versions.length === 0 && <p>{translation('noDrafts')}</p>}
      {versions.length > 0 && (
        <CustomListGroup
          activeListItem={activeVersion.id || ''}
          listItems={versions.map((version) => ({
            id: version.id || '',
            content: version.name || '',
            onClick: () => {
              dispatch(setActiveVersion(version));
              navigate(
                getEditDraftUrl(version.articleId || '', version.id || ''),
              );
            },
            contextActions: [
              {
                iconClass: 'icon-umbenennen',
                iconColorClass: 'text-body',
                name: translation('renameDraft'),
                onClick: () => {
                  dispatch(setContextVersion(version));
                  dispatch(setRenameDraftDialogOpened(true));
                },
              },
              {
                iconClass: 'icon-trash',
                iconColorClass: 'text-danger',
                name: translation('deleteDraft'),
                onClick: () => {
                  dispatch(setContextVersion(version));
                  dispatch(setDeleteDraftDialogOpened(true));
                },
              },
            ],
            iconClass: 'icon-entwuerfe',
          }))}
        />
      )}
    </>
  );
}

export default DraftList;
