import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { decode } from 'html-entities';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useDeleteApiVersionsByIdMutation,
  useGetApiVersionsArticleByIdQuery,
} from '../../../redux/store/api/api';
import {
  selectActiveVersion,
  selectContextVersion,
  selectCurrentVersions,
  selectDeleteDraftDialogOpened,
  setDeleteDraftDialogOpened,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import { getArticleUrl } from '../../../shared/urlBuilder';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import { EDIT_MODE_KEY } from '../../../shared/constants';

function DeleteDraftDialog(): JSX.Element {
  const { t: translation } = useTranslation();
  const version = useAppSelector(selectContextVersion);
  const articleVersions = useAppSelector(selectCurrentVersions);
  const activeVersion = useAppSelector(selectActiveVersion);
  const navigate = useNavigate();
  const dialogShow = useAppSelector(selectDeleteDraftDialogOpened);
  const dispatch = useAppDispatch();
  const [deleteVersion, { isError, isLoading, error }] =
    useDeleteApiVersionsByIdMutation();
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const { refetch } = useGetApiVersionsArticleByIdQuery(
    version.articleId
      ? { id: version.articleId, editMode: editModeIsActive }
      : skipToken,
  );

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'DeleteVersionError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleDeleteVersion = (): void => {
    deleteVersion({ id: version.id || '', isDraft: true })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteVersionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(setDeleteDraftDialogOpened(false));

        // if user delete current opened draft, open recent version of article if possible
        const notDraftVersions = articleVersions.filter((v) => !v.draft);
        if (version.id === activeVersion.id && notDraftVersions.length >= 1) {
          navigate(
            getArticleUrl(
              version.articleId || '',
              notDraftVersions[0].id || undefined,
            ),
            {
              replace: true,
            },
          );
        }
      });
  };

  return (
    <CustomDialog
      titleId='DeleteDraftDialog'
      dialogTitle={translation('deleteDraft')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setDeleteDraftDialogOpened(false));
      }}
      actionFunction={handleDeleteVersion}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p className='fw-bold'>
            {decode(version.title)} - {version.name}
          </p>
          <p>{translation('deleteDraftPermanently')}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default DeleteDraftDialog;
