import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { BookmarkFolder } from '../../redux/store/api/api';
import ContextMenu from '../dropdown-menus/ContextMenu';
import { ContextAction } from '../dropdown-menus/types';
import { MovingBookmark, MovingBookmarkFolder } from './types';
import { useAppDispatch } from '../../redux/hooks';
import {
  setAddBookmarkFolderDialogOpened,
  setContextBookmarkFolder,
  setDeleteBookmarkFolderDialogOpened,
  setRenameBookmarkFolderDialogOpened,
} from '../../redux/store/content/slice';

interface IBookmarkFolderItemProps {
  bookmarkFolder: BookmarkFolder;
  setMoveDialogOpened: (show: boolean) => void;
  movingBookmark: MovingBookmark;
  movingBookmarkFolder: MovingBookmarkFolder;
  setMovingBookmarkFolder: (movingBookmarkFolder: MovingBookmarkFolder) => void;
  setMovingBookmark: (movingBookmark: MovingBookmark) => void;
  moveBookmarkIsActive: boolean;
  setMoveBookmarkIsActive: (moveIsActive: boolean) => void;
  moveBookmarkFolderIsActive: boolean;
  setMoveBookmarkFolderIsActive: (moveIsActive: boolean) => void;
}

function BookmarkFolderItem({
  bookmarkFolder,
  setMoveDialogOpened,
  movingBookmark,
  movingBookmarkFolder,
  setMovingBookmarkFolder,
  setMovingBookmark,
  moveBookmarkIsActive,
  setMoveBookmarkIsActive,
  moveBookmarkFolderIsActive,
  setMoveBookmarkFolderIsActive,
}: IBookmarkFolderItemProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();

  const contextActions = useMemo<ContextAction[]>(() => {
    const actions: ContextAction[] = [];

    if (moveBookmarkIsActive) {
      if (bookmarkFolder.id !== movingBookmark.bookmark.bookmarkFolderId) {
        actions.push({
          iconClass: 'icon-move',
          name: translation('moveBookmarkToThisBookmarkFolder'),
          onClick: () => {
            const curMovingBookmark = movingBookmark;
            curMovingBookmark.targetBookmarkFolderId =
              bookmarkFolder.id || undefined;
            setMovingBookmark(curMovingBookmark);
            setMoveDialogOpened(true);
          },
        });
      }

      actions.push({
        iconClass: 'icon-deaktivieren',
        iconColorClass: 'text-danger',
        name: translation('stopMove'),
        onClick: () => {
          setMoveBookmarkIsActive(false);
        },
      });

      return actions;
    }

    if (moveBookmarkFolderIsActive) {
      if (
        bookmarkFolder.id !== movingBookmarkFolder.bookmarkFolder.id &&
        bookmarkFolder.id !== movingBookmarkFolder.bookmarkFolder.parentId
      ) {
        const curMovingBookmarkFolder = movingBookmarkFolder;

        actions.push({
          iconClass: 'icon-move',
          name: translation('moveBookmarkFolderToThisBookmarkFolder'),
          onClick: () => {
            curMovingBookmarkFolder.targetBookmarkFolderId =
              bookmarkFolder.id || undefined;
            setMovingBookmarkFolder(curMovingBookmarkFolder);
            setMoveDialogOpened(true);
          },
        });

        if (bookmarkFolder.parentId !== '0') {
          actions.push({
            iconClass: 'icon-move',
            name: translation('moveBookmarkFolderOverThisElement'),
            onClick: () => {
              curMovingBookmarkFolder.targetBookmarkFolderId =
                bookmarkFolder.parentId || undefined;
              curMovingBookmarkFolder.newPosition = bookmarkFolder.order || 0;
              setMovingBookmarkFolder(curMovingBookmarkFolder);
              setMoveDialogOpened(true);
            },
          });

          actions.push({
            iconClass: 'icon-move',
            name: translation('moveBookmarkFolderBelowThisElement'),
            onClick: () => {
              curMovingBookmarkFolder.targetBookmarkFolderId =
                bookmarkFolder.parentId || undefined;
              curMovingBookmarkFolder.newPosition =
                (bookmarkFolder.order || 0) + 1;
              setMovingBookmarkFolder(curMovingBookmarkFolder);
              setMoveDialogOpened(true);
            },
            addDividerAfterItem: true,
          });
        }
      }

      actions.push({
        iconClass: 'icon-deaktivieren',
        iconColorClass: 'text-danger',
        name: translation('stopMove'),
        onClick: () => {
          setMoveBookmarkFolderIsActive(false);
        },
      });

      return actions;
    }

    actions.push({
      name: translation('addBookmarkFolder'),
      iconClass: 'icon-folder_new',
      iconColorClass: 'text-success',
      onClick: () => {
        dispatch(setContextBookmarkFolder(bookmarkFolder));
        dispatch(setAddBookmarkFolderDialogOpened(true));
      },
    });

    actions.push({
      name: translation('renameBookmarkFolder'),
      iconClass: 'icon-umbenennen',
      onClick: () => {
        dispatch(setContextBookmarkFolder(bookmarkFolder));
        dispatch(setRenameBookmarkFolderDialogOpened(true));
      },
    });

    if (
      bookmarkFolder.parentId !== '0' &&
      (bookmarkFolder.bookmarkFolderIds?.length || 0) +
        (bookmarkFolder.bookmarkIds?.length || 0) ===
        0
    ) {
      actions.push({
        name: translation('deleteBookmarkFolder'),
        iconClass: 'icon-trash',
        iconColorClass: 'text-danger',
        onClick: () => {
          dispatch(setContextBookmarkFolder(bookmarkFolder));
          dispatch(setDeleteBookmarkFolderDialogOpened(true));
        },
        addDividerAfterItem: bookmarkFolder.parentId !== '0',
      });
    }

    if (bookmarkFolder.parentId !== '0') {
      actions.push({
        iconClass: 'icon-move',
        name: translation('startMoveBookmarkFolder'),
        onClick: () => {
          setMovingBookmarkFolder({ bookmarkFolder });
          setMoveBookmarkFolderIsActive(true);
        },
      });
    }

    return actions;
  }, [
    bookmarkFolder,
    moveBookmarkFolderIsActive,
    moveBookmarkIsActive,
    movingBookmark,
    movingBookmarkFolder,
  ]);

  return (
    <div className='d-flex w-100 position-relative pe-5'>
      <i className='me-2 mt-1 icon-folder_outline' aria-hidden />
      <span>{bookmarkFolder.name}</span>
      <ContextMenu contextActions={contextActions} />
    </div>
  );
}

export default BookmarkFolderItem;
