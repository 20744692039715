import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiCategoryTreeQuery,
  usePutApiArticlesByIdMutation,
} from '../../../redux/store/api/api';
import {
  selectContextArticle,
  selectRenameStructureElementDialogOpened,
  setContextCategory,
  setRenameStructureElementDialogOpened,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

function RenameStructureElementDialog() {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const dialogShow = useAppSelector(selectRenameStructureElementDialogOpened);
  const structureElement = useAppSelector(selectContextArticle);
  const [elementName, setElementName] = useState(
    structureElement.recentVersionTitle || '',
  );
  const [renameStructureElement, { isError, isLoading, error }] =
    usePutApiArticlesByIdMutation();
  const { refetch } = useGetApiCategoryTreeQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'UpdateStructureElementError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  useEffect(() => {
    setElementName(structureElement.recentVersionTitle || '');
  }, [structureElement]);

  const handleRenameStructureElement = (): void => {
    renameStructureElement({
      id: structureElement.id || '',
      article: { ...structureElement, recentVersionTitle: elementName.trim() },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateStructureElementSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        dispatch(setRenameStructureElementDialogOpened(false));
        refetch();
        dispatch(setContextCategory({}));
      });
  };

  return (
    <CustomDialog
      titleId='RenameStructureElementDialog'
      show={dialogShow}
      closeFunction={() => {
        dispatch(setRenameStructureElementDialogOpened(false));
        dispatch(setContextCategory({}));
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleRenameStructureElement}
      actionTitle={translation('rename')}
      actionButtonDisabled={
        elementName.trim() === '' ||
        elementName.trim() === structureElement.recentVersionTitle ||
        isLoading
      }
      dialogTitle={translation('renameStructureElement')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Form.Group className='mb-3' controlId='StructureElementName'>
            <Form.Label>{translation('structureElementName')}*</Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setElementName(e.target.value);
              }}
              type='text'
              value={elementName}
            />
          </Form.Group>
          <p>{translation('fieldsAreRequiredLegend')}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default RenameStructureElementDialog;
