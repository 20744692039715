import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiVersionsArticleByIdQuery,
  usePutApiVersionsByIdMutation,
} from '../../../redux/store/api/api';
import {
  selectContextVersion,
  selectRenameDraftDialogOpened,
  setRenameDraftDialogOpened,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import { EDIT_MODE_KEY } from '../../../shared/constants';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

function RenameDraftDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const dialogShow = useAppSelector(selectRenameDraftDialogOpened);
  const version = useAppSelector(selectContextVersion);
  const [draftName, setDraftName] = useState(version.name || '');
  const [updateVersion, { isError, isLoading, error }] =
    usePutApiVersionsByIdMutation();
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const { refetch } = useGetApiVersionsArticleByIdQuery(
    version.articleId
      ? { id: version.articleId, editMode: editModeIsActive }
      : skipToken,
  );

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'UpdateVersionError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  useEffect(() => {
    setDraftName(version.name || '');
  }, [version]);

  const handleUpdateVersion = () => {
    updateVersion({
      id: version.id || '',
      namedVersion: {
        id: version.id,
        name: draftName.trim(),
        title: version.title,
        articleId: version.articleId,
        publishedFrom: version.publishedFrom,
        publishedUntil: version.publishedUntil,
        type: version.type,
        htmlContent: version.htmlContent,
        validFrom: version.validFrom,
        disabled: version.disabled,
        draft: version.draft,
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateVersionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        dispatch(setRenameDraftDialogOpened(false));
        refetch();
      });
  };

  return (
    <CustomDialog
      titleId='RenameDraftDialog'
      show={dialogShow}
      closeFunction={() => {
        dispatch(setRenameDraftDialogOpened(false));
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleUpdateVersion}
      actionTitle={translation('save')}
      actionButtonDisabled={
        draftName.trim() === '' ||
        version.name === draftName.trim() ||
        isLoading
      }
      dialogTitle={translation('renameDraft')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Form.Group className='mb-3' controlId='DraftName'>
            <Form.Label>{translation('draftName')}*</Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setDraftName(e.target.value);
              }}
              type='text'
              value={draftName}
            />
          </Form.Group>
          <p>{translation('fieldsAreRequiredLegend')}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default RenameDraftDialog;
