import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import CustomCard from '../cards/CustomCard';
import { useGetApiNotesByVersionIdQuery } from '../../redux/store/api/api';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectActiveVersion,
  setNoteData,
} from '../../redux/store/content/slice';
import { addMessage } from '../../redux/store/layout/slice';
import NotesAccordion from './NotesAccordion';

function Notes() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);

  const {
    data: noteData,
    isError: getNotesIsError,
    error,
  } = useGetApiNotesByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (getNotesIsError) {
      dispatch(
        addMessage({
          id: 'GetNotesError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [getNotesIsError]);

  useEffect(() => {
    if (noteData?.resultObject) {
      dispatch(setNoteData(noteData.resultObject));
    }
  }, [noteData]);

  return (
    <CustomCard
      expandable
      title={translation('notes').toUpperCase()}
      iconClass='icon-benutzernotizen'
      iconColorClass='note-color'>
      <NotesAccordion />
    </CustomCard>
  );
}

export default Notes;
