import { useEffect } from 'react';
import References from '../components/references/reference-view/References';
import '../components/article/Article.scss';
import CategoryReferenceView from '../components/references/CategoryReferenceView';
import { useAppSelector } from '../redux/hooks';
import { selectSidebarOpened } from '../redux/store/layout/slice';

function CategoryReference(): JSX.Element {
  const sidebarOpened = useAppSelector(selectSidebarOpened);

  useEffect(() => {
    document.getElementsByTagName('h1')[0].focus();
  }, []);

  return (
    <div className='content-inner'>
      <CategoryReferenceView />
      <div
        className={`article-attachment-container ${
          sidebarOpened ? '' : 'wide-container'
        }`}>
        <References />
      </div>
    </div>
  );
}

export default CategoryReference;
