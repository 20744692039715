import { Form } from 'react-bootstrap';
import { ColorPicker, toColor, useColor } from 'react-color-palette';
import { useTranslation } from 'react-i18next';
import 'react-color-palette/lib/css/styles.css';
import { useEffect, useRef } from 'react';

interface ICategoryTypeColorPickerProps {
  colorHexCode: string;
  setColorHexCode: (hexCode: string) => void;
  isValidColor: boolean;
  setIsValidColor: (isValid: boolean) => void;
  userCanEdit: boolean;
}

function CategoryTypeColorPicker({
  colorHexCode,
  setColorHexCode,
  isValidColor,
  setIsValidColor,
  userCanEdit,
}: ICategoryTypeColorPickerProps): JSX.Element {
  const { t: translation } = useTranslation();
  const categoryTypeColorInputRef = useRef<HTMLInputElement>(null);
  const [color, setColor] = useColor('hex', colorHexCode);

  useEffect(() => {
    setColor(toColor('hex', colorHexCode));
  }, [colorHexCode]);

  return (
    <>
      <Form.Group className='mb-3' controlId='CategoryTypeColor'>
        <Form.Label>{translation('hexColor')}*</Form.Label>
        <Form.Control
          ref={categoryTypeColorInputRef}
          pattern='^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$'
          aria-describedby={!isValidColor ? 'ColorInputError' : undefined}
          isInvalid={!isValidColor}
          required
          type='text'
          readOnly={!userCanEdit}
          value={colorHexCode}
          onChange={(e) => {
            if (userCanEdit) {
              setColorHexCode(e.target.value);
              const isValidValue: boolean =
                categoryTypeColorInputRef.current?.validity.valid || false;

              if (!isValidValue) {
                setIsValidColor(false);
                return;
              }
              setIsValidColor(true);
              setColor(toColor('hex', e.target.value));
            }
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter' && userCanEdit) {
              setColor(toColor('hex', colorHexCode));
            }
          }}
        />
        <Form.Control.Feedback id='ColorInputError' type='invalid'>
          {translation('hexColorInvalidMessage')}
        </Form.Control.Feedback>
      </Form.Group>
      <ColorPicker
        width={250}
        height={180}
        color={color}
        onChange={(c) => {
          if (userCanEdit) {
            setColor(c);
          }
        }}
        onChangeComplete={(c) => {
          if (userCanEdit) {
            setColorHexCode(c.hex);
            setColor(c);
          }
        }}
        hideHSV
        hideRGB
        hideHEX
      />
    </>
  );
}

export default CategoryTypeColorPicker;
