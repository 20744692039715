import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IUserFormProps {
  email: string;
  setEmail: (email: string) => void;
  firstName: string;
  setFirstName: (firstName: string) => void;
  lastName: string;
  setLastName: (lastName: string) => void;
  isValidEmail: boolean;
  setIsValidEmail: (isValid: boolean) => void;
}

function UserForm({
  email,
  setEmail,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  isValidEmail,
  setIsValidEmail,
}: IUserFormProps): JSX.Element {
  const { t: translation } = useTranslation();
  const emailInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Form.Group className='mb-3' controlId='UserEmail'>
        <Form.Label>{translation('emailAddress')}*</Form.Label>
        <Form.Control
          required
          ref={emailInputRef}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
          isInvalid={!isValidEmail}
          type='email'
          aria-describedby={!isValidEmail ? 'UserEmailError' : undefined}
          pattern="^[\w!#$%&'*+\-/=?\^_`{|}~]+(\.[\w!#$%&'*+\-/=?\^_`{|}~]+)*@((([\-\w]+\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$"
          onBlur={() => {
            const isValidValue = emailInputRef.current?.validity.valid || false;
            setIsValidEmail(isValidValue);
          }}
        />
        <Form.Control.Feedback id='UserEmailError' type='invalid'>
          {translation('emailInvalidMessage')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mb-3' controlId='UserFirstName'>
        <Form.Label>{translation('firstName')}*</Form.Label>
        <Form.Control
          required
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          value={firstName}
          type='text'
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId='UserLastName'>
        <Form.Label>{translation('lastName')}*</Form.Label>
        <Form.Control
          required
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          value={lastName}
          type='text'
        />
      </Form.Group>
      <p>{translation('fieldsAreRequiredLegend')}</p>
    </>
  );
}

export default UserForm;
