import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import GlobalSettingList from '../components/global-settings/GlobalSettingList';
import GlobalSettings from '../components/global-settings/GlobalSettings';
import {
  SettingContainer,
  useGetApiPermissionsUserQuery,
} from '../redux/store/api/api';
import { useAppDispatch } from '../redux/hooks';
import { permissionCacheDelayInSeconds } from '../shared/constants';
import { RightKey } from '../shared/enums';
import { addMessage } from '../redux/store/layout/slice';

function GlobalSettingsManagement() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeSettingsContainer, setActiveSettingsContainer] =
    useState<SettingContainer>({});

  const {
    data: permissionData,
    isError,
    error,
  } = useGetApiPermissionsUserQuery(undefined, {
    refetchOnMountOrArgChange: permissionCacheDelayInSeconds,
  });

  useEffect(() => {
    if (
      permissionData?.resultObject &&
      !permissionData.resultObject.includes(
        RightKey.RightAccessGlobalSettingsManagement,
      )
    ) {
      navigate('/');
    }
  }, [permissionData]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetPErmissionsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
      navigate('/');
    }
  }, [isError]);

  useEffect(() => {
    document.getElementsByTagName('h1')[0].focus();
  }, []);

  return (
    <div className='content-inner'>
      {permissionData?.resultObject && (
        <>
          <GlobalSettingList
            activeSettingContainer={activeSettingsContainer}
            setActiveSettingContainer={setActiveSettingsContainer}
            rights={permissionData.resultObject}
          />
          <GlobalSettings
            type={activeSettingsContainer?.settingContainerType || 0}
            rights={permissionData.resultObject}
          />
        </>
      )}
    </div>
  );
}

export default GlobalSettingsManagement;
