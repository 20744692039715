import { Mark, SourceContentArea } from '../../../redux/store/api/api';
import {
  getNodeIgnoreFiberNodes,
  isFiberNode,
  isMarkOrLink,
  isTextNode,
} from './nodes';

const calculateOffset = (node: Node, offset: number): number => {
  const prevSibling = getNodeIgnoreFiberNodes(node, 'previousSibling');
  const parent = getNodeIgnoreFiberNodes(node, 'parentNode');

  if (parent && isMarkOrLink(parent)) {
    return calculateOffset(parent, offset);
  }

  if (
    !prevSibling ||
    (!isMarkOrLink(prevSibling) && !isTextNode(prevSibling))
  ) {
    return offset;
  }

  const nodeContentLength = prevSibling.textContent?.length || 0;
  const newOffset = offset + nodeContentLength;

  return calculateOffset(prevSibling, newOffset);
};

const findPreviousSiblings = (node: Node): number => {
  const previousSibling = getNodeIgnoreFiberNodes(node, 'previousSibling');

  if (previousSibling === null) {
    return 1;
  }

  if (
    (isMarkOrLink(node) || isTextNode(node) || isFiberNode(node)) &&
    (isMarkOrLink(previousSibling) || isTextNode(previousSibling))
  ) {
    return findPreviousSiblings(previousSibling);
  }

  return 1 + findPreviousSiblings(previousSibling);
};

// nodePath: Array of numbers
// Each number represents how much siblings a node has to the left
// Each index represents a level in the DOM
const findPath = (
  node: Node,
  nodePath: number[],
  contentContainerId: string,
): number[] => {
  if (isFiberNode(node) || isMarkOrLink(node)) {
    if (node.parentElement) {
      return findPath(node.parentElement, nodePath, contentContainerId);
    }

    return nodePath;
  }

  const rootEl: Element | null | undefined =
    document.getElementById(contentContainerId);
  const siblingsOnLevel = findPreviousSiblings(node);
  nodePath.push(siblingsOnLevel);

  if (node.parentElement !== rootEl && node.parentElement) {
    return findPath(node.parentElement, nodePath, contentContainerId);
  }

  return nodePath;
};

const convertFromRange = (
  range: Range,
  contentContainerId: string,
): Mark | SourceContentArea => {
  const startOffset = calculateOffset(range.startContainer, range.startOffset);
  const endOffset = calculateOffset(range.endContainer, range.endOffset);
  const startPath: number[] = findPath(
    range.startContainer,
    [],
    contentContainerId,
  );
  const endPath: number[] = findPath(
    range.endContainer,
    [],
    contentContainerId,
  );

  return {
    patternString: range?.toString(),
    startNodeData: `{"nodePath": [${startPath}], "offset": ${startOffset}}`,
    endNodeData: `{"nodePath": [${endPath}], "offset": ${endOffset}}`,
  };
};

export default convertFromRange;
