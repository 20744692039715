import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { useOnClickOutside } from 'usehooks-ts';
import { Dropdown } from 'react-bootstrap';
import { SyntheticEvent, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContextAction } from './types';
import './ContextMenu.scss';
import ContextToggler from './ContextToggler';
import IconButton from '../buttons/IconButton';
import ContextMenuLink from './ContextMenuLink';
import ContextMenuButton from './ContextMenuButton';
import DropdownMenu from './DropdownMenu';

interface IContextMenuProps {
  contextActions: ContextAction[];
  onlyOneActionAsButton?: boolean;
}

// custom key navigation required, because otherwise underlying elements are also triggered
function handleOnKeyDown(event: KeyboardEvent) {
  const focusedElement = event.target as HTMLElement;
  const correspondingLiOfFocusedElement: HTMLElement | null =
    focusedElement.parentElement;
  const ulElementOfMenu: HTMLElement | null | undefined =
    correspondingLiOfFocusedElement?.parentElement;
  const allMenuItems: HTMLCollectionOf<HTMLLIElement> | undefined =
    ulElementOfMenu?.getElementsByTagName('li');

  if (event.key === 'ArrowDown') {
    event.stopPropagation();

    if (correspondingLiOfFocusedElement?.nextElementSibling) {
      (
        correspondingLiOfFocusedElement?.nextElementSibling
          .firstElementChild as HTMLElement
      ).focus();
    }
  }

  if (event.key === 'ArrowUp') {
    event.stopPropagation();
    const indexOfTargetElement = [].slice
      .call(allMenuItems)
      .indexOf((event.target as HTMLElement).parentElement as never);

    if (indexOfTargetElement > 0) {
      (
        allMenuItems?.item(indexOfTargetElement - 1)
          ?.firstElementChild as HTMLElement
      ).focus();
    }
  }
}

function ContextMenu({
  contextActions,
  onlyOneActionAsButton,
}: IContextMenuProps): JSX.Element | null {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => {
    setShow(false);
  });

  const openLink = (href: string, openInNewTab: boolean): void => {
    if (openInNewTab) {
      window.open(href, '_blank', 'noopener,noreferrer');
    } else {
      navigate(href || '', {
        replace: true,
      });
    }
  };

  if (contextActions.length === 0) {
    return null;
  }

  return onlyOneActionAsButton ? (
    <div className='icon-button'>
      <IconButton
        title={contextActions[0].name}
        iconClassName={contextActions[0].iconClass || ''}
        textColorClass={contextActions[0].iconColorClass}
        ariaLabel={contextActions[0].name}
        onClick={contextActions[0].onClick as (event: SyntheticEvent) => void}
      />
    </div>
  ) : (
    <Dropdown
      ref={dropdownRef}
      className='context-menu'
      show={show}
      onToggle={() => {
        setShow(!show);
      }}>
      <Dropdown.Toggle as={ContextToggler} aria-expanded={show}>
        <ThreeDotsVertical aria-hidden />
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={DropdownMenu}
        onKeyDown={(e) => {
          handleOnKeyDown(e as unknown as KeyboardEvent);
        }}
        align='end'>
        {contextActions.map((action) => (
          <li key={action.name}>
            {action.href ? (
              <ContextMenuLink
                action={action}
                openLink={openLink}
                setShow={setShow}
              />
            ) : (
              <ContextMenuButton action={action} setShow={setShow} />
            )}
            {action.addDividerAfterItem && <Dropdown.Divider />}
          </li>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

ContextMenu.defaultProps = { onlyOneActionAsButton: false };

export default ContextMenu;
