import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo } from 'react';
import TreeView from 'react-accessible-treeview';
import { Form } from 'react-bootstrap';
import parse from 'html-react-parser';
import { decode } from 'html-entities';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/hooks';
import {
  useGetApiCategoryTreeQuery,
  useGetApiVersionsByIdQuery,
} from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import { getTreeItems } from '../content-tree/functions';
import {
  ContentTreeItems,
  IArticleTreeItem,
  ICategoryTreeItem,
} from '../content-tree/types';
import Loader from '../loader/Loader';
import ReferenceTreeItem from './reference-tree/ReferenceTreeItem';
import { ReferenceBehaviourString } from './types';
import { getTagsWithIds, getTextPassageIds } from './functions';

interface IInternalReferenceWithTextPassageFormProps {
  setSelectedReferences: (
    element: (IArticleTreeItem | ICategoryTreeItem)[],
  ) => void;
  selectedReferences: (IArticleTreeItem | ICategoryTreeItem)[] | null;
  referenceBehaviour: ReferenceBehaviourString;
  setReferenceBehaviour: (referenceBehaviour: ReferenceBehaviourString) => void;
  textPassage: string | null;
  setTextPassage: (id: string) => void;
}

function InternalReferenceWithTextPassageForm({
  setSelectedReferences,
  selectedReferences,
  referenceBehaviour,
  setReferenceBehaviour,
  textPassage,
  setTextPassage,
}: IInternalReferenceWithTextPassageFormProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const selectedArticleReference: IArticleTreeItem | null = selectedReferences
    ? (selectedReferences[0] as IArticleTreeItem)
    : null;
  const {
    data: contentTreeData,
    isFetching: getTreeIsFetching,
    isError: getTreeIsError,
    error: getTreeError,
  } = useGetApiCategoryTreeQuery();

  const {
    data: versionData,
    isFetching: getVersionIsFetching,
    isError: getVersionIsError,
    error: getVersionError,
  } = useGetApiVersionsByIdQuery(
    selectedArticleReference
      ? { id: selectedArticleReference.recentVersionId || '' }
      : skipToken,
  );

  const treeData = useMemo<ContentTreeItems>(() => {
    if (contentTreeData?.resultObject) {
      return getTreeItems(contentTreeData.resultObject);
    }
    return [];
  }, [contentTreeData]);

  const versionTextPassageIds = useMemo<string[]>(() => {
    if (versionData?.resultObject) {
      return getTextPassageIds(
        decode(versionData.resultObject.htmlContent || ''),
      );
    }
    return [];
  }, [versionData]);

  const versionTextPassages = useMemo<string[]>(() => {
    if (versionData?.resultObject) {
      return getTagsWithIds(decode(versionData.resultObject.htmlContent || ''));
    }
    return [];
  }, [versionData]);

  useEffect(() => {
    if (getTreeIsError) {
      dispatch(
        addMessage({
          id: 'GetTreeError',
          variant: 'danger',
          messageKeyBody:
            getTreeError && 'data' in getTreeError
              ? getTreeError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (getVersionIsError) {
      dispatch(
        addMessage({
          id: 'GetVersionError',
          variant: 'danger',
          messageKeyBody:
            getVersionError && 'data' in getVersionError
              ? getVersionError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getTreeIsError, getVersionIsError]);

  return (
    <div className='mt-3'>
      <p aria-labelledby='ReferenceTree' className='fw-bold'>
        {translation('selectArticleToBeLinked')}*
      </p>
      <div
        className='max-350 border-top border-bottom'
        aria-busy={getTreeIsFetching}>
        {getTreeIsFetching && <Loader />}
        {contentTreeData && !getTreeIsFetching && treeData.length > 0 && (
          <TreeView
            id='ReferenceTree'
            data={treeData}
            selectedIds={selectedReferences?.map((s) => s.id) || []}
            onSelect={(e) => {
              if (e.isSelected) {
                setSelectedReferences([
                  e.element as IArticleTreeItem | ICategoryTreeItem,
                ]);
              }
            }}
            nodeRenderer={({
              element,
              getNodeProps,
              level,
              isBranch,
              isSelected,
              isExpanded,
              handleSelect,
              handleExpand,
            }) =>
              ReferenceTreeItem({
                isExpanded: isExpanded || false,
                element: element as IArticleTreeItem | ICategoryTreeItem,
                onlyHtmlArticleSelectable: true,
                allowMultipleReferenceOnSameTarget: true,
                isBranch,
                isSelected,
                level,
                getNodeProps,
                handleSelect,
                handleExpand,
              })
            }
          />
        )}
      </div>
      {versionData && !getVersionIsFetching && (
        <div className='max-350 ps-1'>
          <p aria-labelledby='TextPassage' className='mb-1 mt-3 fw-bold'>
            {translation('chooseTextPassage')}*
          </p>
          {versionTextPassages.map((p, i) => (
            <Form.Check
              name='TextPassage'
              className='mt-2'
              key={versionTextPassageIds[i]}
              type='radio'
              id={`TextPassage_${versionTextPassageIds[i]}`}>
              <Form.Check.Input
                checked={versionTextPassageIds[i] === textPassage}
                onChange={(e) => {
                  setTextPassage(e.target.value);
                }}
                type='radio'
                value={versionTextPassageIds[i]}
              />
              <Form.Check.Label>{parse(p)}</Form.Check.Label>
            </Form.Check>
          ))}
        </div>
      )}
      {textPassage && (
        <>
          <p aria-labelledby='ReferenceBehaviour' className='mb-1 mt-3 fw-bold'>
            {translation('chooseBehaviourOfReference')}*
          </p>
          <Form.Check
            id='BidirectionalRadio'
            value='Bidirectional'
            onChange={(e) => {
              if (e.target.checked) {
                setReferenceBehaviour(
                  e.target.value as ReferenceBehaviourString,
                );
              }
            }}
            checked={referenceBehaviour === 'Bidirectional'}
            type='radio'
            name='ReferenceBehaviour'
            label={translation('referenceBehaviourBidirectional')}
          />
          <Form.Check
            id='ForwardOnlyRadio'
            value='ForwardOnly'
            onChange={(e) => {
              if (e.target.checked) {
                setReferenceBehaviour(
                  e.target.value as ReferenceBehaviourString,
                );
              }
            }}
            checked={referenceBehaviour === 'ForwardOnly'}
            type='radio'
            name='ReferenceBehaviour'
            label={translation('referenceBehaviourForwardOnly')}
          />
        </>
      )}
    </div>
  );
}

export default InternalReferenceWithTextPassageForm;
