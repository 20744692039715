import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import CustomCard from '../components/cards/CustomCard';
import UserGroupTree from '../components/user-management/user-tree/UserGroupTree';
import { useAppDispatch } from '../redux/hooks';
import { useGetApiPermissionsUserQuery } from '../redux/store/api/api';
import { addMessage } from '../redux/store/layout/slice';
import { permissionCacheDelayInSeconds } from '../shared/constants';
import { RightKey } from '../shared/enums';

function UserManagement(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translation } = useTranslation();
  const {
    data: permissionData,
    isError,
    error,
  } = useGetApiPermissionsUserQuery(undefined, {
    refetchOnMountOrArgChange: permissionCacheDelayInSeconds,
  });

  useEffect(() => {
    if (
      permissionData?.resultObject &&
      !permissionData.resultObject.includes(RightKey.RightAccessUserManagement)
    ) {
      navigate('/');
    }
  }, [permissionData]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetPermissionsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
      navigate('/');
    }
  }, [isError]);

  const [showPermissionIcons, setShowPermissionIcons] = useState(false);

  const showPermissionIconsCallback = (show: boolean) => {
    setShowPermissionIcons(show);
  };

  useEffect(() => {
    document.getElementsByTagName('h1')[0].focus();
  }, []);

  return (
    <div className='content-inner'>
      <CustomCard
        title={translation('userManagement')}
        headlineAsH1
        focusableHeadline>
        <UserGroupTree showPermissionIcons={showPermissionIconsCallback} />
      </CustomCard>
      <CustomCard
        customCardStyle={{ flex: '1 1 400px' }}
        title={translation('legend')}>
        <ul className='list-unstyled mt-3'>
          <li className='d-flex'>
            <i className='icon-users text-success me-2 fs-5' aria-hidden />
            <span>{translation('countUserInGroup')}</span>
          </li>
          <li className='d-flex'>
            <i className='icon-sitemap me-2 fs-5' aria-hidden />
            <span>{translation('countUserInChildGroups')}</span>
          </li>
          {showPermissionIcons && (
            <>
              <li className='d-flex'>
                <i
                  className='icon-right_category text-secondary me-2 fs-5'
                  aria-hidden
                />
                <span>{translation('categoryPermissionsAssigned')}</span>
              </li>
              <li className='d-flex'>
                <i
                  className='icon-right_user text-primary me-2 fs-5'
                  aria-hidden
                />
                <span>{translation('userGroupPermissionsAssigned')}</span>
              </li>
              <li className='d-flex'>
                <i className='icon-right_system me-2 fs-5' aria-hidden />
                <span>{translation('systemRolesAssigned')}</span>
              </li>
            </>
          )}
        </ul>
      </CustomCard>
    </div>
  );
}

export default UserManagement;
