import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

interface ISimpleTinyEditorProps {
  id?: string;
  onChange: (value: string) => void;
  value: string;
}

function SimpleTinyEditor({
  id,
  onChange,
  value,
}: ISimpleTinyEditorProps): JSX.Element {
  return (
    <Editor
      id={id}
      tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
      onEditorChange={(content) => {
        onChange(content);
      }}
      value={value}
      init={{
        // custom tiny config by customer
        /* eslint-disable */
        branding: false,
        language: 'de',
        language_url: `${process.env.PUBLIC_URL}/tiny-locales/de.js`,
        height: 400,
        promotion: false,
        encoding: 'xml',
        entity_encoding: 'raw',
        content_css: '/tiny-config/TinyContent.css',
        plugins: [
          'advlist',
          'lists',
          'image',
          'charmap',
          'searchreplace',
          'visualblocks',
          'code',
          'table',
        ],
        image_class_list: [
          { title: 'image-inline', value: 'image-inline' },
          { title: 'point-mneu', value: 'point-menu' },
        ],
        menubar: 'edit view format table',
        menu: {
          edit: {
            title: 'Edit',
            items: 'undo redo | cut copy paste pastetext | selectall',
          },
          view: { title: 'View', items: 'code | visualblocks' },
          format: {
            title: 'Format',
            items:
              'bold italic underline strikethrough superscript subscript | styles | removeformat',
          },
          table: {
            title: 'Table',
            items: 'inserttable tableprops deletetable | cell row column',
          },
        },
        toolbar:
          'undo redo | removeformat image | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor | code',
        toolbar_mode: 'sliding',
        statusbar: false,
        image_uploadtab: false,
        file_picker_types: 'image',
        image_advtab: false,
        automatic_uploads: false,
        images_upload_handler: function (blobInfo: any, success: any) {
          var reader = new FileReader();
          reader.onloadend = function () {
            success(reader.result);
          };
          reader.readAsDataURL(blobInfo.blob());
        } as any,
        file_picker_callback: function (cb, value, meta) {
          var input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', '.png, .jpg, .jpeg, .gif');

          input.onchange = function () {
            var file = input.files ? input.files[0] : new File([], '');
            var fileType = file.type;
            var fileSizeMB = file.size / (1024 * 1024);

            if (
              (fileType === 'image/png' ||
                fileType === 'image/jpeg' ||
                fileType === 'image/gif') &&
              fileSizeMB <= 1
            ) {
              var reader = new FileReader();
              reader.onload = function () {
                var id = 'blobid' + new Date().getTime();
                var blobCache = (window as any).tinymce.activeEditor
                  .editorUpload.blobCache;
                var blobInfo = blobCache.create(id, file, reader.result);
                blobCache.add(blobInfo);
                cb(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
            } else {
              (window as any).tinymce.activeEditor.windowManager.open({
                title: 'Ung\xfcltige Datei',
                body: {
                  type: 'panel',
                  items: [
                    {
                      type: 'htmlpanel',
                      html: 'Bitte laden Sie eine PNG, JPG oder GIF Datei mit einer Gr&ouml;&szlig;e von maximal 1 MB hoch.',
                    },
                  ],
                },
                buttons: [
                  {
                    type: 'cancel',
                    text: (window as any).tinymce.translate('Close'),
                  },
                ],
              });
            }
          };
          input.click();
        },
        /* eslint-enable */
      }}
    />
  );
}

SimpleTinyEditor.defaultProps = { id: undefined };

export default React.memo(SimpleTinyEditor);
