import { ChangeEvent, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FileInput from '../inputs/FileInput';
import FullTinyEditor from '../text-editor/FullTinyEditor';
import './Versions.scss';
import { settingTypeMaxContentNameLength } from '../../shared/constants';
import { useGetApiSettingsUserQuery } from '../../redux/store/api/api';

interface IVersionForm {
  versionContent: string;
  setVersionContent: (content: string) => void;
  versionTitle: string;
  setVersionTitle: (name: string) => void;
  validityDate: string;
  setValidityDate: (date: string) => void;
  publicationStart: string;
  setPublicationStart: (date: string) => void;
  publicationEnd: string;
  setPublicationEnd: (date: string) => void;
  pdfArticle?: boolean;
  setFile?: (file: File) => void;
  file?: File;
  isValidFile?: boolean;
  setIsValidFile?: (isValid: boolean) => void;
  setInputsAreValid: (valid: boolean) => void;
}

function VersionForm({
  versionContent,
  setVersionContent,
  versionTitle,
  setVersionTitle,
  validityDate,
  setValidityDate,
  publicationStart,
  setPublicationStart,
  publicationEnd,
  setPublicationEnd,
  pdfArticle,
  setFile,
  file,
  isValidFile,
  setIsValidFile,
  setInputsAreValid,
}: IVersionForm): JSX.Element {
  const { t: translation } = useTranslation();
  const [titleMaxIsError, setTitleMaxIsError] = useState(false);
  const { data: settings } = useGetApiSettingsUserQuery();
  const titleMaxSetting =
    Number(
      settings?.resultObject?.find(
        (s) => s.settingType?.key === settingTypeMaxContentNameLength,
      )?.value,
    ) || undefined;

  const checkValidFile = () => {
    if (setIsValidFile) {
      const pattern = '(^.*.(pdf|PDF)|application/pdf)$';
      const pdfFileValid = file?.type.match(pattern) !== null;
      setIsValidFile(pdfFileValid);
    }
  };

  return (
    <>
      <Form.Group className='mb-3' controlId='VersionTitle'>
        <Form.Label>{translation('name')}*</Form.Label>
        <Form.Control
          required
          isInvalid={titleMaxIsError}
          aria-describedby={
            titleMaxIsError ? 'VersionTitleInputError' : undefined
          }
          value={versionTitle}
          type='text'
          onChange={(e) => {
            setVersionTitle(e.target.value);
          }}
          onBlur={(e) => {
            if (titleMaxSetting && e.target.value.length > titleMaxSetting) {
              setInputsAreValid(false);
              setTitleMaxIsError(true);
            } else {
              setInputsAreValid(true);
              setTitleMaxIsError(false);
            }
          }}
        />
        <Form.Control.Feedback id='VersionTitleInputError' type='invalid'>
          {translation('versionTitleToLongError', {
            max: titleMaxSetting,
          })}
        </Form.Control.Feedback>
      </Form.Group>
      {!pdfArticle ? (
        <div className='reduce-card-padding'>
          <FullTinyEditor value={versionContent} onChange={setVersionContent} />
        </div>
      ) : (
        <FileInput
          required
          labelName={`${translation('uploadFile')}*`}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (setFile && e.target.files) {
              setFile(e.target.files[0]);
              checkValidFile();
            }
          }}
          accept='.pdf,.PDF,application/pdf'
          files={file ? [file] : null}
          isValid={isValidFile}
          invalidMessage={translation('uploadInvalidPdfMessage')}
        />
      )}
      <Row>
        <Form.Group
          as={Col}
          xl='6'
          className='mb-3 mt-3'
          controlId='ValidityDate'>
          <Form.Label>{translation('validityDate')}*</Form.Label>
          <Form.Control
            required
            value={validityDate}
            type='date'
            onChange={(e) => {
              setValidityDate(e.target.value);
            }}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group
          as={Col}
          xl='6'
          className='mb-3'
          controlId='PublicationStart'>
          <Form.Label>{translation('publicationStart')}</Form.Label>
          <Form.Control
            value={publicationStart}
            type='date'
            onChange={(e) => {
              setPublicationStart(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group as={Col} xl='6' className='mb-3' controlId='PublicationEnd'>
          <Form.Label>{translation('publicationEnd')}</Form.Label>
          <Form.Control
            value={publicationEnd}
            type='date'
            onChange={(e) => {
              setPublicationEnd(e.target.value);
            }}
          />
        </Form.Group>
      </Row>
    </>
  );
}

VersionForm.defaultProps = {
  pdfArticle: false,
  setFile: null,
  file: undefined,
  isValidFile: true,
  setIsValidFile: null,
};

export default VersionForm;
