import { useEffect, useState } from 'react';
import {
  Button,
  Overlay,
  Popover,
  PopoverBody,
  PopoverHeader,
} from 'react-bootstrap';
import { useSessionStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Note } from '../../redux/store/api/api';
import {
  selectActiveNote,
  selectShowNotePopoverOpened,
  selectVersionHtmlContent,
  setShowNotePopoverOpened,
} from '../../redux/store/content/slice';
import {
  MARK_VISIBILITY_GROUP_KEY,
  VERSION_CONTENT_CONTAINER_ID,
} from '../../shared/constants';
import { NoteVisibilityType } from '../../shared/enums';
import { VisibilityGroupString } from './types';

const findTargetEl = (note: Note): HTMLElement | undefined => {
  const activeMarkId = note.mark?.id;
  if (!activeMarkId) {
    return undefined;
  }

  const activeMarkElements = document.querySelectorAll(
    `[id^='${activeMarkId}']`,
  );
  const lastNodeOfMark = activeMarkElements[activeMarkElements.length - 1];
  if (!lastNodeOfMark) {
    return undefined;
  }

  return lastNodeOfMark as HTMLElement;
};

function ShowNotePopover(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const note: Note = useAppSelector(selectActiveNote);
  const versionContentContainer = document.getElementById(
    VERSION_CONTENT_CONTAINER_ID,
  );
  const htmlContent = useAppSelector(selectVersionHtmlContent);
  const [activeMarkVisibilityGroup, setActiveMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );
  const show = useAppSelector(selectShowNotePopoverOpened);
  const [targetEl, setTargetEl] = useState(findTargetEl(note));

  const handleHide = (event: Event) => {
    const target = event.target as HTMLElement;
    if (!target || target.tagName !== 'MARK') {
      dispatch(setShowNotePopoverOpened(false));
    }
  };

  useEffect(() => {
    setTargetEl(findTargetEl(note));
  }, [note, htmlContent]);

  useEffect(() => {
    if (!show || !note.mark) {
      return;
    }
    if (
      activeMarkVisibilityGroup !== NoteVisibilityType[note.visibility || 0]
    ) {
      setActiveMarkVisibilityGroup(
        NoteVisibilityType[note.visibility || 0] as VisibilityGroupString,
      );
    }
  }, [show, note]);

  useEffect(() => {
    if (targetEl) {
      targetEl.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [targetEl]);

  return note.mark && show && targetEl ? (
    <Overlay
      target={targetEl}
      container={versionContentContainer}
      show={show}
      rootClose
      onHide={(e) => handleHide(e)}
      placement='bottom'>
      <Popover id='ShowNotePopover'>
        <PopoverHeader>
          <div className='d-flex justify-content-between align-items-center'>
            <h6 className='m-1'>{note.name}</h6>
            <Button
              onClick={() => dispatch(setShowNotePopoverOpened(false))}
              className='p-0 ms-3 m-1 border-0 bg-transparent'>
              <i className='icon-close text-dark' />
            </Button>
          </div>
        </PopoverHeader>
        {note.text && <PopoverBody>{note.text}</PopoverBody>}
      </Popover>
    </Overlay>
  ) : null;
}

export default ShowNotePopover;
