import { decode } from 'html-entities';
import { useEffect } from 'react';
import { HousesFill } from 'react-bootstrap-icons';
import ArticleAttachmentsContainer from '../components/article/ArticleAttachmentsContainer';
import ArticleView from '../components/article/ArticleView';
import useGetCategoryByArticleId from '../hooks/useGetCategoryByArticleId';
import { useAppSelector } from '../redux/hooks';
import { selectActiveVersion } from '../redux/store/content/slice';

function Article() {
  const version = useAppSelector(selectActiveVersion);
  const category = useGetCategoryByArticleId(version.articleId);

  useEffect(() => {
    document.getElementsByTagName('h1')[0].focus();
  }, []);

  return (
    <div className='article-view'>
      {category?.permittedPath && (
        <div className='category-path-container'>
          <p className='mb-2 ms-4 text-break'>
            <HousesFill aria-hidden className='me-2 fs-5 align-text-top' />
            {decode(category.permittedPath)}
          </p>
        </div>
      )}
      <div className='content-inner-article'>
        <ArticleView />
        <ArticleAttachmentsContainer />
      </div>
    </div>
  );
}

export default Article;
