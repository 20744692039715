import { baseApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiArticleReferencesById: build.query<
      GetApiArticleReferencesByIdApiResponse,
      GetApiArticleReferencesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/articleReferences/${queryArg.id}` }),
    }),
    putApiArticleReferencesById: build.mutation<
      PutApiArticleReferencesByIdApiResponse,
      PutApiArticleReferencesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articleReferences/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.articleReference,
      }),
    }),
    deleteApiArticleReferencesById: build.mutation<
      DeleteApiArticleReferencesByIdApiResponse,
      DeleteApiArticleReferencesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articleReferences/${queryArg.id}`,
        method: 'DELETE',
        params: { articleId: queryArg.articleId },
      }),
    }),
    postApiArticleReferences: build.mutation<
      PostApiArticleReferencesApiResponse,
      PostApiArticleReferencesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articleReferences`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiArticlesRecentChanges: build.query<
      GetApiArticlesRecentChangesApiResponse,
      GetApiArticlesRecentChangesApiArg
    >({
      query: () => ({ url: `/api/articles/recentChanges` }),
    }),
    getApiArticlesById: build.query<
      GetApiArticlesByIdApiResponse,
      GetApiArticlesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/articles/${queryArg.id}` }),
    }),
    putApiArticlesById: build.mutation<
      PutApiArticlesByIdApiResponse,
      PutApiArticlesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.article,
      }),
    }),
    postApiArticles: build.mutation<
      PostApiArticlesApiResponse,
      PostApiArticlesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles`,
        method: 'POST',
        params: { categoryId: queryArg.categoryId, type: queryArg['type'] },
      }),
    }),
    postApiArticlesStructure: build.mutation<
      PostApiArticlesStructureApiResponse,
      PostApiArticlesStructureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/structure`,
        method: 'POST',
        params: { categoryId: queryArg.categoryId, title: queryArg.title },
      }),
    }),
    postApiArticlesVersion: build.mutation<
      PostApiArticlesVersionApiResponse,
      PostApiArticlesVersionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/version`,
        method: 'POST',
        body: queryArg.version,
        params: { categoryId: queryArg.categoryId, type: queryArg['type'] },
      }),
    }),
    postApiArticlesVersionUpload: build.mutation<
      PostApiArticlesVersionUploadApiResponse,
      PostApiArticlesVersionUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/version/upload`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiArticlesMove: build.mutation<
      PutApiArticlesMoveApiResponse,
      PutApiArticlesMoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/move`,
        method: 'PUT',
        body: queryArg.body,
        params: {
          targetCategoryId: queryArg.targetCategoryId,
          position: queryArg.position,
        },
      }),
    }),
    postApiArticlesExportById: build.mutation<
      PostApiArticlesExportByIdApiResponse,
      PostApiArticlesExportByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/export/${queryArg.id}`,
        method: 'POST',
        params: { includeVersions: queryArg.includeVersions },
      }),
    }),
    getApiArticlesReferencesById: build.query<
      GetApiArticlesReferencesByIdApiResponse,
      GetApiArticlesReferencesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/articles/references/${queryArg.id}` }),
    }),
    putApiArticlesRecycle: build.mutation<
      PutApiArticlesRecycleApiResponse,
      PutApiArticlesRecycleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/recycle`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    putApiArticlesSetDisabledState: build.mutation<
      PutApiArticlesSetDisabledStateApiResponse,
      PutApiArticlesSetDisabledStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/articles/setDisabledState`,
        method: 'PUT',
        body: queryArg.body,
        params: { disabled: queryArg.disabled },
      }),
    }),
    getApiAttachmentsByVersionId: build.query<
      GetApiAttachmentsByVersionIdApiResponse,
      GetApiAttachmentsByVersionIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/attachments/${queryArg.versionId}` }),
    }),
    postApiAttachments: build.mutation<
      PostApiAttachmentsApiResponse,
      PostApiAttachmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/attachments`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    putApiAttachmentsById: build.mutation<
      PutApiAttachmentsByIdApiResponse,
      PutApiAttachmentsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/attachments/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.namedAttachment,
      }),
    }),
    deleteApiAttachmentsById: build.mutation<
      DeleteApiAttachmentsByIdApiResponse,
      DeleteApiAttachmentsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/attachments/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    putApiAttachmentsMoveById: build.mutation<
      PutApiAttachmentsMoveByIdApiResponse,
      PutApiAttachmentsMoveByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/attachments/move/${queryArg.id}`,
        method: 'PUT',
        params: { targetPosition: queryArg.targetPosition },
      }),
    }),
    getApiBookmarkFolders: build.query<
      GetApiBookmarkFoldersApiResponse,
      GetApiBookmarkFoldersApiArg
    >({
      query: () => ({ url: `/api/bookmarkFolders` }),
    }),
    postApiBookmarkFolders: build.mutation<
      PostApiBookmarkFoldersApiResponse,
      PostApiBookmarkFoldersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bookmarkFolders`,
        method: 'POST',
        body: queryArg.bookmarkFolder,
      }),
    }),
    getApiBookmarkFoldersById: build.query<
      GetApiBookmarkFoldersByIdApiResponse,
      GetApiBookmarkFoldersByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/bookmarkFolders/${queryArg.id}` }),
    }),
    putApiBookmarkFoldersById: build.mutation<
      PutApiBookmarkFoldersByIdApiResponse,
      PutApiBookmarkFoldersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bookmarkFolders/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.bookmarkFolder,
      }),
    }),
    deleteApiBookmarkFoldersById: build.mutation<
      DeleteApiBookmarkFoldersByIdApiResponse,
      DeleteApiBookmarkFoldersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bookmarkFolders/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    putApiBookmarkFoldersMoveById: build.mutation<
      PutApiBookmarkFoldersMoveByIdApiResponse,
      PutApiBookmarkFoldersMoveByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bookmarkFolders/move/${queryArg.id}`,
        method: 'PUT',
        params: {
          targetBookmarkFolderId: queryArg.targetBookmarkFolderId,
          position: queryArg.position,
        },
      }),
    }),
    getApiBookmarksById: build.query<
      GetApiBookmarksByIdApiResponse,
      GetApiBookmarksByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/bookmarks/${queryArg.id}` }),
    }),
    putApiBookmarksById: build.mutation<
      PutApiBookmarksByIdApiResponse,
      PutApiBookmarksByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bookmarks/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.bookmark,
      }),
    }),
    deleteApiBookmarksById: build.mutation<
      DeleteApiBookmarksByIdApiResponse,
      DeleteApiBookmarksByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bookmarks/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiBookmarks: build.mutation<
      PostApiBookmarksApiResponse,
      PostApiBookmarksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bookmarks`,
        method: 'POST',
        body: queryArg.bookmark,
      }),
    }),
    putApiBookmarksMoveById: build.mutation<
      PutApiBookmarksMoveByIdApiResponse,
      PutApiBookmarksMoveByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bookmarks/move/${queryArg.id}`,
        method: 'PUT',
        params: {
          targetBookmarkFolderId: queryArg.targetBookmarkFolderId,
          position: queryArg.position,
        },
      }),
    }),
    getApiCategoriesOverviewById: build.query<
      GetApiCategoriesOverviewByIdApiResponse,
      GetApiCategoriesOverviewByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/categories/overview/${queryArg.id}` }),
    }),
    getApiCategoriesById: build.query<
      GetApiCategoriesByIdApiResponse,
      GetApiCategoriesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/categories/${queryArg.id}` }),
    }),
    putApiCategoriesById: build.mutation<
      PutApiCategoriesByIdApiResponse,
      PutApiCategoriesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categories/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.category,
        params: { setCategoryTypeRecursive: queryArg.setCategoryTypeRecursive },
      }),
    }),
    postApiCategories: build.mutation<
      PostApiCategoriesApiResponse,
      PostApiCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categories`,
        method: 'POST',
        body: queryArg.category,
      }),
    }),
    putApiCategoriesSetPublishDates: build.mutation<
      PutApiCategoriesSetPublishDatesApiResponse,
      PutApiCategoriesSetPublishDatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categories/setPublishDates`,
        method: 'PUT',
        params: {
          categoryId: queryArg.categoryId,
          publishFrom: queryArg.publishFrom,
          publishTo: queryArg.publishTo,
        },
      }),
    }),
    putApiCategoriesSetValidFrom: build.mutation<
      PutApiCategoriesSetValidFromApiResponse,
      PutApiCategoriesSetValidFromApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categories/setValidFrom`,
        method: 'PUT',
        params: {
          categoryId: queryArg.categoryId,
          validFrom: queryArg.validFrom,
        },
      }),
    }),
    postApiCategoriesImport: build.mutation<
      PostApiCategoriesImportApiResponse,
      PostApiCategoriesImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categories/import`,
        method: 'POST',
        body: queryArg.body,
        params: {
          categoryId: queryArg.categoryId,
          isPreview: queryArg.isPreview,
          isCorrection: queryArg.isCorrection,
          isReferenceImport: queryArg.isReferenceImport,
          validFrom: queryArg.validFrom,
        },
      }),
    }),
    postApiCategoriesExportById: build.mutation<
      PostApiCategoriesExportByIdApiResponse,
      PostApiCategoriesExportByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categories/export/${queryArg.id}`,
        method: 'POST',
      }),
    }),
    getApiCategoriesArticleReferencesById: build.query<
      GetApiCategoriesArticleReferencesByIdApiResponse,
      GetApiCategoriesArticleReferencesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categories/articleReferences/${queryArg.id}`,
      }),
    }),
    getApiCategoryTreeCheckForChange: build.query<
      GetApiCategoryTreeCheckForChangeApiResponse,
      GetApiCategoryTreeCheckForChangeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categoryTree/checkForChange`,
        params: { utcTimestamp: queryArg.utcTimestamp },
      }),
    }),
    getApiCategoryTree: build.query<
      GetApiCategoryTreeApiResponse,
      GetApiCategoryTreeApiArg
    >({
      query: () => ({ url: `/api/categoryTree` }),
    }),
    putApiCategoryTreeSetDisabledState: build.mutation<
      PutApiCategoryTreeSetDisabledStateApiResponse,
      PutApiCategoryTreeSetDisabledStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categoryTree/setDisabledState`,
        method: 'PUT',
        body: queryArg.categoryTreeBatchOperationItems,
        params: { disabled: queryArg.disabled },
      }),
    }),
    putApiCategoryTreeMove: build.mutation<
      PutApiCategoryTreeMoveApiResponse,
      PutApiCategoryTreeMoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categoryTree/move`,
        method: 'PUT',
        body: queryArg.categoryTreeBatchOperationItems,
        params: {
          targetCategoryId: queryArg.targetCategoryId,
          position: queryArg.position,
        },
      }),
    }),
    putApiCategoryTreeRecycle: build.mutation<
      PutApiCategoryTreeRecycleApiResponse,
      PutApiCategoryTreeRecycleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categoryTree/recycle`,
        method: 'PUT',
        body: queryArg.categoryTreeBatchOperationItems,
      }),
    }),
    getApiCategoryTypes: build.query<
      GetApiCategoryTypesApiResponse,
      GetApiCategoryTypesApiArg
    >({
      query: () => ({ url: `/api/categoryTypes` }),
    }),
    postApiCategoryTypes: build.mutation<
      PostApiCategoryTypesApiResponse,
      PostApiCategoryTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categoryTypes`,
        method: 'POST',
        body: queryArg.categoryType,
      }),
    }),
    getApiCategoryTypesById: build.query<
      GetApiCategoryTypesByIdApiResponse,
      GetApiCategoryTypesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/categoryTypes/${queryArg.id}` }),
    }),
    putApiCategoryTypesById: build.mutation<
      PutApiCategoryTypesByIdApiResponse,
      PutApiCategoryTypesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categoryTypes/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.categoryType,
      }),
    }),
    deleteApiCategoryTypesById: build.mutation<
      DeleteApiCategoryTypesByIdApiResponse,
      DeleteApiCategoryTypesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categoryTypes/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiCategoryTypesIsInUseById: build.query<
      GetApiCategoryTypesIsInUseByIdApiResponse,
      GetApiCategoryTypesIsInUseByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/categoryTypes/isInUse/${queryArg.id}`,
      }),
    }),
    getApiExternalReferencesById: build.query<
      GetApiExternalReferencesByIdApiResponse,
      GetApiExternalReferencesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/externalReferences/${queryArg.id}` }),
    }),
    putApiExternalReferencesById: build.mutation<
      PutApiExternalReferencesByIdApiResponse,
      PutApiExternalReferencesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/externalReferences/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.externalReference,
      }),
    }),
    deleteApiExternalReferencesById: build.mutation<
      DeleteApiExternalReferencesByIdApiResponse,
      DeleteApiExternalReferencesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/externalReferences/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    postApiExternalReferences: build.mutation<
      PostApiExternalReferencesApiResponse,
      PostApiExternalReferencesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/externalReferences`,
        method: 'POST',
        body: queryArg.externalReference,
      }),
    }),
    postApiFilesById: build.mutation<
      PostApiFilesByIdApiResponse,
      PostApiFilesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/files/${queryArg.id}`,
        method: 'POST',
      }),
    }),
    postApiFilesVersionById: build.mutation<
      PostApiFilesVersionByIdApiResponse,
      PostApiFilesVersionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/files/version/${queryArg.id}`,
        method: 'POST',
      }),
    }),
    postApiMarks: build.mutation<PostApiMarksApiResponse, PostApiMarksApiArg>({
      query: (queryArg) => ({
        url: `/api/marks`,
        method: 'POST',
        body: queryArg.mark,
      }),
    }),
    putApiMarksById: build.mutation<
      PutApiMarksByIdApiResponse,
      PutApiMarksByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/marks/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.mark,
      }),
    }),
    deleteApiMarksById: build.mutation<
      DeleteApiMarksByIdApiResponse,
      DeleteApiMarksByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/marks/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    putApiMarksUpdateModifiedDates: build.mutation<
      PutApiMarksUpdateModifiedDatesApiResponse,
      PutApiMarksUpdateModifiedDatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/marks/updateModifiedDates`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiNotesByVersionId: build.query<
      GetApiNotesByVersionIdApiResponse,
      GetApiNotesByVersionIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/notes/${queryArg.versionId}` }),
    }),
    postApiNotes: build.mutation<PostApiNotesApiResponse, PostApiNotesApiArg>({
      query: (queryArg) => ({
        url: `/api/notes`,
        method: 'POST',
        body: queryArg.note,
      }),
    }),
    putApiNotesById: build.mutation<
      PutApiNotesByIdApiResponse,
      PutApiNotesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notes/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.note,
      }),
    }),
    deleteApiNotesById: build.mutation<
      DeleteApiNotesByIdApiResponse,
      DeleteApiNotesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notes/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    putApiNotesMoveById: build.mutation<
      PutApiNotesMoveByIdApiResponse,
      PutApiNotesMoveByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notes/move/${queryArg.id}`,
        method: 'PUT',
        params: { targetPosition: queryArg.targetPosition },
      }),
    }),
    getApiPermissionsUser: build.query<
      GetApiPermissionsUserApiResponse,
      GetApiPermissionsUserApiArg
    >({
      query: () => ({ url: `/api/permissions/user` }),
    }),
    getApiPermissions: build.query<
      GetApiPermissionsApiResponse,
      GetApiPermissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permissions`,
        params: { userGroupId: queryArg.userGroupId, roleId: queryArg.roleId },
      }),
    }),
    postApiPermissions: build.mutation<
      PostApiPermissionsApiResponse,
      PostApiPermissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permissions`,
        method: 'POST',
        body: queryArg.permission,
      }),
    }),
    putApiPermissionsById: build.mutation<
      PutApiPermissionsByIdApiResponse,
      PutApiPermissionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permissions/${queryArg.id}`,
        method: 'PUT',
      }),
    }),
    deleteApiPermissionsById: build.mutation<
      DeleteApiPermissionsByIdApiResponse,
      DeleteApiPermissionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permissions/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiRecycleBin: build.query<
      GetApiRecycleBinApiResponse,
      GetApiRecycleBinApiArg
    >({
      query: () => ({ url: `/api/recycleBin` }),
    }),
    deleteApiRecycleBinEmpty: build.mutation<
      DeleteApiRecycleBinEmptyApiResponse,
      DeleteApiRecycleBinEmptyApiArg
    >({
      query: () => ({ url: `/api/recycleBin/empty`, method: 'DELETE' }),
    }),
    deleteApiRecycleBinDelete: build.mutation<
      DeleteApiRecycleBinDeleteApiResponse,
      DeleteApiRecycleBinDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/recycleBin/delete`,
        method: 'DELETE',
        body: queryArg.categoryTreeBatchOperationItems,
      }),
    }),
    putApiRecycleBinRestore: build.mutation<
      PutApiRecycleBinRestoreApiResponse,
      PutApiRecycleBinRestoreApiArg
    >({
      query: (queryArg) => ({
        url: `/api/recycleBin/restore`,
        method: 'PUT',
        body: queryArg.categoryTreeBatchOperationItems,
        params: { targetCategoryId: queryArg.targetCategoryId },
      }),
    }),
    getApiRoles: build.query<GetApiRolesApiResponse, GetApiRolesApiArg>({
      query: () => ({ url: `/api/roles` }),
    }),
    postApiRoles: build.mutation<PostApiRolesApiResponse, PostApiRolesApiArg>({
      query: (queryArg) => ({
        url: `/api/roles`,
        method: 'POST',
        body: queryArg.role,
      }),
    }),
    getApiRolesContext: build.query<
      GetApiRolesContextApiResponse,
      GetApiRolesContextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/roles/context`,
        params: {
          context: queryArg.context,
          userGroupId: queryArg.userGroupId,
        },
      }),
    }),
    getApiRolesById: build.query<
      GetApiRolesByIdApiResponse,
      GetApiRolesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/roles/${queryArg.id}` }),
    }),
    putApiRolesById: build.mutation<
      PutApiRolesByIdApiResponse,
      PutApiRolesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/roles/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.role,
      }),
    }),
    deleteApiRolesById: build.mutation<
      DeleteApiRolesByIdApiResponse,
      DeleteApiRolesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/roles/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiRolesRightsCategoriesByPermissionContext: build.query<
      GetApiRolesRightsCategoriesByPermissionContextApiResponse,
      GetApiRolesRightsCategoriesByPermissionContextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/roles/rights/categories/${queryArg.permissionContext}`,
      }),
    }),
    getApiRolesIsInUseById: build.query<
      GetApiRolesIsInUseByIdApiResponse,
      GetApiRolesIsInUseByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/roles/isInUse/${queryArg.id}` }),
    }),
    putApiRolesRights: build.mutation<
      PutApiRolesRightsApiResponse,
      PutApiRolesRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/roles/rights`,
        method: 'PUT',
        body: queryArg.body,
        params: { roleId: queryArg.roleId },
      }),
    }),
    putApiRolesRightsCategories: build.mutation<
      PutApiRolesRightsCategoriesApiResponse,
      PutApiRolesRightsCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/roles/rights/categories`,
        method: 'PUT',
        body: queryArg.roleRightCategory,
      }),
    }),
    postApiSearchArticles: build.mutation<
      PostApiSearchArticlesApiResponse,
      PostApiSearchArticlesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/search/articles`,
        method: 'POST',
        body: queryArg.searchAttributes,
      }),
    }),
    postApiSearchCategories: build.mutation<
      PostApiSearchCategoriesApiResponse,
      PostApiSearchCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/search/categories`,
        method: 'POST',
        body: queryArg.searchAttributes,
      }),
    }),
    getApiSettingsContainersGlobals: build.query<
      GetApiSettingsContainersGlobalsApiResponse,
      GetApiSettingsContainersGlobalsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/containers/globals`,
        params: {
          type: queryArg['type'],
          includeSettings: queryArg.includeSettings,
        },
      }),
    }),
    getApiSettingsContainersUserGroups: build.query<
      GetApiSettingsContainersUserGroupsApiResponse,
      GetApiSettingsContainersUserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/containers/userGroups`,
        params: { userGroupId: queryArg.userGroupId },
      }),
    }),
    postApiSettingsContainersUserGroups: build.mutation<
      PostApiSettingsContainersUserGroupsApiResponse,
      PostApiSettingsContainersUserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/containers/userGroups`,
        method: 'POST',
        params: { userGroupId: queryArg.userGroupId, type: queryArg['type'] },
      }),
    }),
    putApiSettingsContainersUserGroups: build.mutation<
      PutApiSettingsContainersUserGroupsApiResponse,
      PutApiSettingsContainersUserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/containers/userGroups`,
        method: 'PUT',
        params: { settingContainerId: queryArg.settingContainerId },
      }),
    }),
    deleteApiSettingsContainersUserGroupsById: build.mutation<
      DeleteApiSettingsContainersUserGroupsByIdApiResponse,
      DeleteApiSettingsContainersUserGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/containers/userGroups/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiSettingsById: build.query<
      GetApiSettingsByIdApiResponse,
      GetApiSettingsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/settings/${queryArg.id}` }),
    }),
    getApiSettingsUserBySettingTypeKey: build.query<
      GetApiSettingsUserBySettingTypeKeyApiResponse,
      GetApiSettingsUserBySettingTypeKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/user/${queryArg.settingTypeKey}`,
      }),
    }),
    getApiSettingsGlobals: build.query<
      GetApiSettingsGlobalsApiResponse,
      GetApiSettingsGlobalsApiArg
    >({
      query: () => ({ url: `/api/settings/globals` }),
    }),
    getApiSettingsUser: build.query<
      GetApiSettingsUserApiResponse,
      GetApiSettingsUserApiArg
    >({
      query: () => ({ url: `/api/settings/user` }),
    }),
    putApiSettings: build.mutation<
      PutApiSettingsApiResponse,
      PutApiSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiSettingsPublicBySettingTypeKey: build.query<
      GetApiSettingsPublicBySettingTypeKeyApiResponse,
      GetApiSettingsPublicBySettingTypeKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/public/${queryArg.settingTypeKey}`,
      }),
    }),
    postApiSourceContentAreas: build.mutation<
      PostApiSourceContentAreasApiResponse,
      PostApiSourceContentAreasApiArg
    >({
      query: (queryArg) => ({
        url: `/api/sourceContentAreas`,
        method: 'POST',
        body: queryArg.sourceContentArea,
      }),
    }),
    putApiSourceContentAreasById: build.mutation<
      PutApiSourceContentAreasByIdApiResponse,
      PutApiSourceContentAreasByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/sourceContentAreas/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.sourceContentArea,
      }),
    }),
    deleteApiSourceContentAreasById: build.mutation<
      DeleteApiSourceContentAreasByIdApiResponse,
      DeleteApiSourceContentAreasByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/sourceContentAreas/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    putApiSourceContentAreasUpdateModifiedDates: build.mutation<
      PutApiSourceContentAreasUpdateModifiedDatesApiResponse,
      PutApiSourceContentAreasUpdateModifiedDatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/sourceContentAreas/updateModifiedDates`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    postApiSupport: build.mutation<
      PostApiSupportApiResponse,
      PostApiSupportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/support`,
        method: 'POST',
        body: queryArg.emailMessage,
      }),
    }),
    getApiUserGroups: build.query<
      GetApiUserGroupsApiResponse,
      GetApiUserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/userGroups`,
        params: {
          includeUsers: queryArg.includeUsers,
          includePermittedActions: queryArg.includePermittedActions,
        },
      }),
    }),
    postApiUserGroups: build.mutation<
      PostApiUserGroupsApiResponse,
      PostApiUserGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/userGroups`,
        method: 'POST',
        body: queryArg.userGroup,
      }),
    }),
    getApiUserGroupsById: build.query<
      GetApiUserGroupsByIdApiResponse,
      GetApiUserGroupsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/userGroups/${queryArg.id}` }),
    }),
    putApiUserGroupsById: build.mutation<
      PutApiUserGroupsByIdApiResponse,
      PutApiUserGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/userGroups/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.userGroup,
      }),
    }),
    deleteApiUserGroupsById: build.mutation<
      DeleteApiUserGroupsByIdApiResponse,
      DeleteApiUserGroupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/userGroups/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiUserGroupsMoveCheckMoveById: build.query<
      GetApiUserGroupsMoveCheckMoveByIdApiResponse,
      GetApiUserGroupsMoveCheckMoveByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/userGroups/move/checkMove/${queryArg.id}`,
        params: { targetUserGroupId: queryArg.targetUserGroupId },
      }),
    }),
    putApiUserGroupsMoveById: build.mutation<
      PutApiUserGroupsMoveByIdApiResponse,
      PutApiUserGroupsMoveByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/userGroups/move/${queryArg.id}`,
        method: 'PUT',
        params: { targetUserGroupId: queryArg.targetUserGroupId },
      }),
    }),
    getApiUserGroupsAddUserCheckAddById: build.query<
      GetApiUserGroupsAddUserCheckAddByIdApiResponse,
      GetApiUserGroupsAddUserCheckAddByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/userGroups/addUser/checkAdd/${queryArg.id}`,
      }),
    }),
    getApiUsersById: build.query<
      GetApiUsersByIdApiResponse,
      GetApiUsersByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/users/${queryArg.id}` }),
    }),
    putApiUsersById: build.mutation<
      PutApiUsersByIdApiResponse,
      PutApiUsersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.user,
      }),
    }),
    postApiUsers: build.mutation<PostApiUsersApiResponse, PostApiUsersApiArg>({
      query: (queryArg) => ({
        url: `/api/users`,
        method: 'POST',
        body: queryArg.user,
      }),
    }),
    deleteApiUsers: build.mutation<
      DeleteApiUsersApiResponse,
      DeleteApiUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users`,
        method: 'DELETE',
        body: queryArg.body,
      }),
    }),
    postApiUsersMoveCheckMove: build.mutation<
      PostApiUsersMoveCheckMoveApiResponse,
      PostApiUsersMoveCheckMoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/move/checkMove`,
        method: 'POST',
        body: queryArg.body,
        params: { targetUserGroupId: queryArg.targetUserGroupId },
      }),
    }),
    putApiUsersMove: build.mutation<
      PutApiUsersMoveApiResponse,
      PutApiUsersMoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/move`,
        method: 'PUT',
        body: queryArg.body,
        params: { targetUserGroupId: queryArg.targetUserGroupId },
      }),
    }),
    postApiUsersByIdConfirmEmail: build.mutation<
      PostApiUsersByIdConfirmEmailApiResponse,
      PostApiUsersByIdConfirmEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg.id}/confirmEmail`,
        method: 'POST',
      }),
    }),
    postApiUsersActivationEmail: build.mutation<
      PostApiUsersActivationEmailApiResponse,
      PostApiUsersActivationEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/activationEmail`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiUsersByIdResetPassword: build.mutation<
      PostApiUsersByIdResetPasswordApiResponse,
      PostApiUsersByIdResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/${queryArg.id}/resetPassword`,
        method: 'POST',
      }),
    }),
    postApiUsersImport: build.mutation<
      PostApiUsersImportApiResponse,
      PostApiUsersImportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/import`,
        method: 'POST',
        body: queryArg.body,
        params: { userGroupId: queryArg.userGroupId },
      }),
    }),
    putApiUsersLockoutToggle: build.mutation<
      PutApiUsersLockoutToggleApiResponse,
      PutApiUsersLockoutToggleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/lockoutToggle`,
        method: 'PUT',
        params: { userId: queryArg.userId },
      }),
    }),
    getApiUsersPublicKey: build.query<
      GetApiUsersPublicKeyApiResponse,
      GetApiUsersPublicKeyApiArg
    >({
      query: () => ({ url: `/api/users/publicKey` }),
    }),
    postApiUsersValidPassword: build.mutation<
      PostApiUsersValidPasswordApiResponse,
      PostApiUsersValidPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/validPassword`,
        method: 'POST',
        body: queryArg.password,
      }),
    }),
    getApiVersionsById: build.query<
      GetApiVersionsByIdApiResponse,
      GetApiVersionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/versions/${queryArg.id}` }),
    }),
    putApiVersionsById: build.mutation<
      PutApiVersionsByIdApiResponse,
      PutApiVersionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/versions/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.namedVersion,
      }),
    }),
    deleteApiVersionsById: build.mutation<
      DeleteApiVersionsByIdApiResponse,
      DeleteApiVersionsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/versions/${queryArg.id}`,
        method: 'DELETE',
        params: { isDraft: queryArg.isDraft },
      }),
    }),
    getApiVersionsArticleById: build.query<
      GetApiVersionsArticleByIdApiResponse,
      GetApiVersionsArticleByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/versions/article/${queryArg.id}`,
        params: { editMode: queryArg.editMode },
      }),
    }),
    postApiVersions: build.mutation<
      PostApiVersionsApiResponse,
      PostApiVersionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/versions`,
        method: 'POST',
        body: queryArg.version,
        params: { oldVersionId: queryArg.oldVersionId },
      }),
    }),
    postApiVersionsFile: build.mutation<
      PostApiVersionsFileApiResponse,
      PostApiVersionsFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/versions/file`,
        method: 'POST',
        body: queryArg.body,
        params: { oldVersionId: queryArg.oldVersionId },
      }),
    }),
    putApiVersionsFile: build.mutation<
      PutApiVersionsFileApiResponse,
      PutApiVersionsFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/versions/file`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    putApiVersionsConvertById: build.mutation<
      PutApiVersionsConvertByIdApiResponse,
      PutApiVersionsConvertByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/versions/convert/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.namedVersion,
        params: { targetVersionId: queryArg.targetVersionId },
      }),
    }),
    putApiVersionsToggleDisableById: build.mutation<
      PutApiVersionsToggleDisableByIdApiResponse,
      PutApiVersionsToggleDisableByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/versions/toggleDisable/${queryArg.id}`,
        method: 'PUT',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetApiArticleReferencesByIdApiResponse =
  /** status 200 Success */ ArticleReferenceStatusResult;
export type GetApiArticleReferencesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiArticleReferencesByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiArticleReferencesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** Article reference to update (id required) */
  articleReference: ArticleReference;
};
export type DeleteApiArticleReferencesByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiArticleReferencesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** Key Id (22 characters, only for imported refs with content link to remove corresponding id in content) */
  articleId?: string;
};
export type PostApiArticleReferencesApiResponse =
  /** status 201 Created */ StatusResult;
export type PostApiArticleReferencesApiArg = {
  /** Article references to add */
  body: ArticleReference[];
};
export type GetApiArticlesRecentChangesApiResponse =
  /** status 200 Success */ RecentChangesItemIEnumerableStatusResult;
export type GetApiArticlesRecentChangesApiArg = void;
export type GetApiArticlesByIdApiResponse =
  /** status 200 Success */ ArticleStatusResult;
export type GetApiArticlesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiArticlesByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiArticlesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** Article to update (id required) */
  article: Article;
};
export type PostApiArticlesApiResponse =
  /** status 201 Created */ ArticleVersionStatusResult;
export type PostApiArticlesApiArg = {
  /** Id of Category to create article in */
  categoryId: string;
  /** Type of article to create */
  type?: ArticleType;
};
export type PostApiArticlesStructureApiResponse =
  /** status 201 Created */ ArticleStatusResult;
export type PostApiArticlesStructureApiArg = {
  /** Id of Category to create structure element in */
  categoryId: string;
  /** Title of structure element */
  title?: string;
};
export type PostApiArticlesVersionApiResponse =
  /** status 201 Created */ ArticleVersionStatusResult;
export type PostApiArticlesVersionApiArg = {
  /** Id of Category to create article in */
  categoryId: string;
  /** Type of article to create */
  type?: ArticleType;
  /** NewVersion to add */
  version: Version;
};
export type PostApiArticlesVersionUploadApiResponse =
  /** status 201 Created */ ArticleVersionStatusResult;
export type PostApiArticlesVersionUploadApiArg = {
  body: {
    ArticlePdf?: Blob;
    CategoryId?: string;
    Type?: ArticleType;
    'Version.Id'?: string;
    'Version.Type'?: ArticleType;
    'Version.ArticleId'?: string;
    'Version.Draft'?: boolean;
    'Version.Disabled'?: boolean;
    'Version.Title'?: string;
    'Version.ValidFrom'?: string;
    'Version.PublishedFrom'?: string;
    'Version.PublishedUntil'?: string;
    'Version.HtmlContent'?: string;
    'Version.PdfFileId'?: string;
    'Version.ModifiedDate'?: string;
    'Version.IsRecentVersion'?: boolean;
    'Version.LastModifiedBy'?: string;
  };
};
export type PutApiArticlesMoveApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiArticlesMoveApiArg = {
  /** Id of target category (22 characters) */
  targetCategoryId: string;
  /** position in category (optional, if not present, article is moved to end) */
  position?: number;
  /** ArticleIds (22 characters) */
  body: string[];
};
export type PostApiArticlesExportByIdApiResponse =
  /** status 200 Success */ Blob;
export type PostApiArticlesExportByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** Indicates if all versions should be exported */
  includeVersions?: boolean;
};
export type GetApiArticlesReferencesByIdApiResponse =
  /** status 200 Success */ ReferenceListStatusResult;
export type GetApiArticlesReferencesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiArticlesRecycleApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiArticlesRecycleApiArg = {
  /** Ids (22 characters) */
  body: string[];
};
export type PutApiArticlesSetDisabledStateApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiArticlesSetDisabledStateApiArg = {
  /** state to set */
  disabled?: boolean;
  /** Key Id (22 characters) */
  body: string[];
};
export type GetApiAttachmentsByVersionIdApiResponse =
  /** status 200 Success */ AttachmentIEnumerableStatusResult;
export type GetApiAttachmentsByVersionIdApiArg = {
  /** Key Id (22 characters) */
  versionId: string;
};
export type PostApiAttachmentsApiResponse =
  /** status 201 Created */ AttachmentIEnumerableStatusResult;
export type PostApiAttachmentsApiArg = {
  body: {
    VersionId?: string;
    AttachmentFiles?: Blob[];
  };
};
export type PutApiAttachmentsByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiAttachmentsByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** Attachment containing name */
  namedAttachment: NamedAttachment;
};
export type DeleteApiAttachmentsByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiAttachmentsByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiAttachmentsMoveByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiAttachmentsMoveByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** Order of target position */
  targetPosition: number;
};
export type GetApiBookmarkFoldersApiResponse =
  /** status 200 Success */ BookmarkTreeStatusResult;
export type GetApiBookmarkFoldersApiArg = void;
export type PostApiBookmarkFoldersApiResponse =
  /** status 201 Created */ BookmarkFolderStatusResult;
export type PostApiBookmarkFoldersApiArg = {
  /** Bookmark folder to create (id not required).
                Order parameter is ignored/not required. Set to next available order based on number of bookmark folders existing. */
  bookmarkFolder: BookmarkFolder;
};
export type GetApiBookmarkFoldersByIdApiResponse =
  /** status 200 Success */ BookmarkFolderStatusResult;
export type GetApiBookmarkFoldersByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiBookmarkFoldersByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiBookmarkFoldersByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** Bookmark folder to update (id required) */
  bookmarkFolder: BookmarkFolder;
};
export type DeleteApiBookmarkFoldersByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiBookmarkFoldersByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiBookmarkFoldersMoveByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiBookmarkFoldersMoveByIdApiArg = {
  /** BookmarkFolderId (22 characters) */
  id: string;
  /** Id of target bookmark folder (22 characters) */
  targetBookmarkFolderId: string;
  /** position in bookmarkFolder (optional, if not present, bookmark folder is moved to end) */
  position?: number;
};
export type GetApiBookmarksByIdApiResponse =
  /** status 200 Success */ BookmarkStatusResult;
export type GetApiBookmarksByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiBookmarksByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiBookmarksByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** Bookmark to update (id required) */
  bookmark: Bookmark;
};
export type DeleteApiBookmarksByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiBookmarksByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PostApiBookmarksApiResponse =
  /** status 201 Created */ BookmarkStatusResult;
export type PostApiBookmarksApiArg = {
  /** Bookmark to create (id not required). Order parameter is ignored/not required. Set to next available order based on number of bookmarks existing. */
  bookmark: Bookmark;
};
export type PutApiBookmarksMoveByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiBookmarksMoveByIdApiArg = {
  /** BookmarkId (22 characters) */
  id: string;
  /** Id of target bookmark folder (22 characters) */
  targetBookmarkFolderId: string;
  /** position in bookmarkFolder (optional, if not present, bookmark is moved to end) */
  position?: number;
};
export type GetApiCategoriesOverviewByIdApiResponse =
  /** status 200 Success */ CategoryTreeStatusResult;
export type GetApiCategoriesOverviewByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type GetApiCategoriesByIdApiResponse =
  /** status 200 Success */ CategoryStatusResult;
export type GetApiCategoriesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiCategoriesByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiCategoriesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** indicates if category type should be set recursive to all subcategories */
  setCategoryTypeRecursive?: boolean;
  /** Category to update (id required) */
  category: Category;
};
export type PostApiCategoriesApiResponse =
  /** status 201 Created */ CategoryStatusResult;
export type PostApiCategoriesApiArg = {
  /** Category to create (id not required). Order parameter is ignored/not required. Set to next available order based on number of categories existing. */
  category: Category;
};
export type PutApiCategoriesSetPublishDatesApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiCategoriesSetPublishDatesApiArg = {
  /** Id of category to set publish dates of recent versions recursive(22 characters) */
  categoryId?: string;
  /** Publish from date */
  publishFrom?: string;
  /** Publish to date */
  publishTo?: string;
};
export type PutApiCategoriesSetValidFromApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiCategoriesSetValidFromApiArg = {
  /** Id of category to set validFrom date of recent versions recursive(22 characters) */
  categoryId?: string;
  /** Publish from date */
  validFrom?: string;
};
export type PostApiCategoriesImportApiResponse = /** status 201 Created */
  | ImportPreviewStatusResult
  | /** status 204 No Content */ ImportPreviewStatusResult;
export type PostApiCategoriesImportApiArg = {
  /** Id of category to add imported content */
  categoryId: string;
  /** Load preview of import */
  isPreview?: boolean;
  /** Import and replace current versions */
  isCorrection?: boolean;
  /** Only import article references */
  isReferenceImport?: boolean;
  /** Import valid from date for article versions */
  validFrom?: string;
  body: {
    importFiles: Blob[];
  };
};
export type PostApiCategoriesExportByIdApiResponse =
  /** status 200 Success */ Blob;
export type PostApiCategoriesExportByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type GetApiCategoriesArticleReferencesByIdApiResponse =
  /** status 200 Success */ ArticleReferenceListIEnumerableStatusResult;
export type GetApiCategoriesArticleReferencesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type GetApiCategoryTreeCheckForChangeApiResponse =
  /** status 200 Success */ BooleanStatusResult;
export type GetApiCategoryTreeCheckForChangeApiArg = {
  /** Timestamp in UTC time since when the changes should be considered */
  utcTimestamp?: string;
};
export type GetApiCategoryTreeApiResponse =
  /** status 200 Success */ CategoryTreeStatusResult;
export type GetApiCategoryTreeApiArg = void;
export type PutApiCategoryTreeSetDisabledStateApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiCategoryTreeSetDisabledStateApiArg = {
  /** state to set */
  disabled?: boolean;
  /** Ids of elements to set disabled state for (22 characters) */
  categoryTreeBatchOperationItems: CategoryTreeBatchOperationItems;
};
export type PutApiCategoryTreeMoveApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiCategoryTreeMoveApiArg = {
  /** Id of target category (22 characters) */
  targetCategoryId: string;
  /** position in parent category (optional, if not present, category is moved to end) */
  position?: number;
  /** Ids of items that need to be moved (22 characters) */
  categoryTreeBatchOperationItems: CategoryTreeBatchOperationItems;
};
export type PutApiCategoryTreeRecycleApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiCategoryTreeRecycleApiArg = {
  /** List of Ids (22 characters) */
  categoryTreeBatchOperationItems: CategoryTreeBatchOperationItems;
};
export type GetApiCategoryTypesApiResponse =
  /** status 200 Success */ CategoryTypeIEnumerableStatusResult;
export type GetApiCategoryTypesApiArg = void;
export type PostApiCategoryTypesApiResponse =
  /** status 201 Created */ CategoryTypeStatusResult;
export type PostApiCategoryTypesApiArg = {
  /** Category type to create (id not required) */
  categoryType: CategoryType;
};
export type GetApiCategoryTypesByIdApiResponse =
  /** status 200 Success */ CategoryTypeStatusResult;
export type GetApiCategoryTypesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiCategoryTypesByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiCategoryTypesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** Category type to update (id required) */
  categoryType: CategoryType;
};
export type DeleteApiCategoryTypesByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiCategoryTypesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type GetApiCategoryTypesIsInUseByIdApiResponse =
  /** status 200 Success */ BooleanStatusResult;
export type GetApiCategoryTypesIsInUseByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type GetApiExternalReferencesByIdApiResponse =
  /** status 200 Success */ ExternalReferenceStatusResult;
export type GetApiExternalReferencesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiExternalReferencesByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiExternalReferencesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** External reference to update (id required) */
  externalReference: ExternalReference;
};
export type DeleteApiExternalReferencesByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiExternalReferencesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PostApiExternalReferencesApiResponse =
  /** status 201 Created */ ExternalReferenceStatusResult;
export type PostApiExternalReferencesApiArg = {
  /** External reference to add */
  externalReference: ExternalReference;
};
export type PostApiFilesByIdApiResponse = /** status 200 Success */ Blob;
export type PostApiFilesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PostApiFilesVersionByIdApiResponse = /** status 200 Success */ Blob;
export type PostApiFilesVersionByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PostApiMarksApiResponse =
  /** status 201 Created */ MarkStatusResult;
export type PostApiMarksApiArg = {
  /** Mark to add */
  mark: Mark;
};
export type PutApiMarksByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiMarksByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** Mark to update */
  mark: Mark;
};
export type DeleteApiMarksByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiMarksByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiMarksUpdateModifiedDatesApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiMarksUpdateModifiedDatesApiArg = {
  /** Key Ids (22 characters) */
  body: string[];
};
export type GetApiNotesByVersionIdApiResponse =
  /** status 200 Success */ SortedNotesStatusResult;
export type GetApiNotesByVersionIdApiArg = {
  /** Foreign Key Id (22 characters) */
  versionId: string;
};
export type PostApiNotesApiResponse =
  /** status 201 Created */ NoteStatusResult;
export type PostApiNotesApiArg = {
  /** Note to add */
  note: Note;
};
export type PutApiNotesByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiNotesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** The updated note */
  note: Note;
};
export type DeleteApiNotesByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiNotesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiNotesMoveByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiNotesMoveByIdApiArg = {
  /** Id (22 characters) */
  id: string;
  /** Order of target position */
  targetPosition?: number;
};
export type GetApiPermissionsUserApiResponse =
  /** status 200 Success */ RightKeyIEnumerableStatusResult;
export type GetApiPermissionsUserApiArg = void;
export type GetApiPermissionsApiResponse =
  /** status 200 Success */ PermissionSetStatusResult;
export type GetApiPermissionsApiArg = {
  /** Id of user group */
  userGroupId: string;
  /** Id of role */
  roleId?: string;
};
export type PostApiPermissionsApiResponse =
  /** status 201 Created */ PermissionStatusResult;
export type PostApiPermissionsApiArg = {
  /** permission to create (id not required). */
  permission: Permission;
};
export type PutApiPermissionsByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiPermissionsByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type DeleteApiPermissionsByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiPermissionsByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type GetApiRecycleBinApiResponse =
  /** status 200 Success */ CategoryTreeStatusResult;
export type GetApiRecycleBinApiArg = void;
export type DeleteApiRecycleBinEmptyApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiRecycleBinEmptyApiArg = void;
export type DeleteApiRecycleBinDeleteApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiRecycleBinDeleteApiArg = {
  /** Ids of elements to delete (22 characters) */
  categoryTreeBatchOperationItems: CategoryTreeBatchOperationItems;
};
export type PutApiRecycleBinRestoreApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiRecycleBinRestoreApiArg = {
  /** Id of target category (22 characters) */
  targetCategoryId: string;
  /** Ids of elements to restore (22 characters) */
  categoryTreeBatchOperationItems: CategoryTreeBatchOperationItems;
};
export type GetApiRolesApiResponse =
  /** status 200 Success */ RoleIEnumerableStatusResult;
export type GetApiRolesApiArg = void;
export type PostApiRolesApiResponse =
  /** status 201 Created */ RoleStatusResult;
export type PostApiRolesApiArg = {
  /** Role to create (id not required). */
  role: Role;
};
export type GetApiRolesContextApiResponse =
  /** status 200 Success */ RoleIEnumerableStatusResult;
export type GetApiRolesContextApiArg = {
  /** Permission context to get roles for */
  context?: PermissionContext;
  /** id of user group to get information if permissions are assigned */
  userGroupId?: string;
};
export type GetApiRolesByIdApiResponse =
  /** status 200 Success */ RoleStatusResult;
export type GetApiRolesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiRolesByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiRolesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** Role to update (id required) */
  role: Role;
};
export type DeleteApiRolesByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiRolesByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type GetApiRolesRightsCategoriesByPermissionContextApiResponse =
  /** status 200 Success */ RightCategoryIEnumerableStatusResult;
export type GetApiRolesRightsCategoriesByPermissionContextApiArg = {
  /** Permission context (1,2,4) */
  permissionContext: PermissionContext;
};
export type GetApiRolesIsInUseByIdApiResponse =
  /** status 200 Success */ BooleanStatusResult;
export type GetApiRolesIsInUseByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiRolesRightsApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiRolesRightsApiArg = {
  /** id of role to change the rights for (by id, 22 characters). */
  roleId: string;
  /** ids to activate for role. all others should be deactivated (by id, 22 characters). */
  body: string[];
};
export type PutApiRolesRightsCategoriesApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiRolesRightsCategoriesApiArg = {
  /** Add or remove rights from right category to/from role (by id, 22 characters). */
  roleRightCategory: RoleRightCategory;
};
export type PostApiSearchArticlesApiResponse =
  /** status 200 Success */ SearchArticleResultStatusResult;
export type PostApiSearchArticlesApiArg = {
  searchAttributes: SearchAttributes;
};
export type PostApiSearchCategoriesApiResponse =
  /** status 200 Success */ SearchCategoryResultStatusResult;
export type PostApiSearchCategoriesApiArg = {
  searchAttributes: SearchAttributes;
};
export type GetApiSettingsContainersGlobalsApiResponse =
  /** status 200 Success */ SettingContainerIEnumerableStatusResult;
export type GetApiSettingsContainersGlobalsApiArg = {
  /** Global setting container type */
  type: SettingContainerType;
  /** Include setting and setting types */
  includeSettings?: boolean;
};
export type GetApiSettingsContainersUserGroupsApiResponse =
  /** status 200 Success */ UserGroupSettingContainersStatusResult;
export type GetApiSettingsContainersUserGroupsApiArg = {
  /** Id of user group */
  userGroupId: string;
};
export type PostApiSettingsContainersUserGroupsApiResponse =
  /** status 201 Created */ SettingContainerStatusResult;
export type PostApiSettingsContainersUserGroupsApiArg = {
  /** Id of user group to create setting container for. */
  userGroupId: string;
  /** Type of setting container to create */
  type?: SettingContainerType;
};
export type PutApiSettingsContainersUserGroupsApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiSettingsContainersUserGroupsApiArg = {
  /** Id of setting container to toggle activity (by id, 22 characters). */
  settingContainerId: string;
};
export type DeleteApiSettingsContainersUserGroupsByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiSettingsContainersUserGroupsByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type GetApiSettingsByIdApiResponse =
  /** status 200 Success */ SettingStatusResult;
export type GetApiSettingsByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type GetApiSettingsUserBySettingTypeKeyApiResponse =
  /** status 200 Success */ SettingStatusResult;
export type GetApiSettingsUserBySettingTypeKeyApiArg = {
  /** Key of setting type */
  settingTypeKey: string;
};
export type GetApiSettingsGlobalsApiResponse =
  /** status 200 Success */ SettingIEnumerableStatusResult;
export type GetApiSettingsGlobalsApiArg = void;
export type GetApiSettingsUserApiResponse =
  /** status 200 Success */ SettingIEnumerableStatusResult;
export type GetApiSettingsUserApiArg = void;
export type PutApiSettingsApiResponse = /** status 202 Accepted */ StatusResult;
export type PutApiSettingsApiArg = {
  body: {
    settingUpdates?: SettingUpdate[];
  };
};
export type GetApiSettingsPublicBySettingTypeKeyApiResponse =
  /** status 200 Success */ SettingStatusResult;
export type GetApiSettingsPublicBySettingTypeKeyApiArg = {
  /** Key of setting type */
  settingTypeKey: string;
};
export type PostApiSourceContentAreasApiResponse =
  /** status 201 Created */ SourceContentAreaStatusResult;
export type PostApiSourceContentAreasApiArg = {
  /** sourceContentArea to add */
  sourceContentArea: SourceContentArea;
};
export type PutApiSourceContentAreasByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiSourceContentAreasByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** SourceContentArea to update */
  sourceContentArea: SourceContentArea;
};
export type DeleteApiSourceContentAreasByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiSourceContentAreasByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiSourceContentAreasUpdateModifiedDatesApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiSourceContentAreasUpdateModifiedDatesApiArg = {
  /** Key Ids (22 characters) */
  body: string[];
};
export type PostApiSupportApiResponse = /** status 202 Accepted */ StatusResult;
export type PostApiSupportApiArg = {
  /** Message to report */
  emailMessage: EmailMessage;
};
export type GetApiUserGroupsApiResponse =
  /** status 200 Success */ UserGroupTreeStatusResult;
export type GetApiUserGroupsApiArg = {
  /** Indicates if users should be included in tree */
  includeUsers: boolean;
  /** Indicates if permitted actions should be included at userGroup */
  includePermittedActions: boolean;
};
export type PostApiUserGroupsApiResponse =
  /** status 201 Created */ UserGroupStatusResult;
export type PostApiUserGroupsApiArg = {
  /** UserGroup to create (id not required). Order parameter is ignored/not required. Set to next available order based on number of userGroups existing. */
  userGroup: UserGroup;
};
export type GetApiUserGroupsByIdApiResponse =
  /** status 200 Success */ UserGroupStatusResult;
export type GetApiUserGroupsByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiUserGroupsByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiUserGroupsByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** UserGroup to update (id required) */
  userGroup: UserGroup;
};
export type DeleteApiUserGroupsByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiUserGroupsByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type GetApiUserGroupsMoveCheckMoveByIdApiResponse =
  /** status 200 Success */ BooleanStatusResult;
export type GetApiUserGroupsMoveCheckMoveByIdApiArg = {
  /** UserGroupId (22 characters) */
  id: string;
  /** Id of target user group (22 characters) */
  targetUserGroupId: string;
};
export type PutApiUserGroupsMoveByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiUserGroupsMoveByIdApiArg = {
  /** UserGroupId (22 characters) */
  id: string;
  /** Id of target user group (22 characters) */
  targetUserGroupId: string;
};
export type GetApiUserGroupsAddUserCheckAddByIdApiResponse =
  /** status 200 Success */ BooleanStatusResult;
export type GetApiUserGroupsAddUserCheckAddByIdApiArg = {
  /** UserGroupId (22 characters) */
  id: string;
};
export type GetApiUsersByIdApiResponse =
  /** status 200 Success */ UserStatusResult;
export type GetApiUsersByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiUsersByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiUsersByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** User to update (id required) */
  user: User;
};
export type PostApiUsersApiResponse =
  /** status 201 Created */ UserStatusResult;
export type PostApiUsersApiArg = {
  /** User to create (id not required) */
  user: User;
};
export type DeleteApiUsersApiResponse = /** status 202 Accepted */ StatusResult;
export type DeleteApiUsersApiArg = {
  /** Key Ids of the users (22 characters) */
  body: string[];
};
export type PostApiUsersMoveCheckMoveApiResponse =
  /** status 200 Success */ BooleanStatusResult;
export type PostApiUsersMoveCheckMoveApiArg = {
  /** Id of target user group (22 characters) */
  targetUserGroupId: string;
  /** Ids of users to be moved (22 characters each) */
  body: string[];
};
export type PutApiUsersMoveApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiUsersMoveApiArg = {
  /** Id of target user group (22 characters) */
  targetUserGroupId: string;
  /** Ids of users to be moved (22 characters each) */
  body: string[];
};
export type PostApiUsersByIdConfirmEmailApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PostApiUsersByIdConfirmEmailApiArg = {
  /** User Id (22 characters) */
  id: string;
};
export type PostApiUsersActivationEmailApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PostApiUsersActivationEmailApiArg = {
  /** User Id (22 characters) */
  body: string[];
};
export type PostApiUsersByIdResetPasswordApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PostApiUsersByIdResetPasswordApiArg = {
  /** User Id (22 characters) */
  id: string;
};
export type PostApiUsersImportApiResponse = /** status 201 Created */
  | StatusResult
  | /** status 204 No Content */ StatusResult;
export type PostApiUsersImportApiArg = {
  /** Id of user group to add users */
  userGroupId: string;
  body: {
    importFile: Blob;
  };
};
export type PutApiUsersLockoutToggleApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiUsersLockoutToggleApiArg = {
  /** Key Id (22 characters) */
  userId: string;
};
export type GetApiUsersPublicKeyApiResponse =
  /** status 200 Success */ StringStatusResult;
export type GetApiUsersPublicKeyApiArg = void;
export type PostApiUsersValidPasswordApiResponse =
  /** status 200 Success */ BooleanStatusResult;
export type PostApiUsersValidPasswordApiArg = {
  /** User password encrypted in RSA with public key */
  password: Password;
};
export type GetApiVersionsByIdApiResponse =
  /** status 200 Success */ NamedVersionStatusResult;
export type GetApiVersionsByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type PutApiVersionsByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiVersionsByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** version to update (id required) */
  namedVersion: NamedVersion;
};
export type DeleteApiVersionsByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type DeleteApiVersionsByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** indicates of given version is draft */
  isDraft?: boolean;
};
export type GetApiVersionsArticleByIdApiResponse =
  /** status 200 Success */ NamedVersionIEnumerableStatusResult;
export type GetApiVersionsArticleByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** indicates if user is in editMode */
  editMode?: boolean;
};
export type PostApiVersionsApiResponse =
  /** status 201 Created */ VersionStatusResult;
export type PostApiVersionsApiArg = {
  /** The id of the version the new notes will be copied from */
  oldVersionId: string;
  /** Version to add */
  version: Version;
};
export type PostApiVersionsFileApiResponse =
  /** status 201 Created */ VersionStatusResult;
export type PostApiVersionsFileApiArg = {
  /** The id of the version the new notes will be copied from */
  oldVersionId: string;
  body: {
    VersionId?: string;
    ArticlePdf?: Blob;
    'Version.Name'?: string;
    'Version.Id'?: string;
    'Version.Type'?: ArticleType;
    'Version.ArticleId'?: string;
    'Version.Draft'?: boolean;
    'Version.Disabled'?: boolean;
    'Version.Title'?: string;
    'Version.ValidFrom'?: string;
    'Version.PublishedFrom'?: string;
    'Version.PublishedUntil'?: string;
    'Version.HtmlContent'?: string;
    'Version.PdfFileId'?: string;
    'Version.ModifiedDate'?: string;
    'Version.IsRecentVersion'?: boolean;
    'Version.LastModifiedBy'?: string;
  };
};
export type PutApiVersionsFileApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiVersionsFileApiArg = {
  body: {
    VersionId?: string;
    ArticlePdf?: Blob;
    'Version.Name'?: string;
    'Version.Id'?: string;
    'Version.Type'?: ArticleType;
    'Version.ArticleId'?: string;
    'Version.Draft'?: boolean;
    'Version.Disabled'?: boolean;
    'Version.Title'?: string;
    'Version.ValidFrom'?: string;
    'Version.PublishedFrom'?: string;
    'Version.PublishedUntil'?: string;
    'Version.HtmlContent'?: string;
    'Version.PdfFileId'?: string;
    'Version.ModifiedDate'?: string;
    'Version.IsRecentVersion'?: boolean;
    'Version.LastModifiedBy'?: string;
  };
};
export type PutApiVersionsConvertByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiVersionsConvertByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
  /** Id of version to replace */
  targetVersionId?: string;
  /** draft to convert (id required) */
  namedVersion: NamedVersion;
};
export type PutApiVersionsToggleDisableByIdApiResponse =
  /** status 202 Accepted */ StatusResult;
export type PutApiVersionsToggleDisableByIdApiArg = {
  /** Key Id (22 characters) */
  id: string;
};
export type HttpStatusCode =
  | 100
  | 101
  | 102
  | 103
  | 200
  | 201
  | 202
  | 203
  | 204
  | 205
  | 206
  | 207
  | 208
  | 226
  | 300
  | 301
  | 302
  | 303
  | 304
  | 305
  | 306
  | 307
  | 308
  | 400
  | 401
  | 402
  | 403
  | 404
  | 405
  | 406
  | 407
  | 408
  | 409
  | 410
  | 411
  | 412
  | 413
  | 414
  | 415
  | 416
  | 417
  | 421
  | 422
  | 423
  | 424
  | 426
  | 428
  | 429
  | 431
  | 451
  | 500
  | 501
  | 502
  | 503
  | 504
  | 505
  | 506
  | 507
  | 508
  | 510
  | 511;
export type SourceContentArea = {
  id?: string | null;
  startNodeData?: string | null;
  endNodeData?: string | null;
  patternString?: string | null;
  articleReferenceId?: string | null;
  modifiedDate?: string | null;
};
export type ReferenceBehaviour = 0 | 1 | 2;
export type ArticleReference = {
  id?: string | null;
  sourceArticleId?: string | null;
  sourceContentAreaId?: string | null;
  sourceContentArea?: SourceContentArea;
  hasContentLink?: boolean | null;
  targetArticleId?: string | null;
  targetCategoryId?: string | null;
  targetContentAreaId?: string | null;
  order?: number | null;
  originalName?: string | null;
  originalBackwardName?: string | null;
  name?: string | null;
  categoryAbbreviation?: string | null;
  backwardCategoryAbbreviation?: string | null;
  backwardName?: string | null;
  referenceBehaviour?: ReferenceBehaviour;
};
export type ArticleReferenceStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: ArticleReference;
};
export type StatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
};
export type RecentChangesItem = {
  articleId?: string | null;
  versionId?: string | null;
  versionTitle?: string | null;
  versionName?: string | null;
  lastModified?: string | null;
  validFrom?: string | null;
  iconCssClass?: string | null;
  iconColor?: string | null;
  categoryAbbreviation?: string | null;
};
export type RecentChangesItemIEnumerableStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: RecentChangesItem[] | null;
};
export type ArticleType = 0 | 1 | 2;
export type Article = {
  id?: string | null;
  categoryId?: string | null;
  order?: number;
  globalOrder?: number | null;
  type?: ArticleType;
  deleted?: boolean;
  disabled?: boolean;
  recentVersionId?: string | null;
  recentVersionTitle?: string | null;
  versionsDisabled?: boolean;
  versionsInvalid?: boolean;
  versionsOnlyDrafts?: boolean;
};
export type ArticleStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: Article;
};
export type NamedVersion = {
  id?: string | null;
  type?: ArticleType;
  articleId?: string | null;
  draft?: boolean;
  disabled?: boolean;
  title?: string | null;
  validFrom?: string;
  publishedFrom?: string | null;
  publishedUntil?: string | null;
  htmlContent?: string | null;
  pdfFileId?: string | null;
  modifiedDate?: string | null;
  isRecentVersion?: boolean | null;
  lastModifiedBy?: string | null;
  name?: string | null;
};
export type ArticleVersion = {
  article?: Article;
  version?: NamedVersion;
};
export type ArticleVersionStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: ArticleVersion;
};
export type Version = {
  id?: string | null;
  type?: ArticleType;
  articleId?: string | null;
  draft?: boolean;
  disabled?: boolean;
  title?: string | null;
  validFrom?: string;
  publishedFrom?: string | null;
  publishedUntil?: string | null;
  htmlContent?: string | null;
  pdfFileId?: string | null;
  modifiedDate?: string | null;
  isRecentVersion?: boolean | null;
  lastModifiedBy?: string | null;
};
export type CategoryType = {
  id?: string | null;
  name?: string | null;
  colorHexCode?: string | null;
  iconCssClass?: string | null;
  order?: number;
};
export type ArticleReferenceList = {
  categoryType?: CategoryType;
  articleReferences?: ArticleReference[] | null;
};
export type ExternalReference = {
  id?: string | null;
  articleId?: string | null;
  name?: string | null;
  hyperlink?: string | null;
};
export type ReferenceList = {
  articleReferenceLists?: ArticleReferenceList[] | null;
  externalReferences?: ExternalReference[] | null;
};
export type ReferenceListStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: ReferenceList;
};
export type Attachment = {
  id?: string | null;
  name?: string | null;
  visibleName?: string | null;
  mimeType?: string | null;
  fileId?: string | null;
  order?: number;
};
export type AttachmentIEnumerableStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: Attachment[] | null;
};
export type NamedAttachment = {
  id?: string | null;
  name?: string | null;
  visibleName?: string | null;
};
export type BookmarkFolder = {
  id?: string | null;
  parentId?: string | null;
  order?: number;
  name?: string | null;
  bookmarkIds?: string[] | null;
  bookmarkFolderIds?: string[] | null;
};
export type Bookmark = {
  id?: string | null;
  bookmarkFolderId?: string | null;
  articleId?: string | null;
  versionId?: string | null;
  order?: number;
  name?: string | null;
  markId?: string | null;
  iconCssClass?: string | null;
  iconColorHexCode?: string | null;
};
export type BookmarkTree = {
  bookmarkFolders?: BookmarkFolder[] | null;
  bookmarks?: Bookmark[] | null;
};
export type BookmarkTreeStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: BookmarkTree;
};
export type BookmarkFolderStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: BookmarkFolder;
};
export type BookmarkStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: Bookmark;
};
export type RightKey =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32
  | 33
  | 34
  | 35
  | 36
  | 37
  | 38
  | 39
  | 40
  | 41
  | 42
  | 43
  | 44
  | 45
  | 46
  | 47
  | 48
  | 49
  | 50
  | 51
  | 52
  | 53
  | 54
  | 55
  | 56
  | 57
  | 58
  | 59
  | 60
  | 61
  | 62
  | 63
  | 64
  | 65;
export type Category = {
  id?: string | null;
  parentId?: string | null;
  treePath?: string[] | null;
  name?: string | null;
  permittedPath?: string | null;
  order?: number;
  globalOrder?: number | null;
  abbreviation?: string | null;
  deleted?: boolean;
  disabled?: boolean;
  lastModified?: string | null;
  categoryTypeId?: string | null;
  categoryTypeIconClass?: string | null;
  categoryTypeColorHexCode?: string | null;
  articleIds?: string[] | null;
  categoryIds?: string[] | null;
  isRecursivePermitted?: boolean | null;
  permittedActions?: RightKey[] | null;
};
export type CategoryTree = {
  utcTime?: string;
  categories?: Category[] | null;
  articles?: Article[] | null;
};
export type CategoryTreeStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: CategoryTree;
};
export type CategoryStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: Category;
};
export type ElementType = 0 | 1 | 2;
export type PreviewElement = {
  id?: string;
  elementType?: ElementType;
  description?: string | null;
};
export type ImportPreview = {
  previewElements?: PreviewElement[] | null;
  errorElements?: string[] | null;
};
export type ImportPreviewStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: ImportPreview;
};
export type ArticleReferenceListIEnumerableStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: ArticleReferenceList[] | null;
};
export type BooleanStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: boolean;
};
export type CategoryTreeBatchOperationItems = {
  categoryIds?: string[] | null;
  articleIds?: string[] | null;
};
export type CategoryTypeIEnumerableStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: CategoryType[] | null;
};
export type CategoryTypeStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: CategoryType;
};
export type ExternalReferenceStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: ExternalReference;
};
export type Mark = {
  id?: string | null;
  startNodeData?: string | null;
  endNodeData?: string | null;
  patternString?: string | null;
  noteId?: string | null;
  modifiedDate?: string | null;
};
export type MarkStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: Mark;
};
export type NoteVisibilityType = 0 | 1 | 2;
export type Note = {
  id?: string | null;
  name?: string | null;
  text?: string | null;
  order?: number;
  versionId?: string | null;
  visibility?: NoteVisibilityType;
  mark?: Mark;
  lastModifiedBy?: string | null;
};
export type SortedNotes = {
  versionId?: string | null;
  generalNotes?: Note[] | null;
  userGroupNotes?: Note[] | null;
  userNotes?: Note[] | null;
};
export type SortedNotesStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: SortedNotes;
};
export type NoteStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: Note;
};
export type RightKeyIEnumerableStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: RightKey[] | null;
};
export type UserGroup = {
  id?: string | null;
  parentId?: string | null;
  name?: string | null;
  userGroupMemberMaximum?: number;
  userGroupTreeMemberMaximum?: number;
  userGroupMemberCount?: number;
  userGroupTreeMemberCount?: number;
  userIds?: string[] | null;
  userGroupIds?: string[] | null;
  isRecursivePermitted?: boolean | null;
  permittedActions?: RightKey[] | null;
  systemRolesAssigned?: boolean | null;
  categoryPermissionsAssigned?: boolean | null;
  userGroupPermissionsAssigned?: boolean | null;
};
export type Permission = {
  id?: string | null;
  userGroupId?: string | null;
  recursiveInheritanceEnabled?: boolean;
  roleId?: string | null;
  targetId?: string | null;
};
export type PermissionSet = {
  userGroupId?: string | null;
  roleId?: string | null;
  userGroups?: UserGroup[] | null;
  categories?: Category[] | null;
  permissions?: Permission[] | null;
};
export type PermissionSetStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: PermissionSet;
};
export type PermissionStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: Permission;
};
export type PermissionContext = 1 | 2 | 4;
export type Right = {
  id?: string | null;
  key?: RightKey;
  description?: string | null;
  order?: number;
  rightCategoryId?: string | null;
};
export type Role = {
  id?: string | null;
  name?: string | null;
  permissionContext?: PermissionContext;
  rights?: Right[] | null;
  usedInContext?: boolean | null;
};
export type RoleIEnumerableStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: Role[] | null;
};
export type RoleStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: Role;
};
export type RightCategory = {
  id?: string | null;
  key?: string | null;
  name?: string | null;
  permissionContext?: PermissionContext;
  rights?: Right[] | null;
};
export type RightCategoryIEnumerableStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: RightCategory[] | null;
};
export type RoleRightCategory = {
  roleId?: string | null;
  rightCategoryId?: string | null;
  add?: boolean;
};
export type SearchArticleResultItem = {
  articleId?: string | null;
  articleType?: ArticleType;
  versionId?: string | null;
  versionTitle?: string | null;
  versionName?: string | null;
  validFrom?: string | null;
  creationDate?: string | null;
  extract?: string | null;
  articleOrder?: number | null;
  attachmentId?: string | null;
  attachmentName?: string | null;
  foundInAttachment?: boolean | null;
  foundInArticleName?: boolean | null;
  foundInContent?: boolean | null;
};
export type SearchArticleResult = {
  resultCount?: number;
  foundTerms?: string[] | null;
  items?: SearchArticleResultItem[] | null;
};
export type SearchArticleResultStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: SearchArticleResult;
};
export type SearchResultSort = 0 | 1;
export type SearchAttributes = {
  searchString?: string | null;
  searchExact?: boolean;
  includeVersions?: boolean;
  includeAttachments?: boolean;
  selectedCategoryIds?: string[] | null;
  searchResultSort?: SearchResultSort;
  startOffset?: number;
  itemsPerPage?: number;
};
export type SearchCategoryResultItem = {
  categoryId?: string | null;
  categoryOrder?: number | null;
  articleIdsWithFoundTerms?: {
    [key: string]: string;
  } | null;
};
export type SearchCategoryResult = {
  resultCount?: number;
  foundTerms?: string[] | null;
  items?: SearchCategoryResultItem[] | null;
};
export type SearchCategoryResultStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: SearchCategoryResult;
};
export type SettingContainerType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export type DataType = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type SettingType = {
  id?: string | null;
  key?: string | null;
  name?: string | null;
  description?: string | null;
  dataType?: DataType;
};
export type Setting = {
  id?: string | null;
  value?: string | null;
  settingType?: SettingType;
  settingContainerId?: string | null;
};
export type SettingContainer = {
  id?: string | null;
  name?: string | null;
  isActive?: boolean;
  settingContainerType?: SettingContainerType;
  settings?: Setting[] | null;
  userGroupId?: string | null;
};
export type SettingContainerIEnumerableStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: SettingContainer[] | null;
};
export type UserGroupSettingContainers = {
  allocatedContainers?: SettingContainer[] | null;
  nonAllocatedContainers?: SettingContainer[] | null;
};
export type UserGroupSettingContainersStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: UserGroupSettingContainers;
};
export type SettingContainerStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: SettingContainer;
};
export type SettingStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: Setting;
};
export type SettingIEnumerableStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: Setting[] | null;
};
export type SettingUpdate = {
  id?: string | null;
  value?: string | null;
  file?: Blob | null;
};
export type SourceContentAreaStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: SourceContentArea;
};
export type EmailMessage = {
  message?: string | null;
};
export type User = {
  id?: string | null;
  userGroupId?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  email?: string | null;
  disabled?: boolean;
  emailSentDate?: string | null;
  emailConfirmed?: boolean;
  loggedIn?: boolean;
  timedOut?: boolean;
  isSuperAdmin?: boolean;
  isLockedOut?: boolean;
};
export type UserGroupTree = {
  userGroups?: UserGroup[] | null;
  users?: User[] | null;
};
export type UserGroupTreeStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: UserGroupTree;
};
export type UserGroupStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: UserGroup;
};
export type UserStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: User;
};
export type StringStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: string | null;
};
export type Password = {
  encryptedPassword?: string | null;
};
export type NamedVersionStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: NamedVersion;
};
export type NamedVersionIEnumerableStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: NamedVersion[] | null;
};
export type VersionStatusResult = {
  statusCode?: HttpStatusCode;
  messageKey?: string | null;
  resultObject?: Version;
};
export const {
  useGetApiArticleReferencesByIdQuery,
  usePutApiArticleReferencesByIdMutation,
  useDeleteApiArticleReferencesByIdMutation,
  usePostApiArticleReferencesMutation,
  useGetApiArticlesRecentChangesQuery,
  useGetApiArticlesByIdQuery,
  usePutApiArticlesByIdMutation,
  usePostApiArticlesMutation,
  usePostApiArticlesStructureMutation,
  usePostApiArticlesVersionMutation,
  usePostApiArticlesVersionUploadMutation,
  usePutApiArticlesMoveMutation,
  usePostApiArticlesExportByIdMutation,
  useGetApiArticlesReferencesByIdQuery,
  usePutApiArticlesRecycleMutation,
  usePutApiArticlesSetDisabledStateMutation,
  useGetApiAttachmentsByVersionIdQuery,
  usePostApiAttachmentsMutation,
  usePutApiAttachmentsByIdMutation,
  useDeleteApiAttachmentsByIdMutation,
  usePutApiAttachmentsMoveByIdMutation,
  useGetApiBookmarkFoldersQuery,
  usePostApiBookmarkFoldersMutation,
  useGetApiBookmarkFoldersByIdQuery,
  usePutApiBookmarkFoldersByIdMutation,
  useDeleteApiBookmarkFoldersByIdMutation,
  usePutApiBookmarkFoldersMoveByIdMutation,
  useGetApiBookmarksByIdQuery,
  usePutApiBookmarksByIdMutation,
  useDeleteApiBookmarksByIdMutation,
  usePostApiBookmarksMutation,
  usePutApiBookmarksMoveByIdMutation,
  useGetApiCategoriesOverviewByIdQuery,
  useGetApiCategoriesByIdQuery,
  usePutApiCategoriesByIdMutation,
  usePostApiCategoriesMutation,
  usePutApiCategoriesSetPublishDatesMutation,
  usePutApiCategoriesSetValidFromMutation,
  usePostApiCategoriesImportMutation,
  usePostApiCategoriesExportByIdMutation,
  useGetApiCategoriesArticleReferencesByIdQuery,
  useGetApiCategoryTreeCheckForChangeQuery,
  useGetApiCategoryTreeQuery,
  usePutApiCategoryTreeSetDisabledStateMutation,
  usePutApiCategoryTreeMoveMutation,
  usePutApiCategoryTreeRecycleMutation,
  useGetApiCategoryTypesQuery,
  usePostApiCategoryTypesMutation,
  useGetApiCategoryTypesByIdQuery,
  usePutApiCategoryTypesByIdMutation,
  useDeleteApiCategoryTypesByIdMutation,
  useGetApiCategoryTypesIsInUseByIdQuery,
  useGetApiExternalReferencesByIdQuery,
  usePutApiExternalReferencesByIdMutation,
  useDeleteApiExternalReferencesByIdMutation,
  usePostApiExternalReferencesMutation,
  usePostApiFilesByIdMutation,
  usePostApiFilesVersionByIdMutation,
  usePostApiMarksMutation,
  usePutApiMarksByIdMutation,
  useDeleteApiMarksByIdMutation,
  usePutApiMarksUpdateModifiedDatesMutation,
  useGetApiNotesByVersionIdQuery,
  usePostApiNotesMutation,
  usePutApiNotesByIdMutation,
  useDeleteApiNotesByIdMutation,
  usePutApiNotesMoveByIdMutation,
  useGetApiPermissionsUserQuery,
  useGetApiPermissionsQuery,
  usePostApiPermissionsMutation,
  usePutApiPermissionsByIdMutation,
  useDeleteApiPermissionsByIdMutation,
  useGetApiRecycleBinQuery,
  useDeleteApiRecycleBinEmptyMutation,
  useDeleteApiRecycleBinDeleteMutation,
  usePutApiRecycleBinRestoreMutation,
  useGetApiRolesQuery,
  usePostApiRolesMutation,
  useGetApiRolesContextQuery,
  useGetApiRolesByIdQuery,
  usePutApiRolesByIdMutation,
  useDeleteApiRolesByIdMutation,
  useGetApiRolesRightsCategoriesByPermissionContextQuery,
  useGetApiRolesIsInUseByIdQuery,
  usePutApiRolesRightsMutation,
  usePutApiRolesRightsCategoriesMutation,
  usePostApiSearchArticlesMutation,
  usePostApiSearchCategoriesMutation,
  useGetApiSettingsContainersGlobalsQuery,
  useGetApiSettingsContainersUserGroupsQuery,
  usePostApiSettingsContainersUserGroupsMutation,
  usePutApiSettingsContainersUserGroupsMutation,
  useDeleteApiSettingsContainersUserGroupsByIdMutation,
  useGetApiSettingsByIdQuery,
  useGetApiSettingsUserBySettingTypeKeyQuery,
  useGetApiSettingsGlobalsQuery,
  useGetApiSettingsUserQuery,
  usePutApiSettingsMutation,
  useGetApiSettingsPublicBySettingTypeKeyQuery,
  usePostApiSourceContentAreasMutation,
  usePutApiSourceContentAreasByIdMutation,
  useDeleteApiSourceContentAreasByIdMutation,
  usePutApiSourceContentAreasUpdateModifiedDatesMutation,
  usePostApiSupportMutation,
  useGetApiUserGroupsQuery,
  usePostApiUserGroupsMutation,
  useGetApiUserGroupsByIdQuery,
  usePutApiUserGroupsByIdMutation,
  useDeleteApiUserGroupsByIdMutation,
  useGetApiUserGroupsMoveCheckMoveByIdQuery,
  usePutApiUserGroupsMoveByIdMutation,
  useGetApiUserGroupsAddUserCheckAddByIdQuery,
  useGetApiUsersByIdQuery,
  usePutApiUsersByIdMutation,
  usePostApiUsersMutation,
  useDeleteApiUsersMutation,
  usePostApiUsersMoveCheckMoveMutation,
  usePutApiUsersMoveMutation,
  usePostApiUsersByIdConfirmEmailMutation,
  usePostApiUsersActivationEmailMutation,
  usePostApiUsersByIdResetPasswordMutation,
  usePostApiUsersImportMutation,
  usePutApiUsersLockoutToggleMutation,
  useGetApiUsersPublicKeyQuery,
  usePostApiUsersValidPasswordMutation,
  useGetApiVersionsByIdQuery,
  usePutApiVersionsByIdMutation,
  useDeleteApiVersionsByIdMutation,
  useGetApiVersionsArticleByIdQuery,
  usePostApiVersionsMutation,
  usePostApiVersionsFileMutation,
  usePutApiVersionsFileMutation,
  usePutApiVersionsConvertByIdMutation,
  usePutApiVersionsToggleDisableByIdMutation,
} = injectedRtkApi;
