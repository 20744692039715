import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  CategoryType,
  useGetApiCategoryTypesQuery,
  usePutApiCategoryTypesByIdMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { useAppDispatch } from '../../../redux/hooks';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

interface IRenameCategoryTypeDialogProps {
  show: boolean;
  categoryType: CategoryType;
  setActiveCategoryType: (categoryType: CategoryType) => void;
  onClose: () => void;
}

function RenameCategoryTypeDialog({
  show,
  categoryType,
  setActiveCategoryType,
  onClose,
}: IRenameCategoryTypeDialogProps) {
  const { t: translation } = useTranslation();
  const [categoryTypeName, setCategoryTypeName] = useState('');
  const categoryTypeNameInputRef = useRef<HTMLInputElement>(null);
  const [updateCategoryType, { isSuccess, isError, isLoading, error }] =
    usePutApiCategoryTypesByIdMutation();
  const { refetch } = useGetApiCategoryTypesQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (show) {
      setCategoryTypeName(categoryType.name || '');
      categoryTypeNameInputRef.current?.focus();
    }
  }, [show]);

  useEffect(() => {
    if (isSuccess) {
      setActiveCategoryType(categoryType);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'RenameCategoryTypeError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleUpdateCategoryType = (): void => {
    const categoryTypeToUpdate: CategoryType = {
      id: categoryType.id,
      name: categoryTypeName.trim(),
      colorHexCode: categoryType.colorHexCode,
      iconCssClass: categoryType.iconCssClass,
      order: categoryType.order,
    };
    updateCategoryType({
      id: categoryTypeToUpdate.id || '',
      categoryType: categoryTypeToUpdate,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'RenameCategoryTypeSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        onClose();
      });
  };

  return (
    <CustomDialog
      titleId='RenameCategoryTypeDialog'
      show={show}
      closeFunction={onClose}
      closeTitle={translation('cancel')}
      actionFunction={handleUpdateCategoryType}
      actionTitle={translation('rename')}
      actionButtonDisabled={
        categoryType.name === categoryTypeName.trim() ||
        categoryTypeName.trim() === '' ||
        isLoading
      }
      dialogTitle={translation('renameCategoryType')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Form
            onSubmit={(e) => {
              if (
                categoryType.name === categoryTypeName.trim() ||
                categoryTypeName.trim() === ''
              ) {
                e.preventDefault();
                return;
              }
              handleUpdateCategoryType();
            }}>
            <Form.Group className='mb-3' controlId='CategoryTypeName'>
              <Form.Label>{translation('categoryTypeName')}*</Form.Label>
              <Form.Control
                ref={categoryTypeNameInputRef}
                required
                onChange={(e) => {
                  setCategoryTypeName(e.target.value);
                }}
                type='text'
                value={categoryTypeName}
              />
            </Form.Group>
          </Form>
          <p>{translation('fieldsAreRequiredLegend')}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default RenameCategoryTypeDialog;
