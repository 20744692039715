import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { decode } from 'html-entities';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  useGetApiVersionsArticleByIdQuery,
  usePostApiVersionsMutation,
  usePutApiVersionsConvertByIdMutation,
  usePutApiVersionsByIdMutation,
  useGetApiCategoryTreeQuery,
  useGetApiSettingsUserQuery,
} from '../../redux/store/api/api';
import {
  selectActiveVersion,
  selectCurrentVersions,
  setMaxNumberOfVersionsDialogOpened,
} from '../../redux/store/content/slice';
import { addMessage } from '../../redux/store/layout/slice';
import { ArticleType } from '../../shared/enums';
import { formatDateString } from '../../shared/utils';
import CustomCard from '../cards/CustomCard';
import Loader from '../loader/Loader';
import VersionForm from '../version/VersionForm';
import VersionSelect from '../version/VersionSelect';
import ValidityDateIsNotChangedDialog from '../version/dialogs/ValidityDateIsNotChangedDialog';
import {
  EDIT_MODE_KEY,
  settingTypeMaxVersionCount,
} from '../../shared/constants';
import DraftToVersionDialog from './dialogs/DraftToVersionDialog';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import { getArticleUrl, getEditDraftUrl } from '../../shared/urlBuilder';
import MaxNumberOfVersionsDialog from '../version/dialogs/MaxNumberOfVersionsDialog';

function EditDraftForm(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const versions = useAppSelector(selectCurrentVersions);
  const activeVersion = useAppSelector(selectActiveVersion);
  const [draftSaveDialogOpened, setDraftSaveDialogOpened] = useState(false);
  const [
    validityDateNotChangedDialogOpened,
    setValidityDateNotChangedDialogOpened,
  ] = useState(false);
  const [versionContent, setVersionContent] = useState(
    decode(activeVersion.htmlContent),
  );
  const [versionTitle, setVersionTitle] = useState(decode(activeVersion.title));
  const [validityDate, setValidityDate] = useState(
    activeVersion.validFrom ? formatDateString(activeVersion.validFrom) : '',
  );
  const [publicationDateStart, setPublicationDateStart] = useState(
    activeVersion.publishedFrom
      ? formatDateString(activeVersion.publishedFrom)
      : '',
  );
  const [publicationDateEnd, setPublicationDateEnd] = useState(
    activeVersion.publishedUntil
      ? formatDateString(activeVersion.publishedUntil)
      : '',
  );
  const { refetch: refetchCategoryTree } = useGetApiCategoryTreeQuery();
  const defaultSaveAsValue = '0';
  const [saveAsValue, setSaveAsValue] = useState(defaultSaveAsValue);
  const [inputsAreValid, setInputsAreValid] = useState(true);
  const [
    addDraft,
    {
      isError: addDraftIsError,
      isLoading: addDraftIsLoading,
      error: addDraftError,
    },
  ] = usePostApiVersionsMutation();
  const [
    convertToVersion,
    {
      isError: convertToVersionIsError,
      isLoading: convertToVersionIsLoading,
      error: convertToVersionError,
    },
  ] = usePutApiVersionsConvertByIdMutation();
  const [
    updateDraft,
    {
      isError: updateDraftIsError,
      isLoading: updateDraftIsLoading,
      error: updateDraftError,
    },
  ] = usePutApiVersionsByIdMutation();
  const [editModeIsActive] = useSessionStorage<boolean>(EDIT_MODE_KEY, false);
  const { refetch } = useGetApiVersionsArticleByIdQuery(
    activeVersion.articleId
      ? { id: activeVersion.articleId, editMode: editModeIsActive }
      : skipToken,
  );
  const valuesNotChanged =
    decode(activeVersion.htmlContent) === versionContent &&
    decode(activeVersion.title) === versionTitle.trim() &&
    formatDateString(activeVersion.validFrom || '') ===
      formatDateString(validityDate) &&
    formatDateString(activeVersion.publishedFrom || '') ===
      formatDateString(publicationDateStart) &&
    formatDateString(activeVersion.publishedUntil || '') ===
      formatDateString(publicationDateEnd);
  const saveDraftButtonDisabled =
    validityDate === '' ||
    versionTitle.trim() === '' ||
    valuesNotChanged ||
    !inputsAreValid;

  const { data: settings } = useGetApiSettingsUserQuery();
  const versionMaxSetting =
    Number(
      settings?.resultObject?.find(
        (s) => s.settingType?.key === settingTypeMaxVersionCount,
      )?.value,
    ) || undefined;

  useEffect(() => {
    setVersionContent(decode(activeVersion.htmlContent));
    setVersionTitle(decode(activeVersion.title));
    setValidityDate(
      activeVersion.validFrom ? formatDateString(activeVersion.validFrom) : '',
    );
    setPublicationDateStart(
      activeVersion.publishedFrom
        ? formatDateString(activeVersion.publishedFrom)
        : '',
    );
    setPublicationDateEnd(
      activeVersion.publishedUntil
        ? formatDateString(activeVersion.publishedUntil)
        : '',
    );
  }, [activeVersion]);

  useEffect(() => {
    if (addDraftIsError) {
      dispatch(
        addMessage({
          id: 'AddDraftError',
          variant: 'danger',
          messageKeyBody:
            addDraftError && 'data' in addDraftError
              ? addDraftError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (convertToVersionIsError) {
      dispatch(
        addMessage({
          id: 'ConvertToVersionError',
          variant: 'danger',
          messageKeyBody:
            convertToVersionError && 'data' in convertToVersionError
              ? convertToVersionError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (updateDraftIsError) {
      dispatch(
        addMessage({
          id: 'UpdateVersionError',
          variant: 'danger',
          messageKeyBody:
            updateDraftError && 'data' in updateDraftError
              ? updateDraftError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [addDraftIsError, convertToVersionIsError, updateDraftIsError]);

  const handleAddDraft = () => {
    addDraft({
      version: {
        type: ArticleType.Html,
        articleId: activeVersion.articleId || '',
        disabled: activeVersion.disabled,
        draft: true,
        title: versionTitle,
        validFrom: validityDate,
        publishedFrom:
          publicationDateStart === '' ? undefined : publicationDateStart,
        publishedUntil:
          publicationDateEnd === '' ? undefined : publicationDateEnd,
        htmlContent: versionContent,
      },
      oldVersionId: activeVersion.id || '',
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'AddDraftSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        navigate(
          getEditDraftUrl(
            activeVersion.articleId || '',
            result?.resultObject?.id || '',
          ),
          {
            replace: true,
          },
        );
      });
  };

  const handleEditDraft = () => {
    updateDraft({
      id: activeVersion.id || '',
      namedVersion: {
        type: ArticleType.Html,
        articleId: activeVersion.articleId || '',
        disabled: activeVersion.disabled,
        draft: true,
        title: versionTitle,
        validFrom: validityDate,
        publishedFrom:
          publicationDateStart === '' ? undefined : publicationDateStart,
        publishedUntil:
          publicationDateEnd === '' ? undefined : publicationDateEnd,
        htmlContent: versionContent,
        name: activeVersion.name,
        id: activeVersion.id || '',
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateVersionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        navigate(
          getEditDraftUrl(
            activeVersion.articleId || '',
            activeVersion.id || '',
          ),
          {
            replace: true,
          },
        );
      });
  };

  const handleConvertToVersion = (targetVersionId: string) => {
    convertToVersion({
      id: activeVersion.id || '',
      namedVersion: {
        type: ArticleType.Html,
        articleId: activeVersion.articleId || '',
        disabled: activeVersion.disabled,
        draft: false,
        title: versionTitle,
        validFrom: validityDate,
        publishedFrom:
          publicationDateStart === '' ? undefined : publicationDateStart,
        publishedUntil:
          publicationDateEnd === '' ? undefined : publicationDateEnd,
        htmlContent: versionContent,
        name: activeVersion.name,
        id: activeVersion.id,
      },
      targetVersionId,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'ConvertToVersionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        refetchCategoryTree();
        navigate(getArticleUrl(activeVersion.articleId || '', saveAsValue), {
          replace: true,
        });
      });
  };

  const category = useGetCategoryByArticleId(activeVersion.articleId);
  const isLoading =
    addDraftIsLoading || convertToVersionIsLoading || updateDraftIsLoading;

  return (
    <div className='article-content'>
      <CustomCard
        focusableHeadline
        headlineAsH1
        iconClass={category?.categoryTypeIconClass || ''}
        title={translation('editDraft')}
        customCardStyle={{ flex: 1 }}>
        <div aria-busy={isLoading}>
          {isLoading && <Loader />}
          {!isLoading && (
            <>
              <VersionForm
                setInputsAreValid={setInputsAreValid}
                versionContent={versionContent}
                setVersionContent={setVersionContent}
                versionTitle={versionTitle}
                setVersionTitle={setVersionTitle}
                validityDate={validityDate}
                setValidityDate={setValidityDate}
                publicationStart={publicationDateStart}
                setPublicationStart={setPublicationDateStart}
                publicationEnd={publicationDateEnd}
                setPublicationEnd={setPublicationDateEnd}
              />
              <VersionSelect
                defaultSaveAsValue={defaultSaveAsValue}
                saveAsValue={saveAsValue}
                setSaveAsValue={setSaveAsValue}
              />
              <p>{translation('fieldsAreRequiredLegend')}</p>
              <div className='text-end'>
                <Button
                  className='me-1 mt-1'
                  variant='outline-dark'
                  onClick={() => handleAddDraft()}>
                  {translation('saveAsNewDraft')}
                </Button>
                <Button
                  className='me-1 mt-1'
                  variant='outline-dark'
                  disabled={saveDraftButtonDisabled}
                  onClick={() => handleEditDraft()}>
                  {translation('saveDraft')}
                </Button>
                <Button
                  className='me-1 mt-1'
                  variant='outline-success'
                  disabled={!inputsAreValid}
                  onClick={() => {
                    if (
                      saveAsValue === defaultSaveAsValue &&
                      new Date(
                        formatDateString(activeVersion.validFrom || ''),
                      ).getTime() >= new Date(validityDate).getTime()
                    ) {
                      setValidityDateNotChangedDialogOpened(true);
                      return;
                    }

                    if (
                      versionMaxSetting &&
                      versions.length >= versionMaxSetting
                    ) {
                      dispatch(setMaxNumberOfVersionsDialogOpened(true));
                      return;
                    }

                    setDraftSaveDialogOpened(true);
                  }}>
                  {translation('saveVersion')}
                </Button>
              </div>
            </>
          )}
        </div>
      </CustomCard>
      <ValidityDateIsNotChangedDialog
        dialogShow={validityDateNotChangedDialogOpened}
        setDialogShow={setValidityDateNotChangedDialogOpened}
      />
      <DraftToVersionDialog
        dialogShow={draftSaveDialogOpened}
        setDialogShow={setDraftSaveDialogOpened}
        handleConvertToVersion={handleConvertToVersion}
        targetVersionId={saveAsValue === defaultSaveAsValue ? '' : saveAsValue}
      />
      <MaxNumberOfVersionsDialog />
    </div>
  );
}

export default EditDraftForm;
