import { Note } from '../../redux/store/api/api';
import { VisibilityGroupString } from './types';

type NoteDataProperty = 'userNotes' | 'userGroupNotes' | 'generalNotes';

export const getSortedNotesPropertyByVisibilityGroup = (
  visibilityGroup: VisibilityGroupString | null,
): NoteDataProperty | undefined => {
  if (!visibilityGroup) {
    return undefined;
  }

  const firstLetterUpperCase = visibilityGroup[0];
  const firstLetterLowerCase = firstLetterUpperCase.toLocaleLowerCase();
  return `${visibilityGroup.replace(
    firstLetterUpperCase,
    firstLetterLowerCase,
  )}Notes` as NoteDataProperty;
};

export const scrollMarkIntoView = (note: Note) => {
  if (!note.mark) {
    return;
  }

  const markEl: HTMLElement | null = document.getElementById(
    `${note.mark.id}-1/1`,
  );
  if (markEl) {
    markEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};
