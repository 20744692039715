import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiAttachmentsByVersionIdQuery,
  usePutApiAttachmentsByIdMutation,
} from '../../../redux/store/api/api';
import {
  selectActiveVersion,
  selectContextAttachment,
  selectRenameAttachmentDialogOpened,
  setRenameAttachmentDialogOpened,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

function RenameAttachmentDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const dialogShow = useAppSelector(selectRenameAttachmentDialogOpened);
  const attachment = useAppSelector(selectContextAttachment);
  const version = useAppSelector(selectActiveVersion);
  const [attachmentName, setAttachmentName] = useState(
    attachment.visibleName || attachment.name || '',
  );
  const [updateAttachment, { isError, isLoading, error }] =
    usePutApiAttachmentsByIdMutation();
  const { refetch } = useGetApiAttachmentsByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'UpdateAttachmentError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  useEffect(() => {
    setAttachmentName(attachment.visibleName || attachment.name || '');
  }, [attachment]);

  const handleUpdateAttachment = () => {
    updateAttachment({
      id: attachment.id || '',
      namedAttachment: {
        id: attachment.id,
        visibleName: attachmentName.trim(),
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateAttachmentSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(setRenameAttachmentDialogOpened(false));
      });
  };

  return (
    <CustomDialog
      titleId='RenameAttachmentDialog'
      show={dialogShow}
      closeFunction={() => {
        dispatch(setRenameAttachmentDialogOpened(false));
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleUpdateAttachment}
      actionTitle={translation('save')}
      actionButtonDisabled={
        attachmentName === '' ||
        isLoading ||
        attachment.visibleName === attachmentName.trim() ||
        (!attachment.visibleName && attachment.name === attachmentName.trim())
      }
      dialogTitle={translation('renameAttachment')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Form.Group className='mb-3' controlId='AttachmentName'>
            <Form.Label>{translation('name')}*</Form.Label>
            <InputGroup>
              <Form.Control
                required
                onChange={(e) => {
                  setAttachmentName(e.target.value);
                }}
                type='text'
                value={attachmentName}
              />
              <Button
                aria-label={translation('retoreOriginalName')}
                title={translation('retoreOriginalName')}
                variant='outline-dark'
                className='rounded-circle circle-btn'
                onClick={() => {
                  setAttachmentName(attachment.name || '');
                }}>
                <i aria-hidden className='icon-wiederherstellen' />
              </Button>
            </InputGroup>
          </Form.Group>

          <p>{translation('fieldsAreRequiredLegend')}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default RenameAttachmentDialog;
