import { useEffect } from 'react';
import CreateArticleVersionForm from '../components/article/CreateArticleVersionForm';

function CreateArticle(): JSX.Element {
  useEffect(() => {
    document.getElementsByTagName('h1')[0].focus();
  }, []);

  return (
    <div className='content-inner'>
      <CreateArticleVersionForm />
    </div>
  );
}

export default CreateArticle;
