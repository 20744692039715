import { useEffect, useMemo } from 'react';
import TreeView from 'react-accessible-treeview';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/hooks';
import { useGetApiCategoryTreeQuery } from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import { getTreeItems } from '../content-tree/functions';
import {
  ContentTreeItems,
  IArticleTreeItem,
  ICategoryTreeItem,
} from '../content-tree/types';
import Loader from '../loader/Loader';
import { ReferenceBehaviourString } from './types';
import ReferenceTreeItem from './reference-tree/ReferenceTreeItem';

interface IMultipleInternalReferenceFormProps {
  setSelectedReferences: (
    elements: (IArticleTreeItem | ICategoryTreeItem)[] | null,
  ) => void;
  selectedReferences: (IArticleTreeItem | ICategoryTreeItem)[] | null;
  referenceBehaviour: ReferenceBehaviourString;
  setReferenceBehaviour: (referenceBehaviour: ReferenceBehaviourString) => void;
  getReferenceBehaviourKeys: () => ReferenceBehaviourString[];
  hasSourceContentArea: boolean;
}

function MultipleInternalReferenceForm({
  setSelectedReferences,
  selectedReferences,
  referenceBehaviour,
  setReferenceBehaviour,
  getReferenceBehaviourKeys,
  hasSourceContentArea,
}: IMultipleInternalReferenceFormProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const {
    data: contentTreeData,
    isFetching,
    isError,
    error,
  } = useGetApiCategoryTreeQuery();

  const treeData = useMemo<ContentTreeItems>(() => {
    if (contentTreeData?.resultObject) {
      return getTreeItems(contentTreeData.resultObject);
    }
    return [];
  }, [contentTreeData]);
  const allowDisabling = !hasSourceContentArea;

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetTreeError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <div className='mt-3'>
      <p aria-labelledby='MultiReferenceTree' className='fw-bold'>
        {translation('selectArticlesAndCategoriesToBeLinked')}
      </p>
      <div
        className='max-350 border-top border-bottom pt-1 ps-1 pb-1'
        aria-busy={isFetching}>
        {isFetching && <Loader />}
        {contentTreeData && !isFetching && treeData.length > 0 && (
          <TreeView
            id='MultiReferenceTree'
            data={treeData}
            multiSelect
            togglableSelect
            selectedIds={selectedReferences?.map((s) => s.id) || []}
            onSelect={(e) => {
              const element = e.element as IArticleTreeItem | ICategoryTreeItem;
              let references: (IArticleTreeItem | ICategoryTreeItem)[] =
                selectedReferences || [];

              if (e.isSelected) {
                references?.push(element);
                setSelectedReferences(references || []);
              } else {
                references = references.filter((r) => r.id !== element.id);
                setSelectedReferences(
                  references.length === 0 ? null : references || [],
                );
              }
            }}
            nodeRenderer={({
              element,
              getNodeProps,
              level,
              isBranch,
              isSelected,
              isExpanded,
              handleSelect,
              handleExpand,
            }) =>
              ReferenceTreeItem({
                isExpanded: isExpanded || false,
                element: element as IArticleTreeItem | ICategoryTreeItem,
                isBranch,
                isSelected,
                level,
                getNodeProps,
                handleSelect,
                handleExpand,
                allowDisabling,
              })
            }
          />
        )}
      </div>
      {selectedReferences && (
        <>
          <p aria-labelledby='ReferenceBehaviour' className='mb-1 mt-3 fw-bold'>
            {translation('chooseTypeOfReference')}
          </p>
          {getReferenceBehaviourKeys().map((key) => (
            <Form.Check
              id={`${key}Radio`}
              key={key}
              value={key}
              onChange={(e) => {
                if (e.target.checked) {
                  setReferenceBehaviour(
                    e.target.value as ReferenceBehaviourString,
                  );
                }
              }}
              checked={key === referenceBehaviour}
              type='radio'
              name='ReferenceBehaviour'
              label={translation(`referenceBehaviour${key}`)}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default MultipleInternalReferenceForm;
