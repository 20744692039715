import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import { useGetApiSettingsUserQuery } from '../../../redux/store/api/api';
import { settingTypeManualContent } from '../../../shared/constants';

interface IUserGuideDialogProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

function UserGuideDialog({
  show,
  setShow,
}: IUserGuideDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const { data: settings, isFetching } = useGetApiSettingsUserQuery();
  const manualSetting = settings?.resultObject?.find(
    (s) => s.settingType?.key === settingTypeManualContent,
  );

  return (
    <CustomDialog
      wideDialog
      titleId='UserGuideDialog'
      dialogTitle={translation('userGuide')}
      show={show}
      closeFunction={() => {
        setShow(false);
      }}
      closeTitle={translation('close')}>
      {isFetching && <Loader />}
      {!isFetching && manualSetting?.value && (
        <>{parse(manualSetting.value || '')}</>
      )}
    </CustomDialog>
  );
}

export default UserGuideDialog;
