/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../shared/types';
import { LayoutState, Message } from './types';

const initialState: LayoutState = {
  sidebarOpened: false,
  messages: [],
};

export const layoutSlice = createSlice({
  initialState,
  name: 'layout',
  reducers: {
    setSidebarOpened: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpened = action.payload;
    },
    addMessage: (state, action: PayloadAction<Message>) => {
      if (action.payload.messageKeyBody) {
        state.messages.push(action.payload);
      }
    },
    removeMessage: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.filter((m) => m.id !== action.payload);
    },
  },
});

export const { setSidebarOpened, addMessage, removeMessage } =
  layoutSlice.actions;

export const selectSidebarOpened = (state: RootState) =>
  state.layout.sidebarOpened;

export const selectMessages = (state: RootState) => state.layout.messages;

export default layoutSlice.reducer;
