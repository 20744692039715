import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomCard from '../components/cards/CustomCard';
import PrivacyText from '../components/infos/PrivacyText';
import { useGetApiSettingsPublicBySettingTypeKeyQuery } from '../redux/store/api/api';
import { settingTypeApplicationName } from '../shared/constants';
import { addMessage } from '../redux/store/layout/slice';
import { useAppDispatch } from '../redux/hooks';

function Privacy(): JSX.Element {
  const { t: translation } = useTranslation();

  const {
    data: applicationNameSetting,
    isError,
    error,
  } = useGetApiSettingsPublicBySettingTypeKeyQuery({
    settingTypeKey: settingTypeApplicationName,
  });

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetSettingError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  useEffect(() => {
    document.title = `${
      applicationNameSetting?.resultObject?.value
        ? `${applicationNameSetting.resultObject.value} - `
        : ''
    }${translation('privacy')}`;
  }, [applicationNameSetting]);

  useEffect(() => {
    document.getElementsByTagName('h1')[0].focus();
  }, []);

  return (
    <CustomCard
      title={translation('privacy')}
      cardId='PrivacyCard'
      focusableHeadline
      headlineAsH1>
      <PrivacyText />
    </CustomCard>
  );
}

export default Privacy;
