import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiCategoryTreeQuery,
  useGetApiCategoryTypesQuery,
  useGetApiPermissionsUserQuery,
  usePostApiCategoriesMutation,
} from '../../../redux/store/api/api';
import {
  selectAddCategoryDialogOpened,
  selectContextCategory,
  setAddCategoryDialogOpened,
  setContextCategory,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  EXPANDED_CATEGORY_IDS,
  permissionCacheDelayInSeconds,
} from '../../../shared/constants';
import { RightKey } from '../../../shared/enums';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import CategoryForm from '../CategoryForm';

function AddCategoryDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const [expandedCategories, setExpandedCategories] = useSessionStorage<
    string[]
  >(EXPANDED_CATEGORY_IDS, []);
  const { t: translation } = useTranslation();
  const dialogShow = useAppSelector(selectAddCategoryDialogOpened);
  const category = useAppSelector(selectContextCategory);
  const [categoryName, setCategoryName] = useState('');
  const [categoryAcronym, setCategoryAcronym] = useState('');
  const [categoryTypeId, setCategoryTypeId] = useState(
    category.categoryTypeId || '',
  );
  const [lastModified, setLastModified] = useState('');
  const {
    data: categoryTypes,
    isFetching,
    isError: getCategoryTypesIsError,
    error: getCategoryTypesError,
  } = useGetApiCategoryTypesQuery(!dialogShow ? skipToken : undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [
    addCategory,
    { isError: addCategoryIsError, isLoading, error: addCategoryError },
  ] = usePostApiCategoriesMutation();
  const {
    data: permissionData,
    isError: getPermissionsIsError,
    error: getPermissionsError,
  } = useGetApiPermissionsUserQuery(undefined, {
    refetchOnMountOrArgChange: permissionCacheDelayInSeconds,
  });
  const [inputsAreValid, setInputsAreValid] = useState(true);
  const { refetch } = useGetApiCategoryTreeQuery();

  useEffect(() => {
    if (getCategoryTypesIsError) {
      dispatch(
        addMessage({
          id: 'GetCategoryTypesError',
          variant: 'danger',
          messageKeyBody:
            getCategoryTypesError && 'data' in getCategoryTypesError
              ? getCategoryTypesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (addCategoryIsError) {
      dispatch(
        addMessage({
          id: 'AddCategoryError',
          variant: 'danger',
          messageKeyBody:
            addCategoryError && 'data' in addCategoryError
              ? addCategoryError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (getPermissionsIsError) {
      dispatch(
        addMessage({
          id: 'GetPermissionsError',
          variant: 'danger',
          messageKeyBody:
            getPermissionsError && 'data' in getPermissionsError
              ? getPermissionsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getCategoryTypesIsError, addCategoryIsError, getPermissionsIsError]);

  useEffect(() => {
    setCategoryTypeId(category.categoryTypeId || '');
  }, [category]);

  const resetStates = () => {
    setCategoryAcronym('');
    setCategoryName('');
    setLastModified('');
    dispatch(setContextCategory({}));
  };

  const handleAddCategory = () => {
    addCategory({
      category: {
        categoryTypeId,
        name: categoryName,
        parentId: category.id || '',
        abbreviation: categoryAcronym,
        lastModified: lastModified || undefined,
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'AddCategorySuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setExpandedCategories(_.union(expandedCategories, [category.id || '']));
        dispatch(setAddCategoryDialogOpened(false));
        resetStates();
        setTimeout(() => {
          refetch();
        });
      });
  };

  return (
    <CustomDialog
      titleId='AddCategoryDialog'
      show={dialogShow}
      closeFunction={() => {
        resetStates();
        dispatch(setAddCategoryDialogOpened(false));
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleAddCategory}
      actionTitle={translation('add')}
      actionButtonDisabled={
        categoryName.trim() === '' || isLoading || !inputsAreValid
      }
      dialogTitle={translation('addCategory')}>
      {(isFetching || isLoading) && <Loader />}
      {categoryTypes && !isFetching && !isLoading && (
        <>
          <CategoryForm
            setInputsAreValid={setInputsAreValid}
            categoryName={categoryName}
            categoryAcronym={categoryAcronym}
            categoryTypeId={categoryTypeId}
            setCategoryName={setCategoryName}
            setCategoryAcronym={setCategoryAcronym}
            setCategoryTypeId={setCategoryTypeId}
            categoryTypeCanSet={
              permissionData?.resultObject?.includes(
                RightKey.RightCategoryTypeManagementAssign,
              ) || false
            }
          />
          <p>{translation('fieldsAreRequiredLegend')}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default AddCategoryDialog;
