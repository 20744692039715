import { SetStateAction, SyntheticEvent } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ContextActionItemContent from './ContextActionItemContent';
import { ContextAction } from './types';

interface IContextMenuLinkProps {
  action: ContextAction;
  openLink: (href: string, openInNewTab: boolean) => void;
  setShow: (value: SetStateAction<boolean>) => void;
}

function ContextMenuLink({ action, openLink, setShow }: IContextMenuLinkProps) {
  const handleOnClick = (event: SyntheticEvent<HTMLElement>): void => {
    event.preventDefault();

    openLink(action.href || '', action.openInNewTab || false);

    if (action.onClick) {
      action.onClick();
    }
  };

  return (
    <Dropdown.Item
      as={Link}
      to={action.href || ''}
      onClick={(e) => {
        handleOnClick(e);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleOnClick(e);
          setShow(false);
        }
      }}>
      <ContextActionItemContent action={action} />
    </Dropdown.Item>
  );
}

export default ContextMenuLink;
