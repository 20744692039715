import { Suspense } from 'react';
import { RawEditorOptions } from 'tinymce';
import './assets/fonts/style.scss';
import './index.scss';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';
import './i18n-config';
import Loader from './components/loader/Loader';

// custom window typing is needed for tiny config files
declare global {
  interface Window {
    tinyConfigs: {
      [key: string]: RawEditorOptions & {
        selector: undefined;
        target: undefined;
      };
    };
    tinyConfigList: string[];
  }
}

const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>
  </BrowserRouter>,
  rootElement,
);
