import _ from 'lodash';
import { htmlTagNameRegexForIndividualTags } from './htmlTagNameRegex';

interface IPositionProps {
  start: number;
  end: number;
}

interface IMatchProps {
  word: string;
  position: IPositionProps;
}

interface ISuggestionProps {
  position: IPositionProps;
  lengthDelta: number;
  missingWordsCount: number;
  content?: string;
}

const getTagfreeContent = (
  content: string,
): { content: string; indexes: number[] } => {
  let c = content;
  let i = Array.from({ length: content.length }, (_value, index) => index);
  const allMatches = [
    ...c.matchAll(htmlTagNameRegexForIndividualTags),
  ].reverse();

  allMatches.forEach((match) => {
    if (match.index || match.index === 0) {
      c =
        c.substring(0, match.index) +
        c.substring(match.index + match[0].length, c.length);
      i = i
        .slice(0, match.index)
        .concat(i.slice(match.index + match[0].length));
    }
  });

  return { content: c, indexes: i };
};

const getPatternWordList = (pattern: string): string[] => {
  const regex = /(?:\p{L}|['\-_[\]()§]|\d)+/gu;
  return pattern.match(regex) || [];
};

const getPatternRegexWords = (pattern: string): RegExp => {
  let matchOptionString = '';
  let patternWordList = getPatternWordList(pattern);
  const maxWordCount = 50;
  const averageWordLength = 5;
  if (patternWordList.length > maxWordCount) {
    // remove duplicates
    patternWordList = [...new Set(patternWordList)];
    if (patternWordList.length > maxWordCount) {
      // remove short words
      patternWordList = patternWordList.filter(
        (word) => word.length > averageWordLength,
      );
    }
  }
  patternWordList.forEach((word, index) => {
    if (index === patternWordList.length - 1) {
      matchOptionString = matchOptionString.concat(`${word}`);
    } else {
      matchOptionString = matchOptionString.concat(`${word}|`);
    }
  });
  return new RegExp(`${matchOptionString}`, 'g');
};

const getMatchList = (
  tagFreeContent: string,
  pattern: string,
): IMatchProps[] => {
  const matchList: IMatchProps[] = [];
  const matches = [...tagFreeContent.matchAll(getPatternRegexWords(pattern))];

  matches.forEach((match) => {
    const word = match[0];
    const start = match.index ?? 0;
    const end = start + word.length;
    matchList.push({
      word,
      position: { start, end },
    });
  });

  return matchList;
};

const walkThroughList = (
  matchList: IMatchProps[],
  startIndex: number,
  pattern: string,
): ISuggestionProps | undefined => {
  const suggestionPosition: number[] = [];
  let searchWordList = getPatternWordList(pattern);
  const indices: number[] = [];

  matchList.forEach((_match, index) => {
    indices.push((startIndex + index) % matchList.length);
  });

  indices.forEach((i) => {
    const match = matchList[i];
    const listIndex = searchWordList.findIndex((word) => word === match.word);
    if (listIndex > -1) {
      // remove search word from list
      searchWordList = searchWordList
        .slice(0, listIndex)
        .concat(searchWordList.slice(listIndex + 1, searchWordList.length));
      // update suggestion position
      suggestionPosition.push(match.position.start, match.position.end);
    }
  });
  const start = Math.min(...suggestionPosition);
  const end = Math.max(...suggestionPosition);
  const lengthDelta = Math.abs(pattern.length - (end - start));
  const missingWordsCount = searchWordList.length;

  if (lengthDelta <= pattern.length) {
    return {
      position: { start, end },
      lengthDelta,
      missingWordsCount,
    };
  }
  return undefined;
};

const findSuggestion = (content: string, pattern: string) => {
  const object = getTagfreeContent(content);
  const tagfreeContent = object.content;
  const contentIndex = object.indexes;
  const matchList = getMatchList(tagfreeContent, pattern);
  let suggestions: ISuggestionProps[] = [];

  matchList.forEach((_match, index) => {
    const newSuggestion = walkThroughList(matchList, index, pattern);
    if (newSuggestion) {
      suggestions = [...suggestions, newSuggestion];
    }
  });

  const sortedCombinations = _.sortBy(suggestions, [
    'wordCount',
    'lengthDelta',
  ]);

  if (sortedCombinations.length < 1) {
    return null;
  }

  const start = contentIndex[sortedCombinations[0].position.start];
  const end = contentIndex[sortedCombinations[0].position.end];

  return {
    position: { start, end },
    content: content.substring(start, end),
  };
};

export default findSuggestion;
