import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { decode } from 'html-entities';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import { useAppSelector } from '../../redux/hooks';
import {
  selectActiveVersion,
  selectContextVersion,
} from '../../redux/store/content/slice';
import CustomCard from '../cards/CustomCard';
import { compareTexts } from '../../shared/custom-compare-lib/customCompare';
import './CompareVersions.scss';

function ComparerContent() {
  const { t: translation } = useTranslation();
  const contextVersion = useAppSelector(selectContextVersion);
  const activeVersion = useAppSelector(selectActiveVersion);

  const category = useGetCategoryByArticleId(activeVersion.articleId);

  return (
    <CustomCard
      focusableHeadline
      headlineAsH1
      iconClass={category?.categoryTypeIconClass || undefined}
      iconColorHexCode={category?.categoryTypeColorHexCode || 'text-dark'}
      title={translation('compareVersions', {
        oldVersion: contextVersion.name || '',
        newVersion: activeVersion.name || '',
      })}>
      <div className='compare-content'>
        {parse(
          compareTexts(
            `<h2 class="fs-5 mb-3">${decode(contextVersion.title)}</h2>`,
            `<h2 class="fs-5 mb-3">${decode(activeVersion.title)}</h2>`,
          ),
        )}
        {parse(
          decode(
            compareTexts(contextVersion.htmlContent, activeVersion.htmlContent),
          ),
        )}
      </div>
    </CustomCard>
  );
}

export default ComparerContent;
