import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiCategoryTreeQuery,
  usePostApiArticlesStructureMutation,
} from '../../../redux/store/api/api';
import {
  selectAddStructureElementDialogOpened,
  selectContextCategory,
  setAddStructureElementDialogOpened,
  setContextCategory,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import { EXPANDED_CATEGORY_IDS } from '../../../shared/constants';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

function AddStructureElementDialog() {
  const { t: translation } = useTranslation();
  const [expandedCategoryIds, setExpandedCategoryIds] = useSessionStorage<
    string[]
  >(EXPANDED_CATEGORY_IDS, []);
  const [elementName, setElementName] = useState('');
  const dialogShow = useAppSelector(selectAddStructureElementDialogOpened);
  const category = useAppSelector(selectContextCategory);
  const dispatch = useAppDispatch();
  const [addStructureElement, { isError, isLoading, error }] =
    usePostApiArticlesStructureMutation();
  const { refetch } = useGetApiCategoryTreeQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'AddStructureElementError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleAddStructureElement = (): void => {
    addStructureElement({
      categoryId: category.id || '',
      title: elementName.trim(),
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'AddStructureElementSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setExpandedCategoryIds(
          _.union(expandedCategoryIds, [category.id || '']),
        );
        setElementName('');
        dispatch(setAddStructureElementDialogOpened(false));
        dispatch(setContextCategory({}));
        setTimeout(() => {
          refetch();
        });
      });
  };

  return (
    <CustomDialog
      titleId='AddStructureElementDialog'
      show={dialogShow}
      closeFunction={() => {
        setElementName('');
        dispatch(setContextCategory({}));
        dispatch(setAddStructureElementDialogOpened(false));
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleAddStructureElement}
      actionTitle={translation('add')}
      actionButtonDisabled={elementName.trim() === '' || isLoading}
      dialogTitle={translation('createStructureElement')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Form.Group className='mb-3' controlId='StructureElementName'>
            <Form.Label>{translation('structureElementName')}*</Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setElementName(e.target.value);
              }}
              type='text'
              value={elementName}
            />
          </Form.Group>
          <p>{translation('fieldsAreRequiredLegend')}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default AddStructureElementDialog;
