import { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useIsFirstRender, useSessionStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  Mark,
  Note,
  useGetApiNotesByVersionIdQuery,
  usePutApiMarksByIdMutation,
} from '../../../redux/store/api/api';
import {
  selectActiveNote,
  selectActiveVersion,
  selectMarksNeedingAction,
  selectNoteData,
} from '../../../redux/store/content/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import convertFromRange from '../../version/functions/convertFromRange';
import makeSuggestionContentString from '../../version/functions/makeSuggestionContentString';
import '../Marks.scss';
import { NoteVisibilityType } from '../../../shared/enums';
import { addMessage } from '../../../redux/store/layout/slice';
import DeleteNoteDialog from '../../notes/dialogs/DeleteNoteDialog';
import DeleteMarkFromNoteDialog from './DeleteMarkFromNoteDialog';
import { VisibilityGroupString } from '../../notes/types';
import makeContent from '../../version/functions/makeContent';
import testForIntersection from '../../version/functions/testForIntersection';
import {
  MARK_VISIBILITY_GROUP_KEY,
  SUGGESTION_CONTENT_CONTAINER_ID,
  TEMP_SUGGESTION_EL_ID,
} from '../../../shared/constants';
import { doubleDecodeHtmlEntities } from '../../../shared/utils';
import makeRangeFromSuggestion from '../../version/functions/makeRangeFromSuggestion';
import { getSortedNotesPropertyByVisibilityGroup } from '../../notes/functions';

interface IMarkNeedsActionDialogProps {
  show: boolean;
  setShow: (show: boolean) => void;
  getUserCanDeleteNote: (note: Note) => boolean;
  getUserCanEditNote: (note: Note) => boolean;
}

function MarkNeedsActionDialog({
  show,
  setShow,
  getUserCanDeleteNote,
  getUserCanEditNote,
}: IMarkNeedsActionDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);
  const marksNeedingAction = useAppSelector(selectMarksNeedingAction);
  const note = useAppSelector(selectActiveNote);
  const [activeMarkVisibilityGroup, setActiveMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );
  const visibilityGroupOfActiveNote = NoteVisibilityType[
    note.visibility || 0
  ] as VisibilityGroupString;
  const noteData = useAppSelector(selectNoteData);
  const [suggestion, setSuggestion] = useState<string | null>('');
  const [noSuggestionFound, setNoSuggestionFound] = useState(
    suggestion === null,
  );
  const [selectingNewMark, setSelectingNewMark] = useState(false);
  const [deleteNoteDialogOpened, setDeleteNoteDialogOpened] = useState(false);
  const [deleteMarkDialogOpened, setDeleteMarkDialogOpened] = useState(false);
  const [suggestionContainer, setSuggestionContainer] = useState(
    document.getElementById(SUGGESTION_CONTENT_CONTAINER_ID),
  );
  const [showInvalidSelectionWarning, setShowInvalidSelectionWarning] =
    useState(false);
  const isFirstRender = useIsFirstRender();

  const [updateMark, { isError: updateMarkIsError, error }] =
    usePutApiMarksByIdMutation();
  const { refetch } = useGetApiNotesByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );

  const closeModalResetButtons = () => {
    setShow(false);
    setSelectingNewMark(false);
    setShowInvalidSelectionWarning(false);
  };

  const makeSuggestionContent = () => {
    let marksToShow: Note[] = [];

    const noteDataProp = getSortedNotesPropertyByVisibilityGroup(
      visibilityGroupOfActiveNote,
    );
    if (noteDataProp) {
      marksToShow =
        noteData[`${noteDataProp}`]?.filter(
          (n) => n.mark?.id && !marksNeedingAction.includes(n.mark.id),
        ) || [];
    }

    return makeContent({
      content: doubleDecodeHtmlEntities(version.htmlContent || ''),
      elements: marksToShow,
      tagName: 'mark',
      withoutIds: true,
      isSuggestionContent: true,
    }).content;
  };

  const onSave = () => {
    let range = makeRangeFromSuggestion(suggestionContainer, 'suggestion-mark');
    const selection = window.getSelection();

    if (!range) {
      if (selection && !selection.isCollapsed) {
        range = selection.getRangeAt(0);
      } else {
        return;
      }
    }

    if (testForIntersection(range, SUGGESTION_CONTENT_CONTAINER_ID, 'mark')) {
      selection?.collapseToStart();
      setShowInvalidSelectionWarning(true);
      return;
    }

    const markId = note?.mark?.id || '';
    const changedMark: Mark = {
      id: markId,
      ...convertFromRange(range, SUGGESTION_CONTENT_CONTAINER_ID),
      noteId: note?.id || '',
    };
    updateMark({ id: markId, mark: changedMark })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateMarkSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setShow(false);
        refetch();
        setActiveMarkVisibilityGroup(
          NoteVisibilityType[note.visibility || 0] as VisibilityGroupString,
        );
      });
  };

  const onSetNewMark = () => {
    if (!suggestionContainer) {
      return;
    }
    setSelectingNewMark(true);
    setSuggestion(makeSuggestionContent() || '');
    const selection = window.getSelection();
    selection?.collapseToStart();
    setNoSuggestionFound(false);
  };

  // Scroll to suggested mark in content
  useEffect(() => {
    if (suggestionContainer) {
      const suggestionMark = document.getElementById(TEMP_SUGGESTION_EL_ID);
      suggestionMark?.scrollIntoView(true);
    }
  }, [suggestionContainer]);

  useEffect(() => {
    if (updateMarkIsError) {
      dispatch(
        addMessage({
          id: 'UpdateMarkError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [updateMarkIsError]);

  useEffect(() => {
    if (show) {
      setSuggestionContainer(
        document.getElementById(SUGGESTION_CONTENT_CONTAINER_ID),
      );
    }
  }, [show]);

  useEffect(() => {
    if (isFirstRender || !note || !show) {
      return;
    }

    if (activeMarkVisibilityGroup !== visibilityGroupOfActiveNote) {
      setActiveMarkVisibilityGroup(visibilityGroupOfActiveNote);
    }

    setSuggestion(
      makeSuggestionContentString(
        makeSuggestionContent(),
        note.mark?.patternString || '',
        'mark',
        'suggestion-mark',
      ),
    );
  }, [note, version.htmlContent, show]);

  return (
    <>
      <CustomDialog
        dialogId='MarkNeedsActionDialog'
        titleId='MarkNeedsActionDialogTitle'
        dialogTitle={
          getUserCanEditNote(note)
            ? translation('checkMark')
            : translation('markNotFound')
        }
        show={show}
        closeFunction={closeModalResetButtons}
        closeTitle={translation('cancel')}>
        {showInvalidSelectionWarning && (
          <Alert
            aria-label={translation('closeAlert')}
            variant='warning'
            onClose={() => setShowInvalidSelectionWarning(false)}
            dismissible>
            <p className='m-0'>{translation('makeNewSelection')}</p>
          </Alert>
        )}

        <h5>{translation('titleOfTheNote')}:</h5>
        <p>{note.name}</p>
        <h5>{translation('contentOfTheNote')}:</h5>
        {note.text ? (
          <p>{note.text}</p>
        ) : (
          <p>{translation('noteHasNoContent')}</p>
        )}
        {getUserCanEditNote(note) ? (
          <>
            <h5>
              {selectingNewMark
                ? translation('selectNewArea')
                : translation('suggestion')}
              :
            </h5>
            <div
              className='max-350 version-content'
              id={SUGGESTION_CONTENT_CONTAINER_ID}>
              {parse(suggestion || translation('noSuggestionFound'))}
            </div>
          </>
        ) : (
          <div>{translation('markNeedsToBeEditedByCreator')}</div>
        )}

        {getUserCanEditNote(note) && (
          <Button
            className='m-1 optionButton'
            variant='outline-dark'
            disabled={noSuggestionFound}
            onClick={onSave}>
            {translation('save')}
          </Button>
        )}
        {getUserCanEditNote(note) && (
          <Button
            className={`m-1 ${
              selectingNewMark ? 'invisible' : 'visible'
            } optionButton`}
            variant='outline-dark'
            onClick={onSetNewMark}>
            {translation('setNewMark')}
          </Button>
        )}
        {getUserCanDeleteNote(note) && (
          <Button
            className='m-1 optionButton'
            variant='outline-danger'
            onClick={() => {
              closeModalResetButtons();
              setDeleteMarkDialogOpened(true);
            }}>
            {translation('deleteMark')}
          </Button>
        )}
        {getUserCanDeleteNote(note) && (
          <Button
            className='m-1 optionButton'
            variant='outline-danger'
            onClick={() => {
              closeModalResetButtons();
              setDeleteNoteDialogOpened(true);
            }}>
            {translation('deleteWholeNote')}
          </Button>
        )}
      </CustomDialog>
      <DeleteMarkFromNoteDialog
        show={deleteMarkDialogOpened}
        setShow={setDeleteMarkDialogOpened}
      />
      <DeleteNoteDialog
        show={deleteNoteDialogOpened}
        setShow={setDeleteNoteDialogOpened}
      />
    </>
  );
}

export default MarkNeedsActionDialog;
