import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch } from '../../../redux/hooks';
import {
  ArticleReference,
  SourceContentArea,
  useGetApiArticlesReferencesByIdQuery,
  usePostApiArticleReferencesMutation,
  usePostApiExternalReferencesMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { IArticleTreeItem, ICategoryTreeItem } from '../../content-tree/types';
import CustomDialog from '../../dialogs/CustomDialog';
import '../References.scss';
import { ReferenceBehaviourString, ReferenceType } from '../types';
import MultipleInternalReferenceForm from '../MultipleInternalReferenceForm';
import ExternalReferenceForm from '../ExternalReferenceForm';
import InternalReferenceWithTextPassageForm from '../InternalReferenceWithTextPassageForm';
import InternalReferenceInArticle from '../InternalReferenceInArticle';
import { ReferenceBehaviour } from '../../../shared/enums';
import { articleIdUrlParam } from '../../../shared/constants';
import Loader from '../../loader/Loader';
import { setShowMakeLinkSelectionInfo } from '../../../redux/store/content/slice';

interface IAddReferenceDialogProps {
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
  sourceContentArea?: SourceContentArea;
  resetSourceContentArea: () => void;
  sourceContentAreaAncestorIds: string[];
}

function AddReferenceDialog({
  dialogShow,
  setDialogShow,
  sourceContentArea,
  resetSourceContentArea,
  sourceContentAreaAncestorIds,
}: IAddReferenceDialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const [searchParams] = useSearchParams();
  const articleId: string | null = searchParams.get(articleIdUrlParam);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [referenceType, setReferenceType] = useState<ReferenceType | null>(
    null,
  );
  const [textPassage, setTextPassage] = useState<string | null>(null);
  const [selectedElements, setSelectedElements] = useState<
    (IArticleTreeItem | ICategoryTreeItem)[] | null
  >(null);
  const [referenceBehaviour, setReferenceBehaviour] =
    useState<ReferenceBehaviourString>(
      ReferenceBehaviour[
        ReferenceBehaviour.Bidirectional
      ] as ReferenceBehaviourString,
    );
  const [displayNameForExternalTarget, setDisplayNameForExternalTarget] =
    useState('');
  const [targetUrl, setTargetUrl] = useState('');

  const [
    addInternalReference,
    {
      isError: addInternalReferenceIsError,
      error: addInternalReferenceError,
      isLoading: addInternalReferenceIsLoading,
    },
  ] = usePostApiArticleReferencesMutation();
  const [
    addExternalReferences,
    {
      isError: addExternalReferenceIsErrror,
      error: addExternalReferenceErrror,
      isLoading: addExternalReferenceIsLoading,
    },
  ] = usePostApiExternalReferencesMutation();
  const isLoading =
    addInternalReferenceIsLoading || addExternalReferenceIsLoading;
  const { refetch } = useGetApiArticlesReferencesByIdQuery(
    articleId
      ? {
          id: articleId,
        }
      : skipToken,
  );
  const multipleInternalReferenceValuesNotComplete =
    referenceType === ReferenceType.InternalReferenceMultiple &&
    !selectedElements;
  const internalReferenceOfTextPassageValuesNotComplete =
    referenceType === ReferenceType.InternalReferenceWithTextPassage &&
    (!textPassage || !selectedElements);
  const internalReferenceInArticleValuesNotComplete =
    referenceType === ReferenceType.InternalReferenceInArticle && !textPassage;
  const externalReferenceValuesNotComplete =
    referenceType === ReferenceType.ExternalReference &&
    (displayNameForExternalTarget.trim() === '' ||
      targetUrl.trim() === '' ||
      !isValidUrl);

  useEffect(() => {
    if (addExternalReferenceIsErrror) {
      dispatch(
        addMessage({
          id: 'AddExternalReferenceError',
          variant: 'danger',
          messageKeyBody:
            addExternalReferenceErrror && 'data' in addExternalReferenceErrror
              ? addExternalReferenceErrror.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (addInternalReferenceIsError) {
      dispatch(
        addMessage({
          id: 'AddInternalReferenceError',
          variant: 'danger',
          messageKeyBody:
            addInternalReferenceError && 'data' in addInternalReferenceError
              ? addInternalReferenceError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [addInternalReferenceIsError, addExternalReferenceIsErrror]);

  const resetSelection = () => {
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  };

  const resetStates = () => {
    setReferenceType(null);
    setSelectedElements(null);
    setReferenceBehaviour(
      ReferenceBehaviour[
        ReferenceBehaviour.Bidirectional
      ] as ReferenceBehaviourString,
    );
    setDisplayNameForExternalTarget('');
    setTextPassage(null);
    resetSourceContentArea();
    resetSelection();
    dispatch(setShowMakeLinkSelectionInfo(false));
  };

  const handleAddReference = () => {
    if (referenceType !== ReferenceType.ExternalReference) {
      const references: ArticleReference[] =
        referenceType === ReferenceType.InternalReferenceInArticle
          ? [
              {
                sourceContentArea,
                targetArticleId: articleId || '',
                sourceArticleId: articleId || '',
                targetContentAreaId: textPassage,
                referenceBehaviour: ReferenceBehaviour[referenceBehaviour],
              },
            ]
          : selectedElements?.map((e) => {
              const targetArticleId: string | null = (e as IArticleTreeItem)
                .recentVersionId
                ? e.id
                : null;
              const targetCategoryId: string | null = (e as ICategoryTreeItem)
                .categoryTypeId
                ? e.id
                : null;
              return {
                targetArticleId,
                targetCategoryId,
                sourceContentArea,
                sourceArticleId: articleId,
                targetContentAreaId: textPassage,
                referenceBehaviour: ReferenceBehaviour[referenceBehaviour],
              };
            }) || [];

      addInternalReference({
        body: references,
      })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'AddInternalReferenceSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          setDialogShow(false);
          resetStates();
          refetch();
        });
    } else {
      addExternalReferences({
        externalReference: {
          articleId: articleId || '',
          name: displayNameForExternalTarget.trim(),
          hyperlink: targetUrl.trim(),
        },
      })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'AddExternalReferenceSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          setDialogShow(false);
          resetStates();
          refetch();
        });
    }
  };

  const getReferenceTypes = () => {
    let referenceTypes = Object.keys(ReferenceType);
    if (sourceContentArea) {
      referenceTypes = referenceTypes.filter(
        (t) => t !== ReferenceType.ExternalReference,
      );
    } else {
      referenceTypes = referenceTypes.filter(
        (t) => t !== ReferenceType.InternalReferenceInArticle,
      );
    }

    return referenceTypes;
  };

  const getReferenceBehaviourKeys = () => {
    let referenceBehaviourKeys = Object.keys(ReferenceBehaviour).filter((key) =>
      Number.isNaN(Number(key)),
    );

    if (sourceContentArea) {
      referenceBehaviourKeys = referenceBehaviourKeys.filter(
        (k) => k !== ReferenceBehaviour[ReferenceBehaviour.BackwardOnly],
      );
    }

    return referenceBehaviourKeys as ReferenceBehaviourString[];
  };

  return (
    <CustomDialog
      titleId='AddReferenceDialog'
      wideDialog
      show={dialogShow}
      closeFunction={() => {
        resetStates();
        setDialogShow(false);
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleAddReference}
      actionTitle={translation('add')}
      actionButtonDisabled={
        multipleInternalReferenceValuesNotComplete ||
        externalReferenceValuesNotComplete ||
        internalReferenceOfTextPassageValuesNotComplete ||
        internalReferenceInArticleValuesNotComplete ||
        isLoading
      }
      dialogTitle={translation('addReference')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Form>
            <p aria-labelledby='ReferenceType' className='mb-1 fw-bold'>
              {translation('chooseTypeOfReference')}*
            </p>
            {getReferenceTypes().map((key) => (
              <Form.Check
                key={key}
                id={`${key}_Radio`}
                value='external'
                checked={referenceType === key}
                label={translation(`referenceType${key}`)}
                name='ReferenceType'
                type='radio'
                onChange={(e) => {
                  setSelectedElements(null);
                  setTextPassage(null);
                  setTargetUrl('');
                  setIsValidUrl(true);
                  setReferenceBehaviour(
                    key === ReferenceType.InternalReferenceInArticle
                      ? (ReferenceBehaviour[
                          ReferenceBehaviour.ForwardOnly
                        ] as ReferenceBehaviourString)
                      : (ReferenceBehaviour[
                          ReferenceBehaviour.Bidirectional
                        ] as ReferenceBehaviourString),
                  );

                  if (e.target.checked) {
                    setReferenceType(key as ReferenceType);
                  }
                }}
              />
            ))}
            {referenceType === ReferenceType.InternalReferenceMultiple && (
              <MultipleInternalReferenceForm
                selectedReferences={selectedElements}
                referenceBehaviour={referenceBehaviour}
                setReferenceBehaviour={setReferenceBehaviour}
                setSelectedReferences={setSelectedElements}
                getReferenceBehaviourKeys={getReferenceBehaviourKeys}
                hasSourceContentArea={sourceContentArea !== null}
              />
            )}
            {referenceType ===
              ReferenceType.InternalReferenceWithTextPassage && (
              <InternalReferenceWithTextPassageForm
                textPassage={textPassage}
                setTextPassage={setTextPassage}
                referenceBehaviour={referenceBehaviour}
                setReferenceBehaviour={setReferenceBehaviour}
                setSelectedReferences={setSelectedElements}
                selectedReferences={selectedElements}
              />
            )}
            {referenceType === ReferenceType.ExternalReference && (
              <ExternalReferenceForm
                displayName={displayNameForExternalTarget}
                setDisplayName={setDisplayNameForExternalTarget}
                targetUrl={targetUrl}
                setTargetUrl={setTargetUrl}
                isValidUrl={isValidUrl}
                setIsValidUrl={setIsValidUrl}
              />
            )}
            {referenceType === ReferenceType.InternalReferenceInArticle && (
              <InternalReferenceInArticle
                textPassage={textPassage}
                setTextPassage={setTextPassage}
                sourceContentAreaAncestorIds={sourceContentAreaAncestorIds}
              />
            )}
            {sourceContentArea && (
              <p>
                {`${translation('referenceWillCreateLinkInContent')} "${
                  sourceContentArea.patternString
                }"`}
              </p>
            )}
          </Form>
          <p>{translation('fieldsAreRequiredLegend')}</p>
        </>
      )}
    </CustomDialog>
  );
}

AddReferenceDialog.defaultProps = { sourceContentArea: null };

export default AddReferenceDialog;
