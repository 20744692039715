import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CategoryTypeList from '../components/category-type-management/CategoryTypeList';
import CategoryTypeSettings from '../components/category-type-management/CategoryTypeSettings';
import { useAppDispatch } from '../redux/hooks';
import {
  CategoryType,
  useGetApiPermissionsUserQuery,
} from '../redux/store/api/api';
import { addMessage } from '../redux/store/layout/slice';
import { permissionCacheDelayInSeconds } from '../shared/constants';
import { RightKey } from '../shared/enums';

function CategoryTypeManagement(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeCategoryType, setActiveCategoryType] = useState<CategoryType>(
    {},
  );
  const {
    data: permissionData,
    isError,
    error,
  } = useGetApiPermissionsUserQuery(undefined, {
    refetchOnMountOrArgChange: permissionCacheDelayInSeconds,
  });

  useEffect(() => {
    if (
      permissionData?.resultObject &&
      !permissionData.resultObject.includes(
        RightKey.RightAccessCategoryTypeManagement,
      )
    ) {
      navigate('/');
    }
  }, [permissionData]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetPermissionsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
      navigate('/');
    }
  }, [isError]);

  useEffect(() => {
    document.getElementsByTagName('h1')[0].focus();
  }, []);

  return (
    <div className='content-inner'>
      {permissionData?.resultObject && (
        <>
          <CategoryTypeList
            activeCategoryType={activeCategoryType}
            setActiveCategoryType={setActiveCategoryType}
            rights={permissionData.resultObject}
          />
          <CategoryTypeSettings
            activeCategoryType={activeCategoryType}
            rights={permissionData.resultObject}
          />
        </>
      )}
    </div>
  );
}

export default CategoryTypeManagement;
