import { useMemo, useEffect } from 'react';
import TreeView from 'react-accessible-treeview';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiUserGroupsQuery,
  User,
  UserGroup,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  addExpandedUserGroup,
  removeExpandedUserGroup,
  selectExpandedUserGroups,
  selectMoveUserGroupStarted,
  selectMoveUserStarted,
  selectSelectedUsers,
  selectMovingUserGroup,
  setMoveUserStarted,
  setMoveUserGroupStarted,
  clearSelectedUsers,
  addExpandedUserGroups,
  setExpandedUserGroups,
} from '../../../redux/store/user-management/slice';
import Loader from '../../loader/Loader';
import AddUserDialog from '../dialogs/AddUserDialog';
import AddUserGroupDialog from '../dialogs/AddUserGroupDialog';
import ContentPermissionsDialog from '../dialogs/ContentPermissionsDialog';
import DeleteUserDialog from '../dialogs/DeleteUserDialog';
import DeleteUserGroupDialog from '../dialogs/DeleteUserGroupDialog';
import EditUserDialog from '../dialogs/EditUserDialog';
import EditUserGroupDialog from '../dialogs/EditUserGroupDialog';
import MoveUserDialog from '../dialogs/MoveUserDialog';
import MoveUserGroupDialog from '../dialogs/MoveUserGroupDialog';
import {
  UserGroupTreeItems,
  IUserGroupTreeItem,
  IUserTreeItem,
} from '../types';
import UserGroupTreeItem from './UserGroupTreeItem';
import '../UserManagement.scss';
import UserGroupPermissionsDialog from '../dialogs/UserGroupPermissionsDialog';
import RoleAssignmentDialog from '../dialogs/RoleAssignmentDialog';
import { getCompleteUserGroupTreeData } from '../functions';
import UserGroupSettingsDialog from '../dialogs/UserGroupSettingsDialog';
import UserImportDialog from '../dialogs/UserImportDialog';
import { RightKey } from '../../../shared/enums';
import AutoCompleteInput from '../../inputs/AutocompleteInput';
import ToggleLockoutUserDialog from '../dialogs/ToggleLockoutUserDialog';

function getParentGuid(
  userGroups: UserGroup[],
  childId: string,
): string | undefined {
  const parentId =
    userGroups.find(
      (c) => c.userGroupIds?.includes(childId) || c.userIds?.includes(childId),
    )?.id || undefined;
  return userGroups.find((c) => c.id === parentId) ? parentId : undefined;
}

function getParentIdsOfChild(
  userGroups: UserGroup[],
  childId: string,
): string[] {
  return userGroups.reduce((ids: string[], item) => {
    if (item.id === getParentGuid(userGroups, childId)) {
      // eslint-disable-next-line no-param-reassign
      ids = ids.concat(
        item.id || '',
        getParentIdsOfChild(userGroups, item.id || ''),
      );
    }
    return ids;
  }, []);
}

interface IUserGroupTreeProps {
  showPermissionIcons: (show: boolean) => void;
}

function UserGroupTree({
  showPermissionIcons,
}: IUserGroupTreeProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const moveUserStarted = useAppSelector(selectMoveUserStarted);
  const currentSelectedUsers = useAppSelector(selectSelectedUsers);
  const moveUserGroupStarted = useAppSelector(selectMoveUserGroupStarted);
  const movingUserGroup = useAppSelector(selectMovingUserGroup);
  const expandedUserGroups = useAppSelector(selectExpandedUserGroups);

  const {
    data: userTreeData,
    isFetching,
    isError,
    error,
  } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetUserGroupsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const treeData = useMemo<UserGroupTreeItems>(() => {
    if (userTreeData?.resultObject) {
      return getCompleteUserGroupTreeData(userTreeData.resultObject);
    }
    return [];
  }, [userTreeData]);

  useEffect(() => {
    if (userTreeData?.resultObject) {
      const filteredExpandedIds = [...expandedUserGroups].filter(
        (c) => !!userTreeData.resultObject?.userGroups?.find((d) => d.id === c),
      );

      if (_.difference(filteredExpandedIds, expandedUserGroups).length > 0) {
        dispatch(setExpandedUserGroups(filteredExpandedIds));
      }

      showPermissionIcons(
        userTreeData.resultObject.userGroups?.some((ug) =>
          ug.permittedActions?.includes(RightKey.RightPermissionManagementRead),
        ) || false,
      );
    }
  }, [userTreeData]);

  useEffect(() => {
    dispatch(clearSelectedUsers());
    dispatch(setMoveUserStarted(false));
  }, []);

  const setFocusOnUserSearchResult = (user: User) => {
    // Remove old highlighting of a user, if it exists.
    Array.from(
      document.body.getElementsByClassName('border border-2 border-primary'),
    ).forEach((e) => {
      e.classList.remove('border', 'border-2', 'border-primary');
    });

    // Open tree for user search result
    if (user.id && treeData.length > 0) {
      const expandedIds = getParentIdsOfChild(
        userTreeData?.resultObject?.userGroups || [],
        user.id,
      );
      dispatch(addExpandedUserGroups(expandedIds));

      // Set focus and scroll to result
      setTimeout(() => {
        const section: HTMLElement | null = document.getElementById(
          user.id || '',
        );
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start' });
          section.classList.add('border', 'border-2', 'border-primary');
          section.focus();
        }
      });
    }
  };

  return (
    <>
      {(moveUserStarted || moveUserGroupStarted) && (
        <Alert variant='warning'>
          {moveUserStarted && (
            <>
              <span className='align-self-center'>
                {translation('followingUserIsSelectedForMoving')}
              </span>
              <ul className='list-unstyled m-0'>
                {currentSelectedUsers.map((user) => (
                  <li key={user.id || ''}>
                    {user.lastname || ''}, {user.firstname || ''}
                  </li>
                ))}
              </ul>
              <Button
                className='position-absolute m-2 end-0 bottom-0'
                onClick={() => {
                  dispatch(setMoveUserStarted(false));
                  dispatch(clearSelectedUsers());
                }}
                variant='outline-dark'>
                {translation('cancelMove')}
              </Button>
            </>
          )}
          {moveUserGroupStarted && (
            <div className='d-flex flex-row justify-content-between'>
              <span className='align-self-center'>
                {`${translation('followingUserGroupIsSelectedForMoving')} 
                ${movingUserGroup.name}`}
              </span>
              <div className='p-0'>
                <Button
                  onClick={() => dispatch(setMoveUserGroupStarted(false))}
                  variant='outline-dark'>
                  {translation('cancelMove')}
                </Button>
              </div>
            </div>
          )}
        </Alert>
      )}
      <div aria-busy={isFetching}>
        {isFetching && <Loader />}
        {!isFetching && userTreeData?.resultObject && (
          <>
            <div className='user-search-container'>
              <AutoCompleteInput
                label={translation('searchForUser')}
                controlId='AutocompleteUserSearch'
                options={userTreeData.resultObject?.users || []}
                onConfirm={setFocusOnUserSearchResult}
                getDisplayName={(user) =>
                  `${user.lastname}, ${user.firstname} (${user.email})`
                }
                getId={(user) => user.id || ''}
                getSearchString={(user) => `${user.lastname} ${user.firstname}`}
                valueMinLengthForSuggestion={1}
              />
            </div>
            <TreeView
              multiSelect
              expandedIds={expandedUserGroups}
              data={treeData}
              onExpand={(e) => {
                const { id } = e.element as IUserGroupTreeItem;
                if (e.isExpanded) {
                  dispatch(addExpandedUserGroup(id));
                } else {
                  dispatch(removeExpandedUserGroup(id));
                }
              }}
              clickAction='FOCUS'
              nodeRenderer={({
                element,
                getNodeProps,
                level,
                isBranch,
                isExpanded,
              }) => {
                const convertedElement = element as
                  | IUserGroupTreeItem
                  | IUserTreeItem;
                const permittedActions: RightKey[] =
                  ('email' in convertedElement
                    ? (
                        treeData.find(
                          (i) => i.id === convertedElement.parent,
                        ) as IUserGroupTreeItem
                      ).permittedActions
                    : convertedElement.permittedActions) || [];
                return UserGroupTreeItem({
                  level,
                  isBranch,
                  isExpanded,
                  getNodeProps,
                  permittedActions,
                  element: convertedElement,
                });
              }}
            />
          </>
        )}
      </div>
      <AddUserGroupDialog />
      <EditUserGroupDialog />
      <DeleteUserGroupDialog />
      <MoveUserGroupDialog />
      <AddUserDialog />
      <EditUserDialog />
      <DeleteUserDialog />
      <MoveUserDialog />
      <ToggleLockoutUserDialog />
      <UserImportDialog />
      <ContentPermissionsDialog />
      <UserGroupPermissionsDialog />
      <UserGroupSettingsDialog />
      <RoleAssignmentDialog />
    </>
  );
}

export default UserGroupTree;
