import { useTranslation } from 'react-i18next';
import CustomCard from '../cards/CustomCard';
import DeleteVersionDialog from './dialogs/DeleteVersionDialog';
import RenameVersionDialog from './dialogs/RenameVersionDialog';
import VersionList from './VersionList';
import CreateBookmarkDialog from './dialogs/bookmarks/CreateBookmarkDialog';

function Versions() {
  const { t: translation } = useTranslation();

  return (
    <>
      <CustomCard
        title={translation('versions').toUpperCase()}
        iconClass='icon-versionen'
        expandable>
        <VersionList />
      </CustomCard>
      <DeleteVersionDialog />
      <RenameVersionDialog />
      <CreateBookmarkDialog />
    </>
  );
}

export default Versions;
