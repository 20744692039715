import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../dialogs/CustomDialog';
import {
  useGetApiBookmarkFoldersQuery,
  usePostApiBookmarkFoldersMutation,
} from '../../../redux/store/api/api';
import Loader from '../../loader/Loader';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  addExpandedBookmarkFolderId,
  selectAddBookmarkFolderDialogOpened,
  selectContextBookmarkFolder,
  setAddBookmarkFolderDialogOpened,
} from '../../../redux/store/content/slice';

function AddBookmarkFolderDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const parentFolderId = useAppSelector(selectContextBookmarkFolder).id || '';
  const dialogShow = useAppSelector(selectAddBookmarkFolderDialogOpened);
  const [addBookmarkFolder, { isLoading, isError, error }] =
    usePostApiBookmarkFoldersMutation();
  const [folderName, setFolderName] = useState('');
  const { refetch } = useGetApiBookmarkFoldersQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'AddBookmarkFolderError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleAddBookmarkFolder = () => {
    addBookmarkFolder({
      bookmarkFolder: { parentId: parentFolderId, name: folderName.trim() },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'AddBookmarkFolderSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        dispatch(setAddBookmarkFolderDialogOpened(false));
        setFolderName('');
        refetch();
        dispatch(addExpandedBookmarkFolderId(parentFolderId));
      });
  };

  return (
    <CustomDialog
      titleId='AddBookmarkFolderDialog'
      show={dialogShow}
      closeFunction={() => {
        setFolderName('');
        dispatch(setAddBookmarkFolderDialogOpened(false));
      }}
      closeTitle={translation('cancel')}
      actionFunction={handleAddBookmarkFolder}
      actionTitle={translation('add')}
      actionButtonDisabled={folderName.trim() === '' || isLoading}
      dialogTitle={translation('addBookmarkFolder')}>
      <>
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <Form.Group className='mb-3' controlId='BookmarkFolderName'>
              <Form.Label>{translation('name')}*</Form.Label>
              <Form.Control
                type='text'
                onChange={(e) => {
                  setFolderName(e.target.value);
                }}
              />
            </Form.Group>
            <p>{translation('fieldsAreRequiredLegend')}</p>
          </>
        )}
      </>
    </CustomDialog>
  );
}

export default AddBookmarkFolderDialog;
