import _ from 'lodash';
import { TEMP_SUGGESTION_EL_ID } from '../../../shared/constants';
import findSuggestion from './findSuggestion';
import { htmlTagNameRegex } from './htmlTagNameRegex';

const makeSuggestionContentString = (
  content: string,
  patternContent: string,
  tagName: 'mark' | 'a',
  suggestionClassName: string,
): string | null => {
  // find new suggestion pattern
  const suggestionPattern = findSuggestion(content, patternContent);

  if (!suggestionPattern) {
    return null;
  }

  // extract content parts that are not affected by new suggestion
  const contentBeforePattern = content.substring(
    0,
    suggestionPattern.position.start,
  );
  const contentAfterPattern = content.substring(
    suggestionPattern.position.end,
    content.length,
  );

  // surround new suggestion with tags
  const startTag = `<${tagName} id='${TEMP_SUGGESTION_EL_ID}' class='${tagName} ${suggestionClassName}'>`;
  const endTag = `</${tagName}>`;
  let alteredPattern = `${startTag}${suggestionPattern.content}${endTag}`;

  // add additional tags where any other tags break up the pattern content
  const allMatches = [...alteredPattern.matchAll(htmlTagNameRegex)];
  const allTags = [...new Set(_.map(allMatches, (match) => match[0]))];

  allTags.forEach((tag) => {
    alteredPattern = alteredPattern.replaceAll(
      tag,
      `${endTag}${tag}${startTag}`,
    );
  });

  return `${contentBeforePattern}${alteredPattern}${contentAfterPattern}`;
};
export default makeSuggestionContentString;
