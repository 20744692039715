import { Form } from 'react-bootstrap';
import TreeView from 'react-accessible-treeview';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectActiveNote,
  selectActiveVersion,
  selectAddMarkBookmarkDialogOpened,
  setAddMarkBookmarkDialogOpened,
} from '../../../redux/store/content/slice';
import {
  useGetApiBookmarkFoldersQuery,
  usePostApiBookmarksMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  BookmarkTreeItems,
  IBookmarkFolderTreeItem,
} from '../../bookmarks/types';
import { getBookmarkFolderTreeItems } from '../../bookmarks/functions';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import BookmarkFolderTreeItem from '../../version/dialogs/bookmarks/BookmarkFolderTreeItem';

function CreateMarkBookmarkDialog() {
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectAddMarkBookmarkDialogOpened);
  const { t: translation } = useTranslation();
  const [bookmarkName, setBookmarkName] = useState('');
  const note = useAppSelector(selectActiveNote);
  const version = useAppSelector(selectActiveVersion);
  const [selectedBookmarkFolderId, setSelectedBookmarkFolderId] = useState('');
  const { data: bookmarkTreeData, refetch: refetchBookmarkTree } =
    useGetApiBookmarkFoldersQuery();
  const [addBookmark, { isLoading, isError, error }] =
    usePostApiBookmarksMutation();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'CreateMarkError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const treeData = useMemo<BookmarkTreeItems>(() => {
    if (bookmarkTreeData?.resultObject) {
      return getBookmarkFolderTreeItems(bookmarkTreeData.resultObject);
    }
    return [];
  }, [bookmarkTreeData]);

  const handleAddBookmark = () => {
    addBookmark({
      bookmark: {
        name: bookmarkName.trim(),
        bookmarkFolderId: selectedBookmarkFolderId,
        versionId: note.versionId || '',
        articleId: version.id || '',
        markId: note.mark?.id || '',
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'CreateMarkSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setBookmarkName('');
        setSelectedBookmarkFolderId('');
        dispatch(setAddMarkBookmarkDialogOpened(false));
        refetchBookmarkTree();
      });
  };

  return (
    <CustomDialog
      titleId='CreateMarkBookmarkDialog'
      show={dialogShow}
      closeFunction={() => {
        setBookmarkName('');
        setSelectedBookmarkFolderId('');
        dispatch(setAddMarkBookmarkDialogOpened(false));
      }}
      actionFunction={handleAddBookmark}
      actionTitle={translation('add')}
      closeTitle={translation('close')}
      dialogTitle={translation('addBookmark')}
      actionButtonDisabled={
        isLoading ||
        bookmarkName.trim() === '' ||
        selectedBookmarkFolderId === ''
      }>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Form.Group className='mb-3' controlId='BookmarkName'>
            <Form.Label>{translation('name')}*</Form.Label>
            <Form.Control
              required
              onChange={(e) => {
                setBookmarkName(e.target.value);
              }}
              type='text'
              value={bookmarkName}
            />
          </Form.Group>
          <label htmlFor='BookmarkFolderTree' className='mb-1'>
            {translation('chooseAFolder')}*
          </label>
          <TreeView
            className='mb-2'
            id='BookmarkFolderTree'
            data={treeData}
            onSelect={(e) => {
              if (e.isSelected) {
                setSelectedBookmarkFolderId(
                  (e.element as IBookmarkFolderTreeItem).id,
                );
              }
            }}
            nodeRenderer={({
              element,
              getNodeProps,
              handleExpand,
              level,
              isExpanded,
              isBranch,
              handleSelect,
              isSelected,
            }) =>
              BookmarkFolderTreeItem({
                level,
                isBranch,
                isExpanded,
                handleExpand,
                getNodeProps,
                handleSelect,
                isSelected,
                element: element as IBookmarkFolderTreeItem,
              })
            }
          />
          <p>{translation('fieldsAreRequiredLegend')}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default CreateMarkBookmarkDialog;
