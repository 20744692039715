import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  Role,
  useGetApiRolesQuery,
  usePutApiRolesByIdMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { useAppDispatch } from '../../../redux/hooks';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

interface IRenameRoleDialogProps {
  show: boolean;
  setActiveRole: (role: Role) => void;
  onClose: () => void;
  role: Role;
}

function RenameRoleDialog({
  show,
  setActiveRole,
  onClose,
  role,
}: IRenameRoleDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const [
    updateRole,
    {
      isSuccess: updateRoleIsSuccess,
      isError: updateRoleIsError,
      error: updateRoleError,
      isLoading,
    },
  ] = usePutApiRolesByIdMutation();
  const {
    refetch,
    isError: getRolesIsError,
    error: getRolesError,
  } = useGetApiRolesQuery();
  const [roleName, setRoleName] = useState(role.name || '');
  const roleNameInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (show) {
      setRoleName(role.name || '');
      roleNameInputRef.current?.focus();
    }
  }, [show]);

  useEffect(() => {
    if (updateRoleIsSuccess) {
      setActiveRole(role);
    }
  }, [updateRoleIsSuccess]);

  useEffect(() => {
    if (getRolesIsError) {
      dispatch(
        addMessage({
          id: 'GetRolesError',
          variant: 'danger',
          messageKeyBody:
            getRolesError && 'data' in getRolesError
              ? getRolesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (updateRoleIsError) {
      dispatch(
        addMessage({
          id: 'UpdateRolesError',
          variant: 'danger',
          messageKeyBody:
            updateRoleError && 'data' in updateRoleError
              ? updateRoleError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getRolesIsError, updateRoleIsError]);

  const handleRenameRole = async () => {
    const roleToUpdate: Role = {
      id: role.id,
      name: roleName.trim(),
      permissionContext: role.permissionContext,
    };
    updateRole({ id: roleToUpdate.id || '', role: roleToUpdate })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateRoleSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        onClose();
      });
  };

  return (
    <CustomDialog
      titleId='RenameRoleDialog'
      actionButtonDisabled={
        role.name === roleName.trim() || roleName.trim() === '' || isLoading
      }
      dialogTitle={translation('renameRole')}
      show={show}
      closeFunction={onClose}
      actionFunction={handleRenameRole}
      closeTitle={translation('cancel')}
      actionTitle={translation('rename')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Form
            onSubmit={(e) => {
              if (role.name === roleName.trim() || roleName.trim() === '') {
                e.preventDefault();
                return;
              }
              handleRenameRole();
            }}>
            <Form.Group className='mb-3' controlId='RoleNameForRename'>
              <Form.Label>{translation('nameOfRole')}*</Form.Label>
              <Form.Control
                ref={roleNameInputRef}
                required
                onChange={(e) => {
                  setRoleName(e.target.value);
                }}
                type='text'
                value={roleName}
              />
            </Form.Group>
          </Form>
          <p>{translation('fieldsAreRequiredLegend')}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default RenameRoleDialog;
